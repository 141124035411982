import LayoutAdmin from "../LayoutAdmin";
import { Form, FormGroup, Label, Col, Input, Alert, Spinner } from "reactstrap";
import LessonServiceAdd from "./LessonServiceAdd";
import { BackComponent } from "../../../components";
import ValidationLesson from "./ValidationLesson";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CreateLessonAPI from "../../../services/adminApi/lesson/create";
import { useHistory } from "react-router";
const CreateLesson = () => {
  const history = useHistory();
  const {
    handleSubmit,
    errors,
    data,
    handleChange,
    isSubmit,
    counter,
    progressImage,
    progressVideo,
  } = LessonServiceAdd(ValidationLesson);
  const { customer } = useSelector((state) => state);
  useEffect(() => {
    if (progressImage == 100 && progressVideo == 101) {
      // const postData = {
      //   bimbelType: data.bimbelType,
      //   title: data.title,
      //   theory: data.theory,
      //   thumbnailUri: data.thumbnailUri,
      //   videoUri: data.videoUri,
      // };
      // console.log(`Data : `, postData);
      const saveLesson = async () => {
        const postData = {
          bimbelType: data.bimbelType,
          title: data.title,
          theory: data.theory,
          thumbnailUri: data.thumbnailUri,
          videoUri: data.videoUri,
        };
        const res = await CreateLessonAPI(customer.token, postData);
        if (res.statusCode == 20) {
          console.log("sukses");
          history.push("/admin/lesson/list");
        }
      };
      saveLesson();
    }
  }, [progressImage, progressVideo]);
  return (
    <LayoutAdmin>
      <h1>Buat Question</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="content" sm={2}>
            Judul :
          </Label>
          <Col>
            <Input
              name="title"
              id="title"
              placeholder="Masukan Judul"
              onChange={handleChange}
            />
            {errors && errors.title && (
              <p className="text-danger small">{errors.title}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="theory" sm={2}>
            Theori :
          </Label>
          <Col>
            <Input
              type="textarea"
              name="theory"
              id="theory"
              placeholder="Masukan Theory"
              onChange={handleChange}
            />
            {errors && errors.theory && (
              <p className="text-danger small">{errors.theory}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="theory" sm={2}>
            Gambar Thumbnail :
          </Label>
          <Col>
            <Input
              type="file"
              name="thumbnailUri"
              id="thumbnailUri"
              placeholder="Masukan Gambar"
              onChange={handleChange}
            />
            {errors && errors.thumbnailUri && (
              <p className="text-danger small">{errors.thumbnailUri}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="progress">
            {/* Progress : */}
          </Label>
          <Col>
            <progress
              value={progressImage}
              placeholder={progressImage + "%"}
              max="100"
              style={{ height: "30px" }}
            />{" "}
            {progressImage + "%"}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="theory" sm={2}>
            Gambar Video :
          </Label>
          <Col>
            <Input
              type="file"
              name="videoUri"
              id="videoUri"
              placeholder="Masukan Video"
              onChange={handleChange}
            />
            {errors && errors.videoUri && (
              <p className="text-danger small">{errors.videoUri}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="progress">
            {/* Progress : */}
          </Label>
          <Col>
            <progress
              value={progressVideo}
              placeholder={progressVideo + "%"}
              max="100"
              style={{ height: "30px" }}
            />{" "}
            {progressVideo + "%"}
          </Col>
        </FormGroup>
        {Object.keys(errors).length === 0 &&
          isSubmit &&
          progressImage == 100 &&
          progressVideo == 100 && (
            <Alert color="success">Berhasil Membuat Question.</Alert>
          )}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            Buat
            {/* {loading ? <Spinner color="primary" /> : "Buat"} */}
          </button>
          {/* <BackComponent url="/admin/try-out/ukom/question" /> */}
          <BackComponent />
        </div>
      </Form>
    </LayoutAdmin>
  );
};
export default CreateLesson;
