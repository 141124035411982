import React, { useState, useCallback } from "react";
import { SideBar, MyFooter, NavbarComponent } from "../components";
import { withRouter } from "react-router-dom";
import { IconContext } from "react-icons";
import { Container } from "reactstrap";
import classNames from "classnames";

function Layout({ children }) {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <div className="App wrapper">
        <SideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
        <Container
          fluid
          style={{ padding: 0 }}
          // className={classNames("content", { "is-open": sidebarIsOpen })}
        >
          <NavbarComponent toggleSidebar={toggleSidebar} />
          <div className="p-2">{children}</div>
        </Container>
      </div>

      <MyFooter />
    </IconContext.Provider>
  );
}

export default Layout;
