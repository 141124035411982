import { useState, useEffect } from "react";
import { UpdateScheduleApi, UpdateLabValueApi } from "../../../services";
import { useSelector } from "react-redux";

const UpdateLabValue = (ValidationLabValue) => {
  const { labValue } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [laboratoryValue, setLabValue] = useState({
    measure: labValue.measure,
    secureId: labValue.secureId,
    referenceValue: labValue.referenceValue,
    unit: labValue.unit,
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setLabValue({
      ...laboratoryValue,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(ValidationLabValue(laboratoryValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const ScheduleUpdate = async () => {
        setLoading(true);
        const res = await UpdateLabValueApi(laboratoryValue, customer.token);

        if (res.data.statusCode === 20) {
          //   console.log("Success");
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
      };
      ScheduleUpdate();
    }
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    laboratoryValue,
    isSubmit,
  };
};

export default UpdateLabValue;
