import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { Container, Alert } from "reactstrap";
import { useEffect, useState } from "react";
import { DetailLessonAPI, SetRatingAPI } from "../services";
import * as FaIcons from "react-icons/ai";

import { Rating } from "react-simple-star-rating";
const Lesson = () => {
  const { lessonSecureId } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [data, setData] = useState({});
  const [dataRating, setDataRating] = useState(0);
  const [rating, setRating] = useState(0); // initial rating value
  const [successRating, setSuccessRating] = useState(false);

  // Catch Rating value
  const handleRating = (rate) => {
    // Some logic
    const datas = {
      rating: rate,
    };
    SetRatingAPI(customer.token, lessonSecureId, datas)
      .then((res) => {
        if (res.statusCode == 20) {
          setRating(rate);
          setSuccessRating(true);
        } else if (res.statusCode == 99) {
          alert("Anda sudah pernah memberi rating");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const getDetail = async () => {
      const res = await DetailLessonAPI(customer.token, lessonSecureId);
      if (res.statusCode == 20) {
        setData(res.result);
        setDataRating(res.result.rating);
        setTimeout(() => {
          setSuccessRating(false);
        }, 2000);
      }
    };
    getDetail();
  }, [rating]);
  return (
    <Layout>
      <Container fluid>
        <div className="card-container p-3">
          <div className="m-3">
            {data && (
              <>
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row gap-2">
                    <h5>{data.title}</h5>
                    <FaIcons.AiFillStar color="black" fontSize="1.5rem" />
                    <span>{dataRating.toFixed(1)}</span>
                  </div>
                  <video
                    src={data.videoUri}
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                    controlsList="nodownload"
                    controls
                  >
                    <source type="video/mp4" />
                  </video>
                  <Alert color="success" isOpen={successRating}>
                    Berhasil Menambahkan rating
                  </Alert>
                  {data.rated ? (
                    ""
                  ) : (
                    <div className="d-flex flex-row gap-2">
                      <h4>Rating : </h4>

                      <Rating
                        onClick={handleRating}
                        ratingValue={rating} /* Rating Props */
                        size={32}
                      />
                    </div>
                  )}
                  <div className="d-flex flex-row gap-2">
                    <h4>Materi : {data.theory}</h4>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Lesson;
