import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  Login,
  PreLogin,
  Register,
  Schedule,
  Materi,
  ContohSoal,
  KuisMandiri,
  KuisMandiriExam,
  TryOut,
  TryOutKetentuan,
  TryOutQuiz,
  TryOutFinish,
  TryOutResult,
  TryOutRanking,
  TryOutRankingList,
  TryOutKey,
  TryOutVideoPembahasan,
  ForumDiskusi,
  Bimbel,
  ToApplication,
  NotFound,
  AdminSchedule,
  AdminScheduleCreate,
  AdminScheduleUpdate,
  AdminQuestion,
  AdminQuestionList,
  AdminQuestionCreate,
  AdminQuestionUpdate,
  AdminQuizPractice,
  AdminQuizPracticeUpdate,
  AdminLaboratoryValue,
  AdminLaboratoryValueCreate,
  AdminLaboratoryValueUpdate,
  AdminTheory,
  AdminTheoryCreate,
  AdminTheoryUpdate,
  AdminInstitute,
  AdminInstituteCreate,
  CreatePacket,
  PopUp_notification,
  dashboard_premium,
  BimbelPremium,
  AdminBimbelList,
  BimbelUpdate,
  FeatureList,
  FeatureAdd,
  Lesson,
  AskInfo,
  PaymentSuccess,
  DetailLesson,
  DetailLessonAdmin,
  KuisMandiriAnswer,
} from "./pages";
import { SyaratDanKetentuan } from "./pages";
import { useEffect } from "react";
import CustomerRoutes from "./CustomerRoutes";
import AdminRoutes from "./AdminRoutes";
import { useDispatch, useSelector } from "react-redux";
import LessonList from "./pages/admin/AdminBimbel/LessonList";
import CreateLesson from "./pages/admin/AdminBimbel/CreateLesson";
import Webinar from "./pages/admin/AdminBimbel/Webinar";
import WebinarList from "./pages/admin/AdminBimbel/WebinarList";
import WebinarAdd from "./pages/admin/AdminBimbel/WebinarAdd";
import { BimbelInformation } from "./services";
import { login } from "./redux/features/customerSlice";

function App() {
  const { customer } = useSelector((state) => state);
  const { theoryType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => {
            if (customer) {
              if (customer.role === 2) {
                return <AdminSchedule />;
              } else if (customer.role === 1) {
                return <Schedule />;
              } else {
                return <PreLogin />;
              }
            } else {
              return <PreLogin />;
            }
          }}
        />

        <Route
          path="/auth/login"
          render={(props) => {
            if (customer) {
              if (customer.role === 2) {
                return <AdminSchedule />;
              } else if (customer.role === 1) {
                return <Schedule />;
              } else {
                return <Login />;
              }
            } else {
              return <Login />;
            }
          }}
        />

        <Route
          path="/auth/register"
          render={(props) => {
            if (customer) {
              if (customer.role === 2) {
                return <AdminSchedule />;
              } else if (customer.role === 1) {
                return <Schedule />;
              } else {
                return <Register />;
              }
            } else {
              return <Register />;
            }
          }}
        />

        {/* Admin route */}
        <AdminRoutes
          exact
          path="/admin/schedule/list"
          component={AdminSchedule}
        />
        <AdminRoutes
          exact
          path="/admin/schedule/create"
          component={AdminScheduleCreate}
        />

        <AdminRoutes
          exact
          path="/admin/schedule/update"
          component={AdminScheduleUpdate}
        />

        <AdminRoutes
          exact
          path="/admin/laboratory-value"
          component={AdminLaboratoryValue}
        />

        <AdminRoutes
          exact
          path="/admin/laboratory-value/create"
          component={AdminLaboratoryValueCreate}
        />

        <AdminRoutes
          exact
          path="/admin/laboratory-value/update"
          component={AdminLaboratoryValueUpdate}
        />

        <AdminRoutes exact path="/admin/theory/ukom" component={AdminTheory} />

        <AdminRoutes
          exact
          path="/admin/theory/create"
          component={AdminTheoryCreate}
        />

        <AdminRoutes
          exact
          path="/admin/theory/update"
          component={AdminTheoryUpdate}
        />

        <AdminRoutes
          exact
          path="/admin/theory/skb-gizi"
          component={AdminTheory}
        />

        <AdminRoutes
          exact
          path="/admin/try-out/ukom"
          component={AdminQuestion}
        />

        <AdminRoutes
          exact
          path="/admin/try-out/skb-gizi"
          component={AdminQuestion}
        />

        <AdminRoutes
          exact
          path="/admin/try-out/d3/question/:secureId"
          component={AdminQuestionList}
        />
        <AdminRoutes
          exact
          path="/admin/try-out/d4/question/:secureId"
          component={AdminQuestionList}
        />
        <AdminRoutes
          exact
          path="/admin/try-out/s1/question/:secureId"
          component={AdminQuestionList}
        />
        <AdminRoutes
          exact
          path="/admin/try-out/pfs/question/:secureId"
          component={AdminQuestionList}
        />

        <AdminRoutes
          exact
          path="/admin/question/create"
          component={AdminQuestionCreate}
        />
        <AdminRoutes
          exact
          path="/admin/try-out/d3/createPacket"
          component={CreatePacket}
        />
        <AdminRoutes
          exact
          path="/admin/try-out/question/update"
          component={AdminQuestionUpdate}
        />

        <AdminRoutes
          exact
          path="/admin/quiz-practice/question/update"
          component={AdminQuizPracticeUpdate}
        />

        <AdminRoutes
          exact
          path="/admin/kuis-mandiri"
          component={AdminQuizPractice}
        />
        <AdminRoutes
          exact
          path="/admin/contoh-soal"
          component={AdminQuizPractice}
        />
        <AdminRoutes exact path="/admin/institute" component={AdminInstitute} />
        <AdminRoutes
          exact
          path="/admin/institute/create"
          component={AdminInstituteCreate}
        />

        <AdminRoutes exact path="/admin/bimbel" component={AdminBimbelList} />
        <AdminRoutes
          exact
          path="/admin/bimbel/update"
          component={BimbelUpdate}
        />
        <AdminRoutes
          exact
          path="/admin/feature-packet"
          component={FeatureList}
        />

        <AdminRoutes
          exact
          path="/admin/feature-packet/add"
          component={FeatureAdd}
        />
        <AdminRoutes exact path="/admin/webinar" component={Webinar} />
        <AdminRoutes exact path="/admin/webinar/list" component={WebinarList} />
        <AdminRoutes exact path="/admin/webinar/add" component={WebinarAdd} />
        <AdminRoutes exact path="/admin/lesson" component={Lesson} />
        <AdminRoutes exact path="/admin/lesson/list" component={LessonList} />
        <AdminRoutes exact path="/admin/lesson/add" component={CreateLesson} />
        <AdminRoutes
          exact
          path="/admin/lesson/detail"
          component={DetailLessonAdmin}
        />
        {/* Customer route */}
        <CustomerRoutes path="/schedule/list" component={Schedule} />

        <CustomerRoutes
          exact
          path={`/materi/${theoryType === 1 ? "ukom" : "skb-gizi"}`}
          component={Materi}
        />

        <CustomerRoutes path="/contoh-soal" component={ContohSoal} />

        <CustomerRoutes exact path="/kuis-mandiri" component={KuisMandiri} />
        <CustomerRoutes
          exact
          path="/kuis-mandiri/start"
          component={KuisMandiriExam}
        />
        <CustomerRoutes
          exact
          path="/kuis-jawaban"
          component={KuisMandiriAnswer}
        />
        <CustomerRoutes exact path="/detail-lesson" component={DetailLesson} />
        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}`}
          component={TryOut}
        />

        <CustomerRoutes
          exact
          path={`/try-out/${
            questionType === 3 ? "ukom" : "skb-gizi"
          }/ketentuan`}
          component={TryOutKetentuan}
        />

        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/start`}
          component={TryOutQuiz}
        />

        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/finish`}
          component={TryOutFinish}
        />
        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/result`}
          component={TryOutResult}
        />
        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/ranking`}
          component={TryOutRanking}
        />
        <CustomerRoutes
          exact
          path={`/try-out/${
            questionType === 3 ? "ukom" : "skb-gizi"
          }/ranking/list`}
          component={TryOutRankingList}
        />
        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/key`}
          component={TryOutKey}
        />
        <CustomerRoutes
          exact
          path={`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/video`}
          component={TryOutVideoPembahasan}
        />

        {/* <CustomerRoutes path="/try-out/skb-gizi" component={TryOut} /> */}
        <CustomerRoutes exact path="/forum-diskusi" component={ForumDiskusi} />
        <Route
          path="/bimbel"
          render={(props) => {
            if (customer) {
              if (customer.premium) {
                return <BimbelPremium />;
              } else {
                return <Bimbel />;
              }
            } else {
              return <NotFound />;
            }
          }}
        />
        <CustomerRoutes path="/fill-info" component={AskInfo} />
        <CustomerRoutes path="/pilih-packet" component={Bimbel} />
        <CustomerRoutes path="/success-payment" component={PaymentSuccess} />
        {/* <CustomerRoutes path="/bimbel-premium" component={BimbelPremium} /> */}
        <CustomerRoutes path="/try-out/ukom" component={PopUp_notification} />
        <CustomerRoutes
          path="/try-out/skb-gizi"
          component={PopUp_notification}
        />
        <Route path="/syarat-ketentuan" component={SyaratDanKetentuan} />
        <CustomerRoutes path="/to-application" component={ToApplication} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
