import axios from "axios";

export const UpdateQuestionApi = async (questionData, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/question/update?";
    const endpoint = `${BASED_URL}${path}token=${token}`;
    const response = await axios.put(endpoint, questionData);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default UpdateQuestionApi;
