import axios from "axios";

export const FeatureCreateAPI = async (token, data) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `?token=${token}`;
    const url = `${BASED_URL}/admin/package-feature/create${params}`;
    const res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default FeatureCreateAPI;
