import { useState } from "react";
import { Link } from "react-router-dom";
import { EntryPage } from "../style";
import Logo from "../../assets/img/logo-ilmugiziku.png";
import {
  ButtonLogin,
  TogglePassword,
  EntryCard,
  InputGroup,
  Input,
} from "../../components";
import useRegister from "./useRegister";
import validate from "./validationRegister";

const Register = () => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const [isRevealConfPwd, setIsRevealConfPwd] = useState(false);

  const { handleChange, values, handleSubmit, errors, loading } =
    useRegister(validate);

  return (
    <EntryPage>
      <EntryCard>
        <Link to="/">
          <img src={Logo} width="100" alt="signup" />
        </Link>
        <h1>ILMUGIZIKU</h1>
        <p>Dashboard Platform Belajar Gizi</p>
        <br />
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <label htmlFor="signup-email">Email Address</label>
            <Input
              autoComplete="off"
              type="email"
              placeholder="name@email.com"
              name="email"
              id="signup-email"
              value={values.email}
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-danger small">{errors.email}</p>
            )}
          </InputGroup>

          <InputGroup>
            <label htmlFor="firstName">First Name</label>
            <Input
              autoComplete="off"
              type="text"
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              placeholder="John Doe"
              id="firstName"
            />
            {errors.firstName && (
              <p className="text-danger small">{errors.firstName}</p>
            )}
          </InputGroup>

          <InputGroup>
            <label htmlFor="LastName">Last Name</label>
            <Input
              autoComplete="off"
              type="text"
              name="lastName"
              placeholder="John Doe"
              id="LastName"
              value={values.lastName}
              onChange={handleChange}
            />
            {errors.lastName && (
              <p className="text-danger small">{errors.lastName}</p>
            )}
          </InputGroup>

          <InputGroup>
            <p>Gender</p>
            <div>
              <label
                style={{ color: "#000", fontWeight: "normal" }}
                htmlFor="pria"
              >
                Pria{" "}
              </label>
              <input
                autoComplete="off"
                style={{
                  margin: "15px 20px 0px 7px",
                  alignItems: "center",
                }}
                checked={values.gender === "Pria"}
                name="gender"
                id="pria"
                type="radio"
                value="Pria"
                onChange={handleChange}
              />

              <label
                style={{ color: "#000", fontWeight: "normal" }}
                htmlFor="wanita"
              >
                Wanita{" "}
              </label>
              <input
                autoComplete="off"
                style={{
                  marginLeft: "7px",
                  alignItems: "center",
                }}
                checked={values.gender === "Wanita"}
                id="wanita"
                name="gender"
                type="radio"
                value="Wanita"
                onChange={handleChange}
              />
            </div>
            {errors.gender && (
              <p className="text-danger small">{errors.gender}</p>
            )}
          </InputGroup>

          <InputGroup>
            <label htmlFor="login-password">Password</label>
            <Input
              autoComplete="off"
              id="login-password"
              name="password"
              placeholder="Password"
              type={isRevealPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
            />
            <TogglePassword
              isRevealPassword={isRevealPassword}
              setIsRevealPassword={setIsRevealPassword}
              onClick={() => setIsRevealPassword((prevState) => !prevState)}
            />
            {errors.password && (
              <p className="text-danger small">{errors.password}</p>
            )}
          </InputGroup>

          <InputGroup>
            <label htmlFor="confirm-password">Confirm Password</label>
            <Input
              autoComplete="off"
              id="confirm-password"
              name="confPassword"
              placeholder="Confirm Password"
              type={isRevealConfPwd ? "text" : "password"}
              value={values.confPassword}
              onChange={handleChange}
            />
            <TogglePassword
              id="confirm-password"
              isRevealPassword={isRevealConfPwd}
              setIsRevealPassword={setIsRevealConfPwd}
              onClick={() => setIsRevealConfPwd((prevState) => !prevState)}
            />
            {errors.confPassword && (
              <p className="text-danger small">{errors.confPassword}</p>
            )}
            {errors.failed && (
              <p className="text-danger small">{errors.failed}</p>
            )}
            {errors.errMessg && (
              <p className="text-danger small">{errors.errMessg}</p>
            )}
          </InputGroup>

          <span className="mb-4">
            Dengan daftar kalian setuju terhadap
            <Link to="#" style={{ color: "#fff" }}>
              Syarat dan Ketentuan
            </Link>
          </span>

          {errors.dataExist && (
            <p className="text-danger small">{errors.dataExist}</p>
          )}
          <ButtonLogin type="submit" full>
            {loading ? "Loading..." : "Sign up"}
          </ButtonLogin>
        </form>
        <span>
          Sudah Punya Akun?
          <Link to="/auth/login" style={{ color: "#fff" }}>
            Log in
          </Link>
        </span>
      </EntryCard>
    </EntryPage>
  );
};

export default Register;
