import firebase from "../../config/FirebaseConfig";

const AuthGoogleApi = (provider) => {
  return firebase
    .auth()
    .signInWithPopup(provider)
    .then((res) => {
      return res.user;
    })
    .catch((err) => {
      return err;
    });
};

export default AuthGoogleApi;
