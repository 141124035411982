import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import "./CSS/PopUp.css";
import React, { Component } from "react";
import upgrade_premium from "../assets/img/Upgrade_Premium.JPG";

class PopUp_notification extends Component {
  constructor() {
    super();
    this.state = { isOpen: false };
  }

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div
        className="overlay"
        style={{ display: this.state.isOpen ? "absolute" : "none" }}
      >
        <div className="modal-content">
          <h2 style={{ paddingTop: "1px" }}>Akun kamu belum Premium nih</h2>
          <div>
            <img
              src={upgrade_premium}
              alt="upgrade-premium"
              border="0"
              style={{ maxWidth: "400px" }}
            />
            <Link to="/bimbel">
              <Button
                size="sm"
                color="danger"
                style={{
                  position: "absolute",
                  width: "250px",
                  height: "45px",
                  fontSize: "20px",
                  right: "70px",
                  top: "88%",
                }}
              >
                Upgrade Akun Kamu
              </Button>
            </Link>
          </div>
          {/* <Button className="close-modal" onClick={ this.handleClose }>
            X
          </Button> */}
          <Button onClick={() => this.handleClose()} className="close-modal">
            X
          </Button>
        </div>
      </div>
    );
  }
}

export default PopUp_notification;
