import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthRegisterApi } from "../../services";

const useRegister = (validate) => {
  const [values, setValues] = useState({
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    password: "",
    confPassword: "",
    registrationType: 1,
    role: 1,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let history = useHistory();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const postRegister = async () => {
        setLoading(true);
        const res = await AuthRegisterApi(values);

        if (res.statusCode === 99) {
          errors.dataExist =
            "E-mail telah terdaftar, Silahkan gunakan e-mail lain.";
          setLoading(false);
        } else if (res.statusCode === 90) {
          errors.failed =
            "Gagal menyimpan data, Silahkan coba beberapa saat lagi.";
          setLoading(false);
        } else if (res.statusCode === 20) {
          window.alert("Berhasil Buat Akun, Silahkan login.");
          setLoading(false);
          return history.push("/auth/login");
        } else {
          errors.errMessg = res.message;
          setLoading(false);
        }
      };
      postRegister();
    }
  }, [errors, isSubmitting, values, history]);

  return { handleChange, values, handleSubmit, errors, loading };
};

export default useRegister;
