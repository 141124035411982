import Layout from "../Layout";
import * as RiIcons from "react-icons/ri";
import { CardComponent } from "../../components";
import { MdGradient } from "react-icons/md";

export default function KuisMandiri() {
  return (
    <Layout>
      <div className="row">
        <p>Kamu tinggal klik "Mulai" untuk memulai mengisi Kuis</p>

        <div className="d-flex justify-content-lg-center text-center">
          <CardComponent
            title="Mulai Kuis (20 Soal)"
            url={`/kuis-mandiri/start`}
            buttonTeks="Mulai"
          >
            <RiIcons.RiPencilRulerLine
              color="#54c2b7"
              style={{ width: "350px", height: "450px" }}
            />
          </CardComponent>
        </div>
      </div>
    </Layout>
  );
}
