import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import {
  QuestionListApi,
  ExamSubmitApi,
  LaboratoryValueApi,
} from "../../services";
import { useSelector, useDispatch } from "react-redux";
import { getQuizAnsweredUser } from "../../redux/features/customerSlice";
import {
  Table,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as IconArrowLeft from "react-icons/bi";
import { CountDown, Flag, ResumeSoal, Kalkulator } from "../../components";
import { useHistory } from "react-router-dom";

export const Quiz = (props) => {
  const { customer } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const { quizAnsweredUser } = useSelector((state) => state);

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [quesList, setQuesList] = useState();
  const [quesAnswers, setQuesAnswers] = useState({
    exams: [],
    questionSubType,
    questionType,
  });
  const [labValue, setLabValue] = useState();
  const [isLabValue, setIsLabValue] = useState(false);
  const [isKalkulator, setIsKalkulator] = useState(false);

  const [waktu, setWaktu] = useState();
  const [modal, setModal] = useState(false);
  useEffect(async () => {
    if (
      quizAnsweredUser &&
      quizAnsweredUser[0] === questionType &&
      quizAnsweredUser[1] === questionSubType
    ) {
      return history.push(
        `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}`
      );
    } else {
      const res = await QuestionListApi(
        questionSubType,
        questionType,
        customer.token
      );

      if (res && res.statusCode === 20) {
        setQuesList(res.result.exam);
        setWaktu(res.result.timeLimit);
        window.localStorage.setItem("waktu", res.result.timeLimit);
      }
    }
  }, []);
  const toggle = () => {
    setIsLabValue(false);
    setIsKalkulator(false);
    setModal(!modal);
  };

  let history = useHistory();
  const dispatch = useDispatch();

  // Variabel Penampung Semua Jawaban no flag untuk di submit
  const answeredQuestion = quesList?.filter((val, idx) => {
    return !val.flag && val.selected;
  });

  const nextClickHandler = () => {
    if (quesList && quesList.length - 1 === activeQuestion) return;

    setActiveQuestion(activeQuestion + 1);
  };

  const prevClickHandler = () => {
    if (activeQuestion === 0) return;

    setActiveQuestion(activeQuestion - 1);
  };

  const selectOption = (indexSelected, indexOptionSelected) => {
    setQuesList(
      quesList.map((val, idx) =>
        idx === indexSelected
          ? {
              ...val,
              selected: true,
              answers: quesList[activeQuestion].answers.map((item, index) =>
                index === indexOptionSelected
                  ? { ...item, selected: true }
                  : { ...item, selected: false }
              ),
            }
          : val
      )
    );
  };

  const submitQuestion = (e) => {
    e.preventDefault();
    setModal(!modal);

    //Kondisi untuk mensubmit Jawaban
    if (answeredQuestion && answeredQuestion.length === quesList.length) {
      quesList.map((value, index) => {
        if (value.selected) {
          value.answers.map((answers, ansIndex) => {
            if (answers.selected) {
              quesAnswers.exams.push({
                questionsSecureId: value.secureId,
                answersSecureId: answers.secureId,
              });
            }
          });
        }
      });
    }
  };

  const showLabValue = async () => {
    toggle();
    setIsLabValue(true);
    return await LaboratoryValueApi(customer.token)
      .then((data) => setLabValue(data.result))
      .catch((error) => console.log(error));
  };

  return (
    <Layout>
      <h3>
        {`Paket Try Out ${
          questionSubType === 1
            ? "D3"
            : questionSubType === 2 && questionType === 3
            ? "D4"
            : questionSubType === 2 && questionType === 4
            ? "D4/S1"
            : questionSubType === 3
            ? "S1"
            : questionSubType === 4
            ? "Pfs"
            : ""
        } ${
          questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"
        }`}
      </h3>
      <div className="row">
        <div className="col-6 d-flex align-items-center">
          <span>
            Dijawab = <div className="done" /> Ragu= <div className="on-progress" /> Belum Dijawab= <div className="not-yet" />
          </span>
        </div>
        <div className="col-6 d-flex justify-content-end">
          <CountDown
            expiryTimestamp={waktu}
            quesList={quesList}
            quesAnswers={quesAnswers}
          />
        </div>
      </div>

      <Table responsive>
        <thead>
          <tr style={{ background: "#54C2B7" }}>
            <th className="border-0">No</th>
            <th className="border-0" style={{width: "100%"}}>Soal</th>
            <th className="border-0">
              <Flag
                quesList={quesList}
                activeQuestion={activeQuestion}
                setQuesList={setQuesList}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="border-0">
              <p className="fs-5" style={{fontWeight: "bold"}}>
                {activeQuestion + 1}.
              </p>
            </th>
            <td className="border-0">
              <p className="fs-5" style={{fontWeight: "bold"}}>
                {quesList && quesList[activeQuestion].content}
              </p>
              {quesList &&
                quesList[activeQuestion].answers.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      style={{
                        fontSize: 18,
                        cursor: "pointer",
                        padding: 10,
                        margin: 5,
                        backgroundColor: val.selected
                          ? "#7AB2D7"
                          : "transparent",
                      }}
                      onClick={() => selectOption(activeQuestion, idx)}
                    >
                      <span className="small">
                        {idx === 0
                          ? "A. "
                          : idx === 1
                          ? "B. "
                          : idx === 2
                          ? "C. "
                          : idx === 3
                          ? "D. "
                          : idx === 4
                          ? "E. "
                          : ""}
                        {val.content}
                      </span>
                    </div>
                  );
                })}
            </td>
            <td className="border-0"></td>
          </tr>
        </tbody>
      </Table>

      <Modal isOpen={modal} toggle={toggle} style={{ marginTop: 360}}>
        <ModalHeader toggle={toggle}>
          {isLabValue && "Nilai Laboratorium"}
          {isKalkulator && "Kalkulator"}
        </ModalHeader>
        <ModalBody style={{ overflow: "auto", maxHeight: 510 }}>
          {isLabValue ? (
            <Table>
              <thead>
                <tr>
                  <th>Ukuran</th>
                  <th>Satuan</th>
                  <th>Nilai Rujukan</th>
                </tr>
              </thead>
              <tbody>
                {labValue?.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{value.measure}</td>
                      <td>{value.unit}</td>
                      <td>{value.referenceValue}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : isKalkulator ? (
            <Kalkulator />
          ) : answeredQuestion?.length === quesList?.length ? (
            <h5 className="text-center">
              Apakah Anda Yakin ingin menyelesaikan quiz?
            </h5>
          ) : (
            <h5 className="text-center">
              Pastikan Anda sudah menjawab semua pertanyaan dan tidak di beri
              flag.
            </h5>
          )}
        </ModalBody>
        <ModalFooter hidden={isKalkulator || isLabValue}>
          {answeredQuestion?.length === quesList?.length && (
            <>
              <Button
                color="primary"
                onClick={async () => {
                  await ExamSubmitApi(quesAnswers, customer.token)
                    .then((data) => {
                      if (data.statusCode === 20) {
                        dispatch(
                          getQuizAnsweredUser([questionType, questionSubType])
                        );
                        return history.push(
                          `/try-out/${
                            questionType === 3 ? "ukom" : "skb-gizi"
                          }/finish`
                        );
                      }
                    })
                    .catch((error) => console.log(error));
                }}
              >
                Ya
              </Button>
              <Button color="secondary" onClick={toggle}>
                Tidak
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      <div className="row">
        <div className="col-6 d-flex justify-content-start">
          <Button
            style={{ background: "#54C2B7", border: "none" }}
            className={`btn btn-border-default ${
              activeQuestion === 0 && "disabled"
            } rounded-circle`}
            onClick={() => prevClickHandler()}
          >
            <IconArrowLeft.BiLeftArrow className="d-flex justify-content-center" />
          </Button>
        </div>

        <div className="col-6 d-flex justify-content-end">
          <Button
            style={{ background: "#54C2B7", border: "none" }}
            className={`btn btn-border-default ${
              quesList?.length - 1 === activeQuestion && "disabled"
            } rounded-circle`}
            onClick={() => nextClickHandler()}
          >
            <IconArrowLeft.BiRightArrow className="d-flex justify-content-center" />
          </Button>
        </div>
      </div>
      <hr />

      <h2 className="h3">Resume Soal</h2>
      <div className="row">
        <div className="col-7 mb-2 d-flex justify-content-center flex-wrap">
          <ResumeSoal
            quesList={quesList}
            setActiveQuestion={setActiveQuestion}
          />
        </div>

        <div className="col-5">
          <div className="d-flex justify-content-end">
            <Button
              style={{ background: "#54C2B7" }}
              type="button"
              className="w-100 mb-2 border-0 shadow"
              onClick={showLabValue}
            >
              Nilai Laboratorium
            </Button>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              style={{ background: "#54C2B7" }}
              type="button"
              className="w-100 mb-2 border-0 shadow"
              onClick={() => {
                toggle();
                setIsKalkulator(true);
              }}
            >
              Kalkulator
            </Button>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              style={{ background: "#54C2B7" }}
              type="submit"
              className="w-100 mb-2 bg-danger border-0 shadow"
              onClick={submitQuestion}
            >
              Selesai
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
