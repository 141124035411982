import axios from "axios";

export const QuestionApi = async (
  questionSubType,
  questionType,
  token,
  templateId
) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/exam/try-out/list?";
    const secureId =
      templateId == undefined
        ? "templateSecureId= "
        : `templateSecureId=${templateId}`;
    const endpoint = `${BASED_URL}${path}questionSubType=${questionSubType}&questionType=${questionType}&token=${token}&${secureId}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export default QuestionApi;
