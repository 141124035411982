const ValidationLesson = (data) => {
  let error = {};
  if (!data.title) {
    error.title = "Masukkan Title Terlebih dahulu";
  }
  if (!data.theory) {
    error.theory = "Masukkan Theori Terlebih dahulu";
  }
  if (!data.thumbnailUri) {
    error.thumbnailUri = "Masukkan Gambar Terlebih dahulu";
  } else {
    const imageType = ["image/bmp", "image/jpg", "image/jpeg"];
    if (data.thumbnailUri && !imageType.includes(data.thumbnailUri)) {
      error.thumbnailUri =
        "Format gambar salah harus format image/bmp, image/jpg, image/jpeg";
    }
  }
  if (!data.videoUri) {
    error.videoUri = "Masukkan Video Terlebih dahulu";
  } else {
    const fileType = ["video/mp4"];
    if (data.videoUri && !fileType.includes(data.videoUri)) {
      error.videoUri = "Format Video salah harus format video/mp4";
    }
  }
  return error;
};

export default ValidationLesson;
