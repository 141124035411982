import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import CreateSchedule from "./CreateSchedule";
import validationCreate from "./validationCreate";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";

const Create = () => {
  const { customer } = useSelector((state) => state);

  const {
    handleChange,
    handleSubmit,
    errors,
    loading,
    schedules,
    isSubmitting,
  } = CreateSchedule(validationCreate, customer.token);

  const day = new Date();
  const nextDay = new Date(day);
  nextDay.setDate(nextDay.getDate() + 1);

  return (
    <LayoutAdmin>
      <h1>Buat Schedule</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="desc" sm={2}>
            Description :
          </Label>
          <Col>
            <Input
              type="text"
              name="desc"
              // defaultValue={schedules.desc}
              id="desc"
              // ref={React.createRef()}
              onChange={handleChange}
            />
            {errors.desc && <p className="text-danger small">{errors.desc}</p>}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="startDate">
            Start Date :
          </Label>
          <Col>
            <Input
              type="date"
              min={nextDay.toISOString().substring(0, 10)}
              name="startDate"
              // defaultValue={schedules.startDate}
              // ref={React.createRef()}
              id="startDate"
              onChange={handleChange}
            />
            {errors.startDate && (
              <p className="text-danger small">{errors.startDate}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="endDate">
            End Date
          </Label>
          <Col>
            <Input
              type="date"
              min={schedules.startDate}
              name="endDate"
              // defaultValue={schedules.endDate}
              disabled={schedules.startDate === "" && true}
              // ref={React.createRef()}
              id="endDate"
              onChange={handleChange}
            />
            {errors.endDate && (
              <p className="text-danger small">{errors.endDate}</p>
            )}
          </Col>
        </FormGroup>

        {Object.keys(errors).length === 0 && !loading && isSubmitting && (
          <Alert color="success">Berhasil Membuat Schedule.</Alert>
        )}

        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Buat"}
          </button>
          <BackComponent url="/admin/schedule/list" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Create;
