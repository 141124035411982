import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/features/customerSlice";
import firebase from "firebase/app";
import "firebase/auth";
import { AuthLogoutApi } from "../../services";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import Logo from "../../assets/img/logo-ilmugiziku.png";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { useHistory } from "react-router-dom";

import { Link } from "react-router-dom";

export const NavbarComponent = ({ toggleSidebar }) => {
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);

  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();
  let history = useHistory();

  const handlePacket = () => {
    return history.push("/pilih-packet");
  };

  const handleLogout = async () => {
    const userLogout = await AuthLogoutApi(customer.token);
    if (userLogout.data.statusCode === 20) {
      console.log("token dihapus");
      dispatch(logout());
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("Sign out success");
        })
        .catch((error) => {
          console.log("Sign out failed.");
        });
    } else {
      // return window.alert("Terjadi kesalahan saat logout.");
      window.alert(userLogout.data.message);
    }
    return history.push("/");
  };
  // console.log("window.innerWidth");
  // console.log(window.innerWidth);

  return (
    <Navbar
      style={{ backgroundColor: "#54C2B7" }}
      light
      className="navbar p-3"
      expand="md"
    >
      <Button className="fs-2" color="white" onClick={toggleSidebar}>
        <FaIcons.FaBars />
      </Button>
      {/* <img
        className="bg-light rounded-circle"
        style={{ height: "50px", width: "50px" }}
        src={Logo}
        alt="Logo"
      /> */}
      <NavbarToggler onClick={toggleTopbar} />
      <Collapse isOpen={topbarIsOpen} navbar>
        <Nav className="container-fluid justify-content-md-end mx-sm-3">
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="border-0 bg-transparent navi " caret>
              <div
                className="circleBorder"
                style={{
                  border: customer.premium ? "2px solid red" : "2px solid gray",
                  borderRadius: "50%",
                }}
              ></div>
              {customer ? (
                customer.firstName.length >= 12 ? (
                  <span className="text-white">
                    {customer.firstName.substring(0, 12) + "..."}
                  </span>
                ) : (
                  <span className="text-white">{customer.firstName}</span>
                )
              ) : (
                ""
              )}
            </DropdownToggle>

            <DropdownMenu style={{ position: "absolute", right: 0 }}>
              <DropdownItem
                onClick={() => {
                  handlePacket();
                }}
                style={{
                  fontSize: "17px",
                }}
              >
                Lihat Paket
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                onClick={() => {
                  handleLogout();
                }}
                style={{
                  fontSize: "17px",
                }}
              >
                <AiIcons.AiOutlineLogout color="#000" /> Logout{" "}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default NavbarComponent;

//   <Collapse isOpen={topbarIsOpen} navbar>
//         <Nav className="container-fluid justify-content-md-end mx-sm-3 ">
//           <NavItem>
//                         <NavLink tag={Link} to="">
//                             <AiIcons.AiOutlineMail />
//                         </NavLink>
//                     </NavItem>
//                     <NavItem>
//                         <NavLink tag={Link} to="">
//                             <HiIcons.HiSpeakerphone />
//                         </NavLink>
//                     </NavItem>
//           <NavItem>
//           <NavLink tag={Link} to={"/page-3"}>
// </NavLink>
// </NavItem>
//           <UncontrolledDropdown nav inNavbar>
//             <DropdownToggle className="border-0 bg-transparent navi" caret>
//               <div
//                 className="circleBorder"
//                 style={{
//                   border: customer.premium ? "2px solid red" : "2px solid gray",
//                   borderRadius: "50%",
//                 }}
//               ></div>
//               {customer ? (
//                 customer.firstName.length >= 12 ? (
//                   <span className="text-white">
//                     {customer.firstName.substring(0, 12) + "..."}
//                   </span>
//                 ) : (
//                   <span className="text-white">{customer.firstName}</span>
//                 )
//               ) : (
//                 ""
//               )}
//             </DropdownToggle>

//             <DropdownMenu left>
//               <DropdownItem
//                 onClick={() => {
//                   handlePacket();
//                 }}
//               >
//                 Lihat Packet
//               </DropdownItem>
//               <DropdownItem divider />
//               <DropdownItem
//                 onClick={() => {
//                   handleLogout();
//                 }}
//               >
//                 <AiIcons.AiOutlineLogout color="#000" /> Logout{" "}
//               </DropdownItem>
//             </DropdownMenu>
//           </UncontrolledDropdown>
//  <NavItem>
//             <NavLink tag={Link} to="#">
//               <FaIcons.FaUserAlt />
//             </NavLink>
//           </NavItem>
//         </Nav>
//       </Collapse>
