import React, { useEffect, useState } from "react";
import { ExamInformation } from "../../services";
import * as AiIcons from "react-icons/ai";
import { Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../Layout";
import { CollapseComponent } from "../../components";
import { Link } from "react-router-dom";
import { getQuestionSubType } from "../../redux/features/customerSlice";

const Ketentuan = () => {
  const { customer } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  let [examInfo, setExamInfo] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchExamInfo = async () => {
      const res = await ExamInformation(questionType, customer.token);
      if (res.statusCode === 20) {
        if (questionType === 4) {
          res.result.pop();
          res.result.pop();
        }

        setExamInfo(res.result);
      } else {
        console.log("Exam info Api gagal di ambil");
      }
    };

    fetchExamInfo();
  }, [questionType]);

  const handleDispatch = (val) => {
    dispatch(getQuestionSubType(val.questionSubType));
  };
  return (
    <Layout>
      <div className="py-5">
        {examInfo &&
          examInfo.map((val, idx) => {
            return (
              <CollapseComponent
                key={idx}
                title={`${
                  val.questionSubType === 1
                    ? "D3"
                    : val.questionSubType === 2 && questionType === 3
                    ? "D4"
                    : val.questionSubType === 2 && questionType === 4
                    ? "D4/S1"
                    : val.questionSubType === 3
                    ? "S1"
                    : val.questionSubType === 4
                    ? "Pfs"
                    : ""
                } Try Out ${
                  questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"
                }`}
              >
                {val.open ? (
                  <>
                    <h5>Ketentuan : </h5>
                    <ol>
                      <li>
                        Soal Terdapat {val.questionTotal} buah dengan jawaban
                        pilihan ganda.
                      </li>
                      <li>
                        Waktu pengerjaan selama {val.timeLimit} menit terhitung sejak mulai mengisi
                        data.
                      </li>
                      <li>
                        Jika terjadi kendala jaringan, kemungkinan karena
                        koneksi internet anda kurang stabil. Jadi mohon untuk
                        mempersiapkan backup jawaban menggunakan tulisan tangan
                        agar dapat mengisi soal yang sudah dikerjakan dengan
                        mudah.
                      </li>
                      <li>
                        Jangan lupa untuk berdoa diawal dan bersyukur ketika
                        sudah selesai mengerjakan soal.
                      </li>
                      <li>Selamat mengerjakan.</li>
                    </ol>
                    <Link
                      to={`/try-out/${
                        questionType === 3 ? "ukom" : "skb-gizi"
                      }/start`}
                    >
                      <Button
                        className="btn btnPilih"
                        onClick={() => {
                          handleDispatch(val);
                        }}
                      >
                        Mulai Try Out
                      </Button>
                    </Link>
                  </>
                ) : (
                  <h2 className="text-center text-danger">
                    Anda sudah menjawab exam ini.
                  </h2>
                )}
              </CollapseComponent>
            );
          })}
      </div>
    </Layout>
  );
};

export default Ketentuan;
