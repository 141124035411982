import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import * as AiIcons from "react-icons/ai";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { getSchedule } from "../../../redux/features/customerSlice";
import LayoutAdmin from "../LayoutAdmin";
import { ScheduleListApi, DeleteScheduleApi } from "../../../services";

const { SearchBar } = Search;

const AdminSchedule = () => {
  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(false);
  const [listsError, setListError] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [schedule, setSchedule] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { customer } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteSchedule = (data) => {
    setSchedule(data);
    setModal(!modal);
  };

  useEffect(() => {
    const fetchList = async () => {
      setListLoading(true);
      const res = await ScheduleListApi(customer.token);
      if (res.statusCode === 20) {
        setList(res.result);
        setListLoading(false);
      } else {
        setListError(true);
        setListLoading(false);
      }
    };
    fetchList();
  }, [customer]);

  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "desc",
      text: "Description",
      sort: true,
    },
    {
      dataField: "startDate",
      text: "Start Date",
      sort: true,
      formatter: (cell, row) => {
        return row.startDate.substring(0, 10);
      },
    },
    {
      dataField: "endDate",
      text: "End Date",
      sort: true,
      formatter: (cell, row) => {
        return row.endDate.substring(0, 10);
      },
    },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col>
                <Link to={`/admin/schedule/update`}>
                  <Button
                    color="warning"
                    onClick={() => dispatch(getSchedule(row))}
                  >
                    <AiIcons.AiOutlineEdit />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button color="danger" onClick={() => deleteSchedule(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "firstName",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <h1>Daftar Jadwal</h1>
      <Modal isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>{schedule.desc}</ModalHeader> */}
        <ModalBody>
          <p className="text-center">
            <strong>Detail</strong>
          </p>
          <ul>
            <li>Desc : {schedule.desc}</li>
            <li>Start : {schedule.startDate}</li>
            <li>End : {schedule.endDate}</li>
            <li>Time : {schedule.timeLimit} Menit</li>
          </ul>
          <h5 className="text-center">
            Apakah Anda Yakin Akan Menghapus Data?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              toggle();
              await DeleteScheduleApi(schedule.secureId, customer.token);
              setList(
                list.filter((item) => {
                  return item.secureId !== schedule.secureId;
                })
              );
              setIsDelete(true);
            }}
          >
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Tidak
          </Button>
        </ModalFooter>
      </Modal>

      {isDelete && <Alert color="success">Data berhasil dihapus.</Alert>}

      <ToolkitProvider
        bootstrap4
        defaultSorted={defaultSorted}
        keyField="secureId"
        data={list}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <Link to="/admin/schedule/create">
                  <Button color="primary mb-4 ">
                    <AiIcons.AiOutlineSchedule /> Buat
                  </Button>
                </Link>
              </Col>
              <Col>
                <div className="offset-8 mb-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </Col>
            </Row>

            {listLoading ? (
              <div className="text-center">
                <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
              </div>
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            )}

            {listsError && (
              <Alert color="danger">
                Terjadi Kesalahan saat pegambilan Data
              </Alert>
            )}
          </div>
        )}
      </ToolkitProvider>
    </LayoutAdmin>
  );
};

export default AdminSchedule;
