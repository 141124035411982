import React from "react";
import Layout from "./Layout";

export default function ToApplication() {
  return (
    <Layout>
      <div className="to-application">
        <h1>To Aplication</h1>
      </div>
    </Layout>
  );
}
