import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { useSelector } from "react-redux";
import { ExamRankingApi } from "../../../services";

const List = () => {
  const { customer } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);

  const [ranking, setRanking] = useState();

  useEffect(async () => {
    await ExamRankingApi(questionSubType, questionType, customer.token)
      .then((data) => setRanking(data.result))
      .catch((error) => console.log(error));
  }, []);
  return (
    <Layout>
      <p>
        Halaman Ranking Peserta Try Out{" "}
        {questionType == 3
          ? questionSubType == 1
            ? "D3 UKOM ilmugiziku"
            : questionSubType == 2
            ? "D4 UKOM ilmugiziku"
            : questionSubType == 3
            ? "S1 UKOM ilmugiziku"
            : questionSubType == 4
            ? "Pfs UKOM ilmugiziku"
            : ""
          : questionSubType == 1
          ? "D3 SKB Gizi ilmugiziku"
          : "D4 / S1 SKB Gizi ilmugiziku"}
      </p>
      <table className="table table-striped">
        <thead
          style={{
            background: "rgb(84, 194, 183)",
            color: "rgb(255, 255, 255)",
          }}
        >
          <tr>
            <th>No</th>
            <th>Nama Lengkap</th>
            <th>Nilai Try Out</th>
          </tr>
        </thead>
        <tbody>
          {ranking?.map((value, index) => {
            return (
              <tr
                key={index}
                style={{
                  background: index % 2 !== 0 ? "rgb(84, 194, 183)" : "",
                }}
              >
                <td>{index + 1}</td>
                <td>{value.fullName}</td>
                <td>
                  {value.correct} / {value.total}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Layout>
  );
};

export default List;
