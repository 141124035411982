import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  ModalHeader,
  Input,
  Label,
  FormGroup,
} from "reactstrap";

import { getTheory, getInstitute } from "../../../redux/features/customerSlice";
import LayoutAdmin from "../LayoutAdmin";
import {
  InstituteApi,
  DeleteInstituteApi,
  FeatureListAPI,
  FeatureUpdateAPI,
} from "../../../services";
import { storage } from "../../../config/FirebaseConfig";
import DeleteFeatureAPI from "../../../services/adminApi/lesson/deleteFeature";

const { SearchBar } = Search;

const FeatureList = () => {
  const [listFeature, setlistFeature] = useState([]);
  const [feature, setFeature] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const toggleUpdate = () => setModalUpdate(!modalUpdate);
  const [modalDelete, setModalDelete] = useState(false);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const [data, setData] = useState({ desc: "" });
  const [changeData, setChangeData] = useState(false);
  const [errorUpdate, setErrorUpdate] = useState(false);
  const toggle = () => setModal(!modal);
  const { customer } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteInstitute = (data) => {
    setFeature(data);
    setModalDelete(!modalDelete);
  };

  const updateFeature = (data) => {
    setFeature(data);
    setModalUpdate(!modalUpdate);
  };

  useEffect(() => {
    const fetchFeature = async () => {
      setLoading(true);
      const res = await FeatureListAPI(customer.token);
      console.log(res);
      if (res && res.statusCode === 20) {
        setlistFeature(res.result);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    fetchFeature();
  }, [changeData]);

  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "desc",
      text: "Description",
      formatter: (rowContent, row) => {
        return <div>{row.desc}</div>;
      },
      sort: true,
    },
    // {
    //   dataField: "uri",
    //   text: "Uri",
    //   formatter: (rowContent, row) => {
    //     console.log("row");
    //     console.log(row);
    //     return (
    //       <Container fluid>
    //         <a href={row.uri} target="_blank">
    //           {row.uri.substring(0, 100) + "..."}
    //         </a>
    //       </Container>
    //     );
    //   },
    //   sort: true,
    // },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row className="d-inline-flex justify-content-center">
              <Col>
                <Button color="warning" onClick={() => updateFeature(row)}>
                  <AiIcons.AiOutlineEdit />
                </Button>
              </Col>
              <Col className="text-center">
                <Button color="danger" onClick={() => deleteInstitute(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "200px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <h1>Feature List </h1>
      <Modal
        style={{ margin: "auto", height: "90%", width: "90%" }}
        isOpen={modalDelete}
        toggle={toggleDelete}
      >
        <ModalHeader>Delete Feature</ModalHeader>
        <ModalBody>Apakah Anda Yakin ingin menghapus data ini?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              setLoading(true);
              await DeleteFeatureAPI(customer.token, feature.secureId).then(
                (res) => {
                  if (res.statusCode == 20) {
                    setChangeData(!changeData);
                    toggleDelete();
                  }
                }
              );
            }}
          >
            Hapus
          </Button>{" "}
          <Button color="secondary" onClick={toggleDelete}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        style={{ margin: "auto", height: "90%", width: "90%" }}
        isOpen={modalUpdate}
        toggle={toggleUpdate}
      >
        <ModalHeader>Edit Description</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label for="editDesc">Description</Label>
            <Input
              value={data.desc}
              type="text"
              onChange={(e) => setData({ desc: e.target.value })}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              setLoading(true);

              setErrorUpdate(false);
              if (data.desc.length > 0) {
                console.log(data.desc);
                const res = await FeatureUpdateAPI(
                  customer.token,
                  feature.secureId,
                  data
                );
                console.log(res);
                toggleUpdate();
                setChangeData(!changeData);
                setLoading(false);
              } else {
                toggleUpdate();
              }
            }}
          >
            Edit
          </Button>{" "}
          <Button color="secondary" onClick={toggleUpdate}>
            Batal
          </Button>
        </ModalFooter>
      </Modal>
      {isDelete && <Alert color="success">Data berhasil dihapus.</Alert>}
      <ToolkitProvider
        bootstrap4
        defaultSorted={defaultSorted}
        keyField="secureId"
        data={listFeature}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <Link to="/admin/feature-packet/add">
                  <Button color="primary mt-4">
                    <BiIcons.BiBookOpen /> Buat
                  </Button>
                </Link>
              </Col>
              <Col>
                <div className="offset-8 mb-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </Col>
            </Row>

            {loading ? (
              <div className="text-center">
                <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
              </div>
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            )}
            {error && (
              <Alert color="danger">
                Terjadi Kesalahan saat pegambilan Data
              </Alert>
            )}
          </div>
        )}
      </ToolkitProvider>
    </LayoutAdmin>
  );
};
export default FeatureList;
