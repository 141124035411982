import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminRoutes = ({ component: Component, ...rest }) => {
  const { customer } = useSelector((state) => state);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (customer) {
          if (customer.role === 2) {
            return <Component />;
          } else {
            return <Redirect to="/schedule/list" />;
          }
        } else {
          return (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          );
        }
      }}
    />
  );
};

export default AdminRoutes;
