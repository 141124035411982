import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import CreateTheory from "./CreateTheory";
import ValidationTheory from "./ValidationTheory";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";

const Create = () => {
  const { handleChange, handleSubmit, errors, pdfFileError, progress } =
    CreateTheory();

  return (
    <LayoutAdmin>
      <h1>Buat Theory</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="competence" sm={2}>
            Kompetensi :
          </Label>
          <Col>
            <Input
              type="text"
              name="competence"
              id="competence"
              onChange={handleChange}
              required
            />
            {errors.competence && (
              <p className="text-danger small">{errors.competence}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="uri">
            File :
          </Label>
          <Col>
            <Input
              type="file"
              name="uri"
              id="uri"
              onChange={handleChange}
              accept="application/pdf"
              required
            />

            {pdfFileError && (
              <p className="text-danger small">{pdfFileError}</p>
            )}
            {/* {console.log("pdfFileError")}
            {console.log(pdfFileError)} */}
            {/* {errors.uri && <p className="text-danger small">{errors.uri}</p>} */}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="progress">
            {/* Progress : */}
          </Label>
          <Col>
            <progress
              value={progress}
              placeholder={progress + "%"}
              max="100"
              style={{ height: "30px" }}
            />{" "}
            {progress + "%"}
          </Col>
        </FormGroup>

        {progress === 100 && (
          <Alert color="success">Berhasil Membuat Theory.</Alert>
        )}
        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}
        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            Buat
          </button>
          <BackComponent url="/admin/theory" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Create;
