import axios from "axios";

const WebinarDeleteAPI = async (token, secureId) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/admin/webinar/delete?token=${token}&secureId=${secureId}`;
    const url = `${BASED_URL}${params}`;
    const response = await axios.delete(url);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export default WebinarDeleteAPI;
