import axios from "axios";

export const AuthLoginApi = async (data) => {
  try {
    const AUTH_BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/auth/login";
    const endpoint = `${AUTH_BASED_URL}${path}`;

    const response = await axios.post(endpoint, data);
    console.log(response);
    return response.data;
  } catch (error) {
    console.log("ini error auth login");
    console.log(error);
    return error.response;
  }
};

export default AuthLoginApi;
