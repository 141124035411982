import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import validation from "./validation";
import { BackComponent } from "../../../components";
import UpdateQuestion from "./UpdateQuestion";
import { useSelector } from "react-redux";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";

export const Update = () => {
  const { question } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);

  const {
    handleChange,
    handleSubmit,
    errors,
    loading,
    updateQuestion,
    isSubmit,
  } = UpdateQuestion(validation, question, questionSubType, questionType);

  return (
    <LayoutAdmin>
      <h1 className="mx-2">Ubah Question</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="content" sm={2}>
            Pertanyaan :
          </Label>
          <Col>
            <Input
              name="content"
              id="content"
              defaultValue={updateQuestion.content}
              ref={React.createRef()}
              placeholder="Masukan Pertanyaan"
              onChange={handleChange}
            />
            {errors && errors.content && (
              <p className="text-danger small">{errors.content}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="discussion" sm={2}>
            Discussion :
          </Label>
          <Col>
            <Input
              name="discussion"
              type="textarea"
              defaultValue={updateQuestion.discussion}
              ref={React.createRef()}
              id="discussion"
              placeholder="Masukan Discussion"
              onChange={handleChange}
            />
            {errors && errors.discussion && (
              <p className="text-danger small">{errors.discussion}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent0" className="text-primary" sm={2}>
            Jawaban Benar :
          </Label>
          <Col>
            <Input
              className="border-primary"
              name="answerContent0"
              defaultValue={updateQuestion.answers[0].content}
              ref={React.createRef()}
              id="answerContent0"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent0 && (
              <p className="text-danger small">{errors.answerContent0}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent1" className="text-warning" sm={2}>
            Jawaban Salah 1 :
          </Label>
          <Col>
            <Input
              className="form-control border-warning"
              name="answerContent1"
              defaultValue={updateQuestion.answers[1].content}
              ref={React.createRef()}
              id="answerContent1"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent1 && (
              <p className="text-danger small">{errors.answerContent1}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent2" className="text-warning" sm={2}>
            Jawaban Salah 2 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent2"
              defaultValue={updateQuestion.answers[2].content}
              ref={React.createRef()}
              id="answerContent2"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent2 && (
              <p className="text-danger small">{errors.answerContent2}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent3" className="text-warning" sm={2}>
            Jawaban Salah 3 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent3"
              defaultValue={updateQuestion.answers[3].content}
              ref={React.createRef()}
              id="answerContent3"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent3 && (
              <p className="text-danger small">{errors.answerContent3}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent4" className="text-warning" sm={2}>
            Jawaban Salah 4 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent4"
              defaultValue={updateQuestion.answers[4].content}
              ref={React.createRef()}
              id="answerContent4"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent4 && (
              <p className="text-danger small">{errors.answerContent4}</p>
            )}
          </Col>
        </FormGroup>

        {Object.keys(errors).length === 0 && !loading && isSubmit && (
          <Alert color="success">Berhasil Mengubah Question.</Alert>
        )}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Update"}
          </button>

          <BackComponent url="/admin/try-out/ukom/question" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};
