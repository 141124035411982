import axios from "axios";

export const CreatePacketAPI = async (packetData, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/template-to/create?";
    const endpoint = `${BASED_URL}${path}token=${token}`;

    const response = await axios.post(endpoint, packetData);

    return response;
  } catch (error) {
    return error.response;
  }
};

export default CreatePacketAPI;
