import axios from "axios";

export const DeleteInstitute = async (instituteSecureId, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/institute/delete?";
    const endpoint = `${BASED_URL}${path}instituteSecureId=${instituteSecureId}&token=${token}`;
    const response = axios.delete(endpoint);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default DeleteInstitute;
