function validation(values) {
  let errorIni = {};
  if (!values.content) {
    errorIni.content = "Masukkan Pertanyaan terlebih dahulu";
  }
  if (!values.discussion) {
    errorIni.discussion = "Masukkan Discussion terlebih dahulu";
  }
  if (!values.answers[0].content) {
    errorIni.answerContent0 = "Masukkan Jawaban benar terlebih dahulu";
  }
  if (!values.answers[1].content) {
    errorIni.answerContent1 = "Masukkan Salah 1 terlebih dahulu";
  }
  if (!values.answers[2].content) {
    errorIni.answerContent2 = "Masukkan Salah 2 terlebih dahulu";
  }
  if (!values.answers[3].content) {
    errorIni.answerContent3 = "Masukkan Salah 3 terlebih dahulu";
  }
  if (!values.answers[4].content) {
    errorIni.answerContent4 = "Masukkan Salah 4 terlebih dahulu";
  }

  return errorIni;
}

export default validation;
