import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Spinner,
  Col,
  Alert,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import LayoutAdmin from "../LayoutAdmin";
import { GetVideoAPI, QuestionListApi } from "../../../services";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { getQuestion } from "../../../redux/features/customerSlice";
import { DeleteQuestionApi } from "../../../services";
import setActivePacketAPI from "../../../services/adminApi/question/setActivePacket";
import CreateVideo from "./CreateVideo";

const { SearchBar } = Search;

export const QuestionList = () => {
  const [questionList, setQuestionList] = useState([]);
  const [urlVideo, setUrlVideo] = useState("");
  let questions = [];
  const [question, setQuestion] = useState({});
  const [listsError, setListError] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [modal, setModal] = useState(false);
  const [isPopUpActive, setPopUpActive] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const toggle = () => setModal(!modal);
  const toggleVideo = () => setModalVideo(!modalVideo);
  const deleteQuestion = async (question) => {
    setQuestion(question);
    setModal(!modal);
  };

  const { customer } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const { templateSecureId } = useSelector((state) => state);

  const { handleChange, handleSubmit, errors, pdfVideoError, progress } =
    CreateVideo();

  const dispatch = useDispatch();
  const handleActive = async () => {
    setPopUpActive(true);
    const result = await setActivePacketAPI(templateSecureId, customer.token);
    setTimeout(() => {
      setPopUpActive(false);
    }, 2000);
  };

  useEffect(() => {
    const getVideo = async () => {
      const res = await GetVideoAPI(templateSecureId, customer.token);
      if (res.statusCode === 20) {
        if (res?.result?.uri) {
          setUrlVideo(res.result.uri);
        }
      }
    };

    const fetchQuestion = async () => {
      setListLoading(true);
      const res = await QuestionListApi(
        questionSubType,
        questionType,
        customer.token,
        templateSecureId
      );

      if (res && res.statusCode === 20) {
        setQuestionList(res.result);
        setListLoading(false);
      } else {
        setListLoading(false);
        setListError(true);
      }
    };
    fetchQuestion();
    getVideo();
  }, []);

  if (questionList.length !== 0) {
    questionList.exam.map((val, idx) =>
      questions.push({
        secureId: val.secureId,
        content: val.content,
        discussion: val.discussion,
        answerSecureId1: val.answers[0].secureId,
        answerContent1: val.answers[0].content,
        answerSecureId2: val.answers[1].secureId,
        answerContent2: val.answers[1].content,
        answerSecureId3: val.answers[2].secureId,
        answerContent3: val.answers[2].content,
        answerSecureId4: val.answers[3].secureId,
        answerContent4: val.answers[3].content,
        answerSecureId5: val.answers[4].secureId,
        answerContent5: val.answers[4].content,
      })
    );
  }

  const columns = [
    {
      dataField: "content",
      text: "Content",
    },
    {
      dataField: "discussion",
      text: "Discussion",
    },
    {
      dataField: "answerContent1",
      text: "Jawaban Benar",
    },
    {
      dataField: "answerContent2",
      text: "Jawaban Salah",
    },
    {
      dataField: "answerContent3",
      text: "Jawaban Salah",
    },
    {
      dataField: "answerContent4",
      text: "Jawaban Salah",
    },
    {
      dataField: "answerContent5",
      text: "Jawaban Salah",
    },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col>
                <Link to={`/admin/try-out/question/update`}>
                  <Button
                    color="warning"
                    onClick={() => dispatch(getQuestion(row))}
                  >
                    <AiIcons.AiOutlineEdit />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button color="danger" onClick={() => deleteQuestion(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <h3>
        Daftar Pertanyaan{" "}
        {questionType === 1
          ? "Contoh Soal"
          : questionType === 2
          ? "Kuis Mandiri"
          : questionType === 3
          ? "Try Out UKOM"
          : questionType === 4
          ? "Try Out SKB GIZI"
          : ""}{" "}
        {questionSubType === 1
          ? "D3"
          : questionSubType === 2
          ? "D4"
          : questionSubType === 3
          ? "S1"
          : questionSubType === 4
          ? "Pfs"
          : ""}{" "}
        Gizi
      </h3>

      <Modal isOpen={modal} toggle={toggle} style={{marginTop: 300}}>
        <ModalBody>
          <h5 className="text-center">
            Apakah Anda Yakin Akan Menghapus Data?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              toggle();
              await DeleteQuestionApi(question.secureId, customer.token);
              setIsDelete(true);
              window.location.reload();
            }}
          >
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Tidak
          </Button>
        </ModalFooter>
      </Modal>

      {isDelete && <Alert color="success">Data berhasil dihapus.</Alert>}

      {questions.length >= 0 ? (
        <>
          <ToolkitProvider
            bootstrap4
            defaultSorted={defaultSorted}
            keyField="secureId"
            data={questions}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <Row>
                  <Col lg="2" className="mb-2">
                    <Link to="/admin/question/create">
                      <Button color="primary mt-4">Buat Soal</Button>
                    </Link>
                  </Col>
                  <Col lg="2" className="mb-2">
                    <Button onClick={() => handleActive()} color="success mt-4">
                      Set Active Paket
                    </Button>
                  </Col>
                  <Col lg={{ size: "2", offset: "6" }}>
                    <div className="mt-4">
                      <SearchBar {...props.searchProps} />
                    </div>
                  </Col>
                </Row>

                {listLoading ? (
                  <div className="text-center">
                    <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
                  </div>
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory()}
                  />
                )}
              </div>
            )}
          </ToolkitProvider>
        </>
      ) : (
        <Container>
          <Alert color="danger text-center" className="my-5">
            Question Kosong
          </Alert>
        </Container>
      )}
      {listsError && (
        <Alert color="danger" className="my-5">
          Terjadi Kesalahan saat pegambilan Data
        </Alert>
      )}
      {isPopUpActive && (
        <Alert color="success" className="my-5">
          Paket Sudah diset
        </Alert>
      )}
      <br />
      {urlVideo ? null : (
        <Button color="success" onClick={() => setModalVideo(true)}>
          Buat Video Pembahasan
        </Button>
      )}
      {urlVideo ? (
        <div>
          <h1>Berikut Video Pembahasan</h1>
          <video src={urlVideo} controlsList="nodownload" controls>
            <source type="video/mp4" />
          </video>
        </div>
      ) : (
        <h1>Video Belum ada silahkan buat video pembahasan</h1>
      )}

      <Modal
        style={{ position: "relative", margin: "0 auto", top: "25%" }}
        isOpen={modalVideo}
        toggle={toggleVideo}
      >
        <ModalBody>
          <p className="text-center">
            <strong>Buat Video Pembahasan</strong>
          </p>
          <Form onSubmit={handleSubmit}>
            <FormGroup row className="mb-2">
              <Col>
                <Input
                  type="text"
                  name="video"
                  id="video"
                  placeholder="Nama Video"
                  onChange={handleChange}
                  required
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={2} htmlFor="uri">
                File :
              </Label>
              <Col>
                <Input
                  type="file"
                  name="uri"
                  id="uri"
                  onChange={handleChange}
                  required
                />

                {pdfVideoError && (
                  <p className="text-danger small">{pdfVideoError}</p>
                )}
              </Col>
            </FormGroup>

            <FormGroup row className="mb-2">
              <Label sm={2} htmlFor="progress">
              </Label>
              <Col>
                <progress
                  value={progress}
                  placeholder={progress + "%"}
                  max="100"
                  style={{ height: "30px" }}
                />{" "}
                {progress + "%"}
              </Col>
            </FormGroup>

            {progress === 100 && (
              <Alert color="success">Berhasil Membuat Video Pembahasan.</Alert>
            )}
            {errors.invalid && <p className="text-danger">{errors.invalid}</p>}
            <div className="container-fluid d-flex justify-content-end">
              <button type="submit" className="btn btn-primary mx-2">
                Buat
              </button>

              <Button color="secondary" onClick={toggleVideo}>
                Tidak
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </LayoutAdmin>
  );
};
