import axios from "axios";

export const setActivePacketAPI = async (secureId, token) => {
  try {
    const BASED_URL =
      process.env.REACT_APP_BASED_URL + "/admin/template-to/set";
    const params = `?secureId=${secureId}&token=${token}`;
    const url = `${BASED_URL}/${params}`;
    const res = await axios.put(url);
    return res.result;
  } catch (err) {
    return err.response;
  }
};

export default setActivePacketAPI;
