import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import CreateInstitute from "./CreateInstitute";
import ValidationInstitute from "./ValidationInstitute";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";

const Create = () => {
  const { handleChange, handleSubmit, errors, imgFileError, progress } =
    CreateInstitute();

  return (
    <LayoutAdmin>
      <h1>Buat Theory</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="description" sm={2}>
            Description :
          </Label>
          <Col>
            <Input
              type="text"
              name="description"
              id="description"
              onChange={handleChange}
              required
            />
            {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="uri">
            File :
          </Label>
          <Col>
            <Input
              type="file"
              name="uri"
              id="uri"
              onChange={handleChange}
              accept="image/png, image/gif, image/jpeg"
              required
            />

            {imgFileError && (
              <p className="text-danger small">{imgFileError}</p>
            )}
            {/* {console.log("imgFileError")}
            {console.log(imgFileError)} */}
            {/* {errors.uri && <p className="text-danger small">{errors.uri}</p>} */}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="progress">
            {/* Progress : */}
          </Label>
          <Col>
            <progress
              value={progress}
              placeholder={progress + "%"}
              max="100"
              style={{ height: "30px" }}
            />{" "}
            {progress + "%"}
          </Col>
        </FormGroup>

        {progress === 100 && (
          <Alert color="success">Berhasil Membuat Theory.</Alert>
        )}
        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}
        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            Buat
          </button>
          <BackComponent url="/admin/theory" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Create;
