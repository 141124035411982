function validateUpdate(values) {
  let errorIni = {};

  if (!values.desc) {
    errorIni.desc = "Desc is required";
  }

  return errorIni;
}

export default validateUpdate;
