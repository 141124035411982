function validationLabValue(values) {
  let errorIni = {};
  if (!values.measure) {
    errorIni.measure = "Masukkan Ukuran terlebih dahulu";
  }
  if (!values.unit) {
    errorIni.unit = "Masukkan Satuan terlebih dahulu";
  }
  if (!values.referenceValue) {
    errorIni.referenceValue = "Masukkan Nilai Referensi terlebih dahulu";
  }

  return errorIni;
}

export default validationLabValue;
