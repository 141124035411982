import axios from "axios";

export const bimbelList = async (token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const url = `${BASED_URL}/bimbel/package/list?token=${token}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default bimbelList;
