import { useState, useEffect } from "react";
import Layout from "./Layout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import { useDispatch, useSelector } from "react-redux";
import {
  BimbelInformation,
  CekScheduleAPI,
  ScheduleListApi,
} from "../services";
import { Alert } from "reactstrap";
import { disableButton } from "../redux/features/customerSlice";

function Schedule() {
  const [schedules, setSchedules] = useState({});
  const [isLoading, setListLoading] = useState(false);
  const [isError, setListError] = useState(false);
  const dispatch = useDispatch();
  const { customer } = useSelector((state) => state);

  // const agenda = [
  //   {
  //     title: "event 1",
  //     date: "2021-06-30",
  //     backgroundColor: "red",
  //     textColor: "yellow",
  //   },
  //   {
  //     title: "event 2",
  //     start: "2021-06-29T12:00:00",
  //     end: "2021-06-29T15:00:00",
  //     backgroundColor: "blue",
  //   },
  // ];

  useEffect(() => {
    const fetchList = async () => {
      setListLoading(true);
      const res = await ScheduleListApi(customer.token);
      console.log("res");
      console.log(res);

      if (!res) {
        setListError(true);
        // return;
      } else {
        setSchedules(res.result);
        setListLoading(false);
      }
    };
    fetchList();
  }, [customer]);
  useEffect(async () => {
    await CekScheduleAPI(customer.token).then(async (res) => {
      if (res.statusCode == 20) {
        console.log(res);
        dispatch(disableButton(res.result));
        await BimbelInformation(customer.token).then(async (res) => {
          if (res.statusCode == 20) {
            console.log(res);
          }
        });
      }
    });
  }, []);

  let agenda = [];

  for (let i = 0; i < schedules?.length; i++) {
    agenda.push({
      title: schedules[i].desc,
      // start: new Date(
      //   schedules[i].schedule.substring(0, 11) + schedules[i].startTime
      // ),
      // end: new Date(
      //   schedules[i].schedule.substring(0, 11) + schedules[i].endTime
      // ),
      start: schedules[i].startDate,
      end: schedules[i].endDate,
      // backgroundColor: "#FF616D",
      // textColor: "#FFEAC9",
    });
  }

  return (
    <Layout>
      {schedules?.length === 0 && (
        <h4 className="text-danger text-center mt-2">
          Anda belum punya Jadwal
        </h4>
      )}

      {isError && (
        <Alert color="danger mt-5">
          Terjadi Kesalahan saat pegambilan Data
        </Alert>
      )}
      {!isLoading && !isError && (
        <div className="mb-3">
          <h2>Jadwal</h2>
          <FullCalendar
            height={500}
            className="schedule"
            plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
            initialView="dayGridMonth"
            headerToolbar={{
              left: "prev today",
              // left: "prev",
              center: "title",
              // right: "dayGridMonth,dayGridWeek,dayGridDay,listWeek next",
              right: "next",
              // backgroundColor: "#FF616D",
            }}
            // eventClick={function (info) {
            //   info.el.style.background = "#FF616D";
            //   info.el.style.color = "#FFEAC9";
            // }}
            events={agenda}
          />
        </div>
      )}
    </Layout>
  );
}

export default Schedule;
