function validationRegister(values) {
  let errorIni = {};

  if (!values.email) {
    errorIni.email = "Email is required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errorIni.email = "Email address is invalid";
  }

  if (!values.firstName) {
    errorIni.firstName = "First Name is required";
  }
  if (!values.lastName) {
    errorIni.lastName = "Last Name is required";
  }
  if (!values.gender) {
    errorIni.gender = "Gender is required";
  }

  if (!values.password) {
    errorIni.password = "Password is required";
  } else if (values.password.length < 6) {
    errorIni.password = "Password need to be 6 characters or more.";
  }

  if (!values.confPassword) {
    errorIni.confPassword = "Confirmation password is required.";
  } else if (values.confPassword !== values.password) {
    errorIni.confPassword = "Password do not match.";
  }

  return errorIni;
}

export default validationRegister;
