import { useState } from "react";
import "./style.css";
import { NavItem, NavLink, Collapse } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getQuestionType,
  getQuestionSubType,
  getTheoryType,
} from "../../redux/features/customerSlice";

function SubMenu(props) {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const { item } = props;

  const [subnav, setSubnav] = useState(false);
  const [subNavSub, setSubNavSub] = useState(false);
  const showSubnav = () => setSubnav(!subnav);
  const showSubNavSub = () => setSubNavSub(!subNavSub);
  const dispatch = useDispatch();

  // const testA = (v) => {
  //   console.log("v");
  //   console.log(v);
  //   if (v.path === "/try-out/ukom") {
  //     console.log("UKOM");
  //     // dispatch(getQuestionType(3));
  //   } else if (v.path === "/try-out/skb-gizi") {
  //     console.log("SKB GIZI");
  //     // dispatch(getQuestionType(4));
  //   } else {
  //     console.log("elsenya ini");
  //     return v.subNavSub;
  //   }
  // };

  // const goToMenu = (v) => {
  //   testA(v);
  //   return v.subNavSub && showSubNavSub;
  // };

  return (
    <>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink
          className="p-4"
          tag={Link}
          to={item.path}
          onClick={item.subNav && showSubnav}
        >
          {item.icon}
          <span className="text-white mx-1">
            {item.title === "Bimbel" ? <>{item.title}</> : item.title}

            {item.subNav && subnav ? item.iconOpened : item.iconClosed}
          </span>
        </NavLink>
      </NavItem>

      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-0": !collapsed })}
      >
        {subnav &&
          item.subNav.map((v, i) => {
            return (
              <div key={i}>
                <NavItem key={i}>
                  <NavLink
                    className="p-4"
                    tag={Link}
                    to={v.path}
                    onClick={() => {
                      if (v.path === "/try-out/ukom") {
                        console.log("UKOM");
                        dispatch(getQuestionType(3));
                      } else if (v.path === "/try-out/skb-gizi") {
                        console.log("SKB GIZI");
                        dispatch(getQuestionType(4));
                      } else {
                        console.log("elsenya ini");
                      }
                    }}
                  >
                    <span
                      onClick={v.subNavSub && showSubNavSub}
                      className="mx-5 text-white"
                    >
                      {v.title}
                      {v.subNavSub && subNavSub ? v.iconOpened : v.iconClosed}
                    </span>
                  </NavLink>
                </NavItem>
                {subNavSub && v.subNavSub && (
                  <div>
                    {v.subNavSub &&
                      v.subNavSub.map((val, idx) => {
                        return (
                          <div key={idx}>
                            <NavItem key={idx} className="pl-4">
                              <NavLink
                                className="p-4 nav-item-submenu"
                                tag={Link}
                                to={val.path}
                                onClick={() => {
                                  if (val.title === "UKOM") {
                                    dispatch(getTheoryType(1));
                                  } else if (val.title === "SKB GIZI") {
                                    dispatch(getTheoryType(2));
                                  } else {
                                    console.log("ini else dari Question Type");
                                  }
                                }}
                              >
                                <span className="mx-5 px-4 text-white">
                                  {val.title}
                                </span>
                              </NavLink>
                            </NavItem>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            );
          })}
      </Collapse>
    </>
  );
}

export default SubMenu;
