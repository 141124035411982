import { useState, useEffect } from "react";
import { UpdateQuestionApi } from "../../../../services";
import { useSelector } from "react-redux";

const UpdateQuizPractice = (
  validation,
  question,
  questionSubType,
  questionType
) => {
  const [updateQuestion] = useState({
    answers: [
      {
        content: question.answers[0].content,
        secureId: question.answers[0].secureId,
        value: true,
      },
      {
        content: question.answers[1].content,
        secureId: question.answers[1].secureId,
        value: false,
      },
      {
        content: question.answers[2].content,
        secureId: question.answers[2].secureId,
        value: false,
      },
      {
        content: question.answers[3].content,
        secureId: question.answers[3].secureId,
        value: false,
      },
      {
        content: question.answers[4].content,
        secureId: question.answers[4].secureId,
        value: false,
      },
    ],
    content: question.content,
    discussion: question.discussion,
    label: question.label,
    type: question.type,
    questionSubType,
    questionType,
    secureId: question.secureId,
  });

  const { customer } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "label") {
      updateQuestion.label = value;
    }
    if (name === "type") {
      updateQuestion.type = value;
    }
    if (name === "content") {
      updateQuestion.content = value;
    } else if (name === "discussion") {
      updateQuestion.discussion = value;
    } else if (name === "answerContent0") {
      updateQuestion.answers[0].content = value;
    } else if (name === "answerContent1") {
      updateQuestion.answers[1].content = value;
    } else if (name === "answerContent2") {
      updateQuestion.answers[2].content = value;
    } else if (name === "answerContent3") {
      updateQuestion.answers[3].content = value;
    } else if (name === "answerContent4") {
      updateQuestion.answers[4].content = value;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validation(updateQuestion));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const QuizPracticeUpdate = async () => {
        setLoading(true);
        const res = await UpdateQuestionApi(updateQuestion, customer.token);
        console.log("res");
        console.log(res);

        if (res.data.statusCode === 20) {
          setLoading(false);
          //   setUpdateQuestion(res.data.result)
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
        }
      };
      QuizPracticeUpdate();
    }
  }, [errors, customer, updateQuestion, isSubmit]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    updateQuestion,
    isSubmit,
  };
};

export default UpdateQuizPractice;
