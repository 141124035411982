import { useState } from "react";
import "./style.css";
import { NavItem, NavLink, Collapse } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionType,
  getQuestionSubType,
  getTheoryType,
} from "../../../redux/features/customerSlice";

function SubMenu(props) {
  const [collapsed, setCollapsed] = useState(true);
  const { item } = props;
  const [subnav, setSubnav] = useState(false);
  const [subNavSub, setSubNavSub] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => setCollapsed(!collapsed);
  const showSubnav = () => setSubnav(!subnav);
  const showSubNavSub = () => setSubNavSub(!subNavSub);

  return (
    <>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink
          className="p-4"
          tag={Link}
          to={item.path}
          onClick={item.subNav && showSubnav}
        >
          {item.icon}
          <span className="text-white mx-1">
            {item.title}
            {item.subNav && subnav ? item.iconOpened : item.iconClosed}
          </span>
        </NavLink>
      </NavItem>

      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-0": !collapsed })}
      >
        {subnav &&
          item.subNav.map((v, i) => {
            return (
              <div key={i}>
                <NavItem key={i}>
                  <NavLink
                    className="p-4"
                    tag={Link}
                    to={v.path}
                    onClick={() => {
                      if (v.title === "Contoh Soal") {
                        console.log("Contoh SOal");
                        dispatch(getQuestionType(2));
                        dispatch(getQuestionSubType(0));
                      } else if (v.title === "Kuis Mandiri") {
                        console.log("Kuis Mandiri");
                        dispatch(getQuestionType(1));
                        dispatch(getQuestionSubType(0));
                      } else if (v.title === "Try Out UKOM") {
                        console.log("Try Out UKOM");
                        dispatch(getQuestionType(3));
                      } else if (v.title === "Try Out SKB GIZI") {
                        console.log("Try Out SKB GIZI");
                        dispatch(getQuestionType(4));
                      } else if (v.title === "UKOM") {
                        console.log("UKOM");
                        dispatch(getTheoryType(1));
                      } else if (v.title === "SKB GIZI") {
                        console.log("SKB GIZI");
                        dispatch(getTheoryType(2));
                      } else {
                        console.log("ini else dari Question Type");
                      }
                      return v.subNavSub && showSubNavSub;
                    }}
                  >
                    {/* {v.icon} */}
                    <span
                      className="mx-5 text-white"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {v.title}
                      {v.subNavSub && subNavSub ? v.iconOpened : v.iconClosed}
                    </span>
                  </NavLink>
                </NavItem>
                {/* Nested SubMenu */}
                {/* {subNavSub && v.subNavSub && (
                  <div>
                    {v.subNavSub.map((val, idx) => {
                      return (
                        <div key={idx}>
                          <NavItem key={idx} className="pl-4">
                            <NavLink
                              className="p-4 nav-item-submenu"
                              tag={Link}
                              to={val.path}
                            >
                              <span className="mx-5 px-4 text-white">
                                {val.title}
                              </span>
                            </NavLink>
                          </NavItem>
                        </div>
                      );
                    })}
                  </div>
                )} */}
              </div>
            );
          })}
      </Collapse>
    </>
  );
}

export default SubMenu;
