import axios from "axios";

const getTemplateListAPI = async (questionSubType, questionType, token) => {
  try {
    const BASED_URL =
      process.env.REACT_APP_BASED_URL + "/admin/template-to/list";
    const params = `/?questionSubType=${questionSubType}&questionType=${questionType}&token=${token}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export default getTemplateListAPI;
