import { useEffect, useState } from "react";
import Layout from "./Layout";
import { Table } from "reactstrap";
import * as ImIcons from "react-icons/im";
import { TheoryApi } from "../services";
import { useSelector } from "react-redux";

export const Materi = () => {
  const [materi, setMateri] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { theoryType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);

  useEffect(() => {
    const fetchTheory = async () => {
      setIsLoading(true);
      const res = await TheoryApi(theoryType, customer.token);
      if (res.statusCode === 20) {
        setMateri(res.result);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };
    fetchTheory();
  }, [theoryType]);

  return (
    <Layout>
      <p>
        Materi yang keluar Saat{" "}
        <strong>{theoryType === 1 ? "Uji Kompetensi" : "SKB GIZI"}</strong>{" "}
        Terdiri dari {materi ? materi.length : 0} Kompetensi.
      </p>
      <p>Setiap Kompetensi terdapat ringkasan materi.</p>
      <p>
        Untuk mempermudah belajar dan materi yang lebih lengkap silahkan{" "}
        <a
          href="https://ilmugiziku.com/product/buku-bank-gizi/"
          target="_blank"
        >
          Klik disini
        </a>
      </p>
      <Table striped>
        <thead>
          <tr>
            <th>No</th>
            <th>Kompetensi</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {materi &&
            materi.map((val, idx) => {
              return (
                <tr key={idx}>
                  <th scope="row">{idx + 1}</th>
                  <td>{val.competence}</td>
                  <td>
                    <a href={val.uri} target="_blank">
                      <ImIcons.ImDownload3 color="#000" />
                    </a>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Layout>
  );
};
