import { FooterComp } from "./style";

const FooterComponent = () => {
  return (
    <FooterComp>
      <div className="container text-center">
        <span>©Copy Right By ILMUGIZIKU</span>
      </div>
    </FooterComp>
  );
};

export default FooterComponent;
