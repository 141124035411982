import axios from "axios";

export const DeleteQuestionApi = async (questionSecureId, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/question/delete?";
    const endpoint = `${BASED_URL}${path}questionSecureId=${questionSecureId}&token=${token}`;
    const response = axios.delete(endpoint);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default DeleteQuestionApi;
