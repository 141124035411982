import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import CardComponent from "../../components/Card";
import { QuizPracticeApi, ExamSubmitApi } from "../../services";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import {
  setQuizAnswer,
  setQuizQuestion,
} from "../../redux/features/customerSlice";

const Exam = () => {
  const { customer } = useSelector((state) => state);
  //   const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [kuisMandiri, setKuisMandiri] = useState();
  const [quesAnswers, setQuesAnswers] = useState({
    exams: [],
    questionSubType: 0,
    questionType: 1,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  let history = useHistory();

  useEffect(async () => {
    await QuizPracticeApi(1, customer.token)
      .then((data) => setKuisMandiri(data.result))
      .catch((error) => console.log(error));
  }, []);

  const answeredQuestion = kuisMandiri?.filter((val, idx) => {
    return val.selected;
  });

  const selectOption = (indexSelected, indexOptionSelected) => {
    setKuisMandiri(
      kuisMandiri.map((val, idx) =>
        idx === indexSelected
          ? {
              ...val,
              selected: true,
              answers: val.answers.map((item, index) =>
                index === indexOptionSelected
                  ? { ...item, selected: true }
                  : { ...item, selected: false }
              ),
            }
          : val
      )
    );
  };

  const submitQuestion = (e) => {
    e.preventDefault();
    setModal(!modal);

    //Kondisi untuk mensubmit Jawaban
    if (answeredQuestion.length === kuisMandiri.length) {
      console.log("Yeay");
      kuisMandiri.map((value, index) => {
        if (value.selected) {
          value.answers.map((answers, ansIndex) => {
            if (answers.selected) {
              quesAnswers.exams.push({
                questionsSecureId: value.secureId,
                answersSecureId: answers.secureId,
                value: answers.value,
              });
            }
          });
        }
      });
    }
  };

  return (
    <Layout>
      <Modal
        isOpen={modal}
        toggle={toggle}
        style={{ margin: "auto", height: "90%", width: "90%" }}
      >
        {/* <ModalHeader toggle={toggle}>Headernya</ModalHeader> */}
        <ModalBody>
          <h5 className="text-center">
            {answeredQuestion?.length === kuisMandiri?.length
              ? "Apakah Anda Yakin ingin menyelesaikan quiz?"
              : "Pastikan Anda sudah menjawab semua pertanyaan dan tidak di beri flag."}
          </h5>
        </ModalBody>
        <ModalFooter>
          {answeredQuestion?.length === kuisMandiri?.length && (
            <>
              <Button
                color="primary"
                onClick={async () => {
                  console.log(quesAnswers);
                  await ExamSubmitApi(quesAnswers, customer.token)
                    .then((data) => {
                      if (data.statusCode === 20) {
                        dispatch(setQuizAnswer(quesAnswers));
                        dispatch(setQuizQuestion(kuisMandiri));
                        return history.push(`/kuis-jawaban`);
                      }
                      console.log(data);
                    })
                    .catch((error) => console.log(error));
                }}
              >
                Ya
              </Button>
              <Button color="secondary" onClick={toggle}>
                Tidak
              </Button>
            </>
          )}
        </ModalFooter>
      </Modal>

      <div className="shadow m-auto p-4 container">
        {kuisMandiri?.length > 0 ? (
          <ol style={{ cursor: "pointer" }}>
            {kuisMandiri?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <li>{value.content}</li>
                  {value.answers.map((value, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex"
                        onClick={() => selectOption(index, idx)}
                      >
                        <div
                          style={{
                            backgroundColor: value.selected
                              ? "#0072B8"
                              : "rgb(161, 178, 185)",
                            borderRadius: "70%",
                            padding: "7px",
                            height: "10px",
                            width: "10px",
                            margin: "2px",
                            borderStyle: "solid",
                            borderColor: "rgb(227, 231, 234)",
                          }}
                        ></div>
                        <span>{value.content}</span>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ol>
        ) : (
          <Spinner
            className="offset-5"
            style={{ width: "7.5rem", height: "7.5rem" }}
          />
        )}

        <div className="text-center">
          <button
            type="submit"
            className="w-25 mb-2 bg-primary border-0 shadow  btn btn-secondary"
            style={{ background: "rgb(84, 194, 183)" }}
            onClick={submitQuestion}
          >
            Selesai
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Exam;
