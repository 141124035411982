/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import LayoutAdmin from "../LayoutAdmin";
import validation from "./validation";
import { BackComponent } from "../../../components";
import CreateQuestion from "./CreateQuestion";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CreatePacketAPI from "../../../services/adminApi/question/createPacket";

export const CreatePacket = () => {
  const [namePacket, setNamePacket] = useState("");
  const [errors, setErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isSubmit, setSubmit] = useState(false);
  const { questionType, questionSubType, customer } = useSelector(
    (state) => state
  );
  const [packet, setPacket] = useState({
    name: "",
    questionSubType: "",
    questionType: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!namePacket) {
      setErrors(true);
      setLoading(false);
    } else {
      setSubmit(true);

      packet.name = namePacket;
      packet.questionSubType = questionSubType;
      packet.questionType = questionType;
      setErrors(false);
    }
  };

  useEffect(() => {
    console.log("jalan");
    if (isSubmit) {
      const CreatePacket = async () => {
        setLoading(true);
        const res = await CreatePacketAPI(packet, customer.token);
        console.log(res);
        if (res.data.statusCode === 20) {
          setLoading(false);
          setSuccess(true);
        } else {
          console.log(res.data.message);
          setLoading(false);
          setSuccess(false);
          return;
        }
      };
      CreatePacket();
    }
  }, [isSubmit]);

  return (
    <LayoutAdmin>
      <h1>Buat Paket</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="content" sm={2}>
            Nama Paket :
          </Label>
          <Col>
            <Input
              name="content"
              id="content"
              placeholder="Masukan Pertanyaan"
              onChange={(e) => {
                setNamePacket(e.target.value);
              }}
              value={namePacket}
            />

            {errors && (
              <p className="text-danger small">{"Nama Wajib Diisi"}</p>
            )}
          </Col>
        </FormGroup>
        {isSuccess && <Alert color="success">Berhasil Membuat Paket.</Alert>}
        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="info" /> : "Buat"}
          </button>
          {/* <BackComponent url="/admin/try-out/ukom/question" /> */}
          <BackComponent />
        </div>
      </Form>
    </LayoutAdmin>
  );
};
