import React from "react";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as ImIcons from "react-icons/im";
import * as CgIcons from "react-icons/cg";
import * as MdIcons from "react-icons/md";
import * as GiIcons from "react-icons/gi";
import { useSelector } from "react-redux";

const SidebarData = [
  {
    title: "Jadwal",
    path: "/schedule/list",
    icon: <AiIcons.AiFillSchedule />,
  },
  {
    title: "Materi dan Pembahasan",
    path: "#",
    icon: <ImIcons.ImBooks />,
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDropDownFill />,
    subNav: [
      {
        title: "Materi",
        path: "#",
        // icon: <ImIcons.ImBooks />,
        // icon: null,
        iconClosed: <RiIcons.RiArrowRightSFill />,
        iconOpened: <RiIcons.RiArrowDropDownFill />,
        subNavSub: [
          {
            title: "UKOM",
            path: "/materi/ukom",
            // icon: <ImIcons.ImBooks/>,
            icon: null,
          },
          {
            title: "SKB GIZI",
            path: "/materi/skb-gizi",
            // icon: <ImIcons.ImBooks />,
            icon: null,
          },
        ],
      },
      {
        title: "Contoh Soal",
        path: "/contoh-soal",
        // icon: <AiIcons.AiFillSchedule />,
        icon: null,
      },
      {
        title: "Kuis Mandiri",
        path: "/kuis-mandiri",
        // icon: <AiIcons.AiFillSchedule />,
        icon: null,
      },
    ],
  },

  {
    title: "Try Out",
    path: "#",
    icon: <CgIcons.CgNotes />,
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDropDownFill />,
    subNav: [
      {
        title: "UKOM",
        path: "/try-out/ukom",
        // icon: <ImIcons.ImBooks/>,
        icon: null,
      },
      {
        title: "SKB GIZI",
        path: "/try-out/skb-gizi",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
    ],
  },
  {
    title: "Forum Diskusi",
    path: "/forum-diskusi",
    icon: <MdIcons.MdForum />,
  },
  {
    title: "Bimbel",
    path: "/bimbel",
    icon: <GiIcons.GiTeacher />,
    // icon: null,
  },
];

export default SidebarData;
