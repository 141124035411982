import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ExamSubmitApi } from "../../services";

export const CountDown = ({ quesList, quesAnswers, expiryTimestamp }) => {
  const { questionType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  let history = useHistory();
  const time = new Date();
  // time.setSeconds(time.getSeconds() + 600);
  const simpanwaktu = window.localStorage.getItem("waktu")
    ? window.localStorage.getItem("waktu")
    : 60;
  const secon = parseInt(simpanwaktu * 60);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp: time.setSeconds(time.getSeconds() + secon),
    onExpire: () => {
      console.warn("onExpire called");
      quesList &&
        quesList.map((value, index) => {
          if (value.selected) {
            console.log(value);
            value.answers.map((answers, ansIndex) => {
              if (answers.selected) {
                quesAnswers.exams.push({
                  questionsSecureId: value.secureId,
                  answersSecureId: answers.secureId,
                });
              }
            });
          }
        });

      console.log("quesAnswers");
      console.log(quesAnswers);

      const response = ExamSubmitApi(quesAnswers, customer.token);
      console.log(response);

      return history.push(
        `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/finish`
      );
    },
  });

  // useEffect(() => {

  // }, []);

  // 10 minutes timer

  // Restarts to 5 minutes timer

  return (
    <h2>
      <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
    </h2>
  );
};

export default CountDown;
