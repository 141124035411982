import axios from "axios";

const BimbelUpdateAPI = async (token, secureId, data) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/admin/package/update?secureId=${secureId}&token=${token}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.put(url, data);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default BimbelUpdateAPI;
