import styled from "styled-components";
import * as AiIcons from "react-icons/ai";

export const IconShow = styled(AiIcons.AiFillEye)`
  cursor: pointer;
  position: relative;
  fontsize: 1.3rem;
  left: 180px;
  bottom: 30px;
  @media only screen and (max-width: 415px) {
    left: 110px;
  }
`;

export const IconHide = styled(AiIcons.AiFillEyeInvisible)`
  cursor: pointer;
  position: relative;
  fontsize: 1.3rem;
  left: 180px;
  bottom: 30px;
  @media only screen and (max-width: 415px) {
    left: 110px;
  }

`;
