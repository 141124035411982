import React, { useEffect, useState } from "react";
import { CollapseComponent } from "../../components";
import Layout from "../Layout";
import { ExamResultApi } from "../../services";
import { useSelector } from "react-redux";

export const Result = () => {
  const { customer } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const [notAnswerD3, setNotAnswerD3] = useState({});
  const [notAnswerD4, setNotAnswerD4] = useState(false);
  const [notAnswerS1, setNotAnswerS1] = useState(false);
  const [notAnswerPfs, setNotAnswerPfs] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    const fetchExamInfo = async () => {
      const res = await ExamResultApi(questionType, customer.token);
      if (res.statusCode === 20) {
        setResult(res.result);
        result?.map((value, index) => {
          if (value.questionSubType === 1) {
            setNotAnswerD3(value);
          } else if (value.questionSubType === 2) {
            setNotAnswerD4(value);
          } else if (value.questionSubType === 3) {
            setNotAnswerS1(value);
          } else if (value.questionSubType === 4) {
            setNotAnswerPfs(value);
          }
        });
      } else {
        console.log("Exam info Api gagal di ambil");
      }
    };

    fetchExamInfo();
  }, []);


  return (
    <Layout>
      <CollapseComponent
        title={
          questionType == 3
            ? "D3 Try Out Uji Kompetensi"
            : "D3 SKB Gizi Uji Kompetensi"
        }
      >
        {Object.keys(notAnswerD3).length > 0 ? (
          <div>
            <p>
              Hasil Try Out Kamu Pada Tanggal (
              {new Date(notAnswerD3.date).toISOString().substring(0, 10)})
            </p>
            <p>Nilai Kamu adalah : {notAnswerD3.score}%</p>
            <h2>
              {notAnswerD3.correct} / {notAnswerD3.total}
            </h2>
          </div>
        ) : (
          <>
            <p>Hasil Try Out Kamu Pada Tanggal (DD-MM-YYYY)</p>
            <p>Nilai Kamu adalah : -%</p>
            <h2>- / -- Soal</h2>
          </>
        )}
      </CollapseComponent>

      <CollapseComponent
        title={
          questionType == 3
            ? "D4 Try Out Uji Kompetensi"
            : "D4 / S1 SKB Gizi Uji Kompetensi"
        }
      >
        {Object.keys(notAnswerD4).length > 0 ? (
          <div>
            <p>
              Hasil Try Out Kamu Pada Tanggal (
              {new Date(notAnswerD4.date).toISOString().substring(0, 10)})
            </p>
            <p>Nilai Kamu adalah : {notAnswerD4.score}%</p>
            <h2>
              {notAnswerD4.correct} / {notAnswerD4.total}
            </h2>
          </div>
        ) : (
          <>
            <p>Hasil Try Out Kamu Pada Tanggal (DD-MM-YYYY)</p>
            <p>Nilai Kamu adalah : -%</p>
            <h2>- / -- Soal</h2>
          </>
        )}
      </CollapseComponent>
      {questionType == 3 ? (
        <>
          {" "}
          <CollapseComponent title="S1 Try Out Uji Kompetensi">
            {Object.keys(notAnswerS1).length > 0 ? (
              <div>
                <p>
                  Hasil Try Out Kamu Pada Tanggal (
                  {new Date(notAnswerS1.date).toISOString().substring(0, 10)})
                </p>
                <p>Nilai Kamu adalah : {notAnswerS1.score}%</p>
                <h2>
                  {notAnswerS1.correct} / {notAnswerS1.total}
                </h2>
              </div>
            ) : (
              <>
                <p>Hasil Try Out Kamu Pada Tanggal (DD-MM-YYYY)</p>
                <p>Nilai Kamu adalah : -%</p>
                <h2>- / -- Soal</h2>
              </>
            )}
          </CollapseComponent>
          <CollapseComponent title="Pfs Try Out Uji Kompetensi">
            {Object.keys(notAnswerPfs).length > 0 ? (
              <div>
                <p>
                  Hasil Try Out Kamu Pada Tanggal (
                  {new Date(notAnswerPfs.date).toISOString().substring(0, 10)})
                </p>
                <p>Nilai Kamu adalah : {notAnswerPfs.score}%</p>
                <h2>
                  {notAnswerPfs.correct} / {notAnswerPfs.total}
                </h2>
              </div>
            ) : (
              <>
                <p>Hasil Try Out Kamu Pada Tanggal (DD-MM-YYYY)</p>
                <p>Nilai Kamu adalah : -%</p>
                <h2>- / -- Soal</h2>
              </>
            )}
          </CollapseComponent>
        </>
      ) : null}
    </Layout>
  );
};

export default Result;
