import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { setPremium } from "../../redux/features/customerSlice";
import Layout from "../Layout";

const SuccessPayment = () => {
  // initialize timeLeft with the seconds prop
  const [timeLeft, setTimeLeft] = useState(3);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      if (window.localStorage.getItem("status") === "checkout") {
        dispatch(setPremium(true));
      }
      history.replace("/schedule/list");
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks

    return () => {
      clearInterval(intervalId);
    };
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80%",
        }}
      >
        <h2>
          Selamat akun anda sudah premium, Dalam 3 detik anda akan diarahkan ke
          website
        </h2>
        <h2>Redirect {timeLeft}</h2>
      </div>
    </Layout>
  );
};

export default SuccessPayment;
