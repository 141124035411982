import styled from "styled-components";
import * as FaIcons from "react-icons/fa";
import * as CgIcons from "react-icons/cg";
import * as FcIcons from "react-icons/fc";
import LoginBg from "../assets/img/login-bg.jpg";

export const EntryPage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fbfbfb;
  background-image: url(${LoginBg});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const IconGoogle = styled(FcIcons.FcGoogle)`
  position: relative;
  top: 9px;
  font-size: 2rem;
`;
export const IconFacebook = styled(FaIcons.FaFacebook)`
  position: relative;
  top: 9px;
  font-size: 2rem;
  color: #3b5998;
`;
export const IconEmail = styled(CgIcons.CgMail)`
  position: relative;
  top: 9px;
  font-size: 2rem;
  background: #54c2b7;
  color: #fff;
  border-radius: 50%;
`;
