import { useState, useEffect } from "react";
import { CreateScheduleApi } from "../../../services";

const CreateSchedule = (validationCreate, token) => {
  const [schedules, setSchedule] = useState({
    desc: "",
    endDate: "",
    startDate: "",
    timeLimit: 0,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    setSchedule({
      ...schedules,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validationCreate(schedules));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const ScheduleCreate = async () => {
        setLoading(true);
        const res = await CreateScheduleApi(schedules, token);
        if (res.data.statusCode === 20) {
          setSchedule(res.data.result);
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
      };
      ScheduleCreate();
    }
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    schedules,
    isSubmitting,
  };
};

export default CreateSchedule;
