import React, { useState } from "react";
import { MyFooter, NavbarComponent, AdminSideBar } from "../../components";
import { Container } from "reactstrap";
import { IconContext } from "react-icons";
// import classNames from "classnames";

function LayoutAdmin({ children }) {
  const [sidebarIsOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => setSidebarOpen(!sidebarIsOpen);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="App wrapper">
          <AdminSideBar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
          <Container
            fluid
            style={{ padding: 0 }}
            // className={classNames("content", { "is-open": sidebarIsOpen })} //Don't Delete this
          >
            <NavbarComponent toggleSidebar={toggleSidebar} />
            <div className="p-2">{children}</div>
          </Container>
        </div>
        <MyFooter />
      </IconContext.Provider>
    </>
  );
}

export default LayoutAdmin;
