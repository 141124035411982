import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import LayoutAdmin from "../LayoutAdmin";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as BiIcons from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import WebinarListAPI from "../../../services/adminApi/webinar/webinarList";
import WebinarAddAPI from "../../../services/adminApi/webinar/addWebinar";
import WebinarUpdateAPI from "../../../services/adminApi/webinar/updateWebinar";
import WebinarDeleteAPI from "../../../services/adminApi/webinar/deleteWebinar";
import moment from "moment";
const { SearchBar } = Search;

const WebinarList = () => {
  const { bimbelType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [webinar, setWebinar] = useState({
    meetingId: "",
    date: "",
    secureId: "",
    link: "",
    passcode: "",
    title: "",
  });
  const [webinarList, setwebinarList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [changeData, setChangeData] = useState(false);
  const toggle = () => setModalUpdate(!modalUpdate);
  const toggleDelete = () => setModalDelete(!modalDelete);
  const updateWebinar = (data) => {
    // e.preventDefault();
    setModalUpdate(!modalUpdate);
    // console.log(data.date)
    let tanggal = moment(data.date).utc().format("YYYY-MM-DDThh:mm");
    console.log(tanggal);
    setWebinar({
      ...webinar,
      link: data.link,
      meetingId: data.meetingId,
      secureId: data.secureId,
      date: tanggal,
      title: data.title,
      passcode: data.passcode,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "title") {
      setWebinar({ ...webinar, title: value });
    }
    if (name == "passcode") {
      setWebinar({ ...webinar, passcode: value });
    }
    if (name == "link") {
      setWebinar({ ...webinar, link: value });
    }
    if (name == "meetingId") {
      setWebinar({ ...webinar, meetingId: value });
    }
    if (name == "date") {
      setWebinar({ ...webinar, date: value });
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    await WebinarUpdateAPI(customer.token, webinar.secureId, webinar).then(
      (res) => {
        if (res.statusCode == 20) {
          setModalUpdate(!modalUpdate);
          setChangeData(true);
        }
      }
    );
  };
  const handleDeleteData = async () => {
    await WebinarDeleteAPI(customer.token, webinar.secureId).then((res) => {
      if (res.statusCode === 20) {
        setChangeData(true);
        setModalDelete(!modalDelete);
      }
    });
  };
  const handleDelete = (data) => {
    toggleDelete();
    setWebinar(data);
  };
  useEffect(() => {
    const webinarListAPI = async () => {
      const res = await WebinarListAPI(customer.token, bimbelType);
      if (res.statusCode == 20) {
        console.log(res.result);
        setwebinarList(res.result);
        setListLoading(false);
      }
    };
    webinarListAPI();
  }, [changeData]);

  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "title",
      text: "Judul",
      formatter: (rowContent, row) => {
        return <div>{row.title}</div>;
      },
      sort: true,
    },
    {
      dataField: "date",
      text: "Waktu",
      formatter: (rowContent, row) => {
        let date = moment(row.date).utc().format("MMMM Do YYYY, HH:mm:ss a");
        return <div>{date}</div>;
      },
    },
    {
      dataField: "uri",
      text: "Uri",
      formatter: (rowContent, row) => {
        return (
          <Container
            fluid
            style={{
              wordBreak: "break-all",
            }}
          >
            <a href={row.link} target="_blank">
              {row.link.substring(0, 100) + "..."}
            </a>
          </Container>
        );
      },
      sort: true,
    },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row className="d-inline-flex justify-content-center">
              <Col>
                <Button color="warning" onClick={() => updateWebinar(row)}>
                  <AiIcons.AiOutlineEdit />
                </Button>
              </Col>
              <Col className="text-center">
                <Button color="danger" onClick={() => handleDelete(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "200px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <div className="m-4">
        <Link to="/admin/webinar/add">
          <Button color="primary">Buat Webinar</Button>
        </Link>

        <Modal
          style={{ margin: "auto", height: "90%", width: "90%" }}
          isOpen={modalDelete}
          toggle={toggleDelete}
        >
          {/* <ModalHeader toggleDelete={toggleDelete}>{schedule.desc}</ModalHeader> */}
          <ModalBody>
            <h5 className="text-center">
              Apakah Anda Yakin Akan Menghapus Data?
            </h5>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleDeleteData}>
              Ya
            </Button>
            <Button color="secondary" onClick={toggleDelete}>
              Tidak
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={modalUpdate}
          toggle={toggle}
          style={{ margin: "auto", height: "90%", width: "90%" }}
        >
          <ModalHeader>Detail Webinar</ModalHeader>
          <ModalBody>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <div>
                Judul
                <Input
                  type="text"
                  name="title"
                  onChange={handleChange}
                  value={webinar.title}
                />
              </div>
              <div>
                Link
                <Input
                  type="text"
                  name="link"
                  onChange={handleChange}
                  value={webinar.link}
                />
              </div>
              <div>
                Meeting Id
                <Input
                  type="text"
                  name="meetingId"
                  onChange={handleChange}
                  value={webinar.meetingId}
                />
              </div>
              <div>
                Date
                <Input
                  type="datetime-local"
                  name="date"
                  onChange={handleChange}
                  value={webinar.date}
                />
              </div>
              <div>
                Passcode
                <Input
                  type="text"
                  name="passcode"
                  onChange={handleChange}
                  value={webinar.passcode}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={(e) => {
                handleUpdate(e);
              }}
            >
              Update Data
            </Button>
            <Button color="secondary" onClick={toggle}>
              Batal
            </Button>
          </ModalFooter>
        </Modal>
        {webinarList.length > 0 ? (
          <ToolkitProvider
            bootstrap4
            defaultSorted={defaultSorted}
            keyField="secureId"
            data={webinarList}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <Row>
                  <Col className="offset-6">
                    <div className="offset-8 mb-2">
                      <SearchBar {...props.searchProps} />
                    </div>
                  </Col>
                </Row>
                {listLoading ? (
                  <div className="text-center">
                    <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
                  </div>
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory()}
                  />
                )}
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <h2>Data Belum Ada</h2>
        )}
      </div>
    </LayoutAdmin>
  );
};

export default WebinarList;
