import React from "react";
import Layout from "./Layout";
import FDImage from "../assets/img/forum-disukusi-asset.jpeg";
import IGImage from "../assets/img/ig.jpg";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  CardBody,
  CardTitle,
} from "reactstrap";
import * as FaIcons from "react-icons/fa";

const ForumDiskusi = () => {
  return (
    <Layout>
      <Container>
        <Row>
          <Col sm={6} className="d-flex my-5">
            <img
              src={FDImage}
              alt="Forum-diskusi"
              style={{ width: 420, height: 420, margin: "auto" }}
              className="d-flex"
            />
          </Col>
          <Col sm={6} className="m-auto px-5">
            <div className="d-flex">
              <img
                src={IGImage}
                alt="Instagram"
                style={{ width: "100px", height: "100px" }}
              />
              <h6 className="fw-bold p-1 d-flex flex-column justify-content-center align-items-start w-50">
                Follow kami juga di Instagram{" "}
                <a href="https://www.instagram.com/ilmugiziku/" target="_blank">
                  <i>@ilmugiziku</i>
                </a>
              </h6>
            </div>

            <p className="w-75 d-flex flex-column">
              Feed Materi Kuliah Gizi, Kuis Harian di Story, Live Sharing
              Mingguan, Review Buku Event dan Seminar Gizi.
            </p>
            <p className="small w-75">
              Serta sudah Terintegrasi Marketplace Instagram dan E-commerce
              seperti Shopee dan Tokopedia.
            </p>
          </Col>
        </Row>

        <Row className="text-center my-5">
          <Col md={6} className="d-flex justify-content-center">
            <Card className="w-100 mb-4 px-5 border-0">
              <CardBody className="cardShadow">
                <CardTitle tag="h4" className="text-dark">
                  Facebook Page
                </CardTitle>
                <FaIcons.FaFacebook
                  color="#3E5799"
                  style={{ width: "150px", height: "200px" }}
                />
                <hr></hr>
                <a target="_blank" href="https://facebook.com/ilmugiziku">
                  <Button className={"btnPilih btn btn-lg button-big w-75"}>
                    Diskusi
                  </Button>
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} className="d-flex justify-content-center">
            <Card className="w-100 mb-4 px-5 border-0">
              <CardBody className="cardShadow">
                <CardTitle tag="h4" className="text-dark">
                  Telegram 
                </CardTitle>
                <FaIcons.FaTelegramPlane
                  color="#3E5799"
                  style={{ width: "150px", height: "200px" }}
                />
                <hr></hr>
                <a target="_blank" href="https://t.me/joinchat/QzZgLx1UT4-N51k3Iy6uUw">
                  <Button className={"btnPilih btn btn-lg button-big w-75"}>
                    Diskusi
                  </Button>
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default ForumDiskusi;
