import { Row, Col, Button } from "reactstrap";
import * as AiIcons from "react-icons/ai";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

const BackComponent = (props) => {
  let history = useHistory();
  return (
    <Row>
      <Col>
        {/* <Link to={}> */}
        <Button className="btn btn-md" onClick={() => history.goBack()}>
          {props.available == true ? (
            <>
              <AiIcons.AiOutlineRollback /> Kembali{" "}
            </>
          ) : (
            <>Kembali</>
          )}
        </Button>
        {/* </Link> */}
      </Col>
    </Row>
  );
};

export default BackComponent;
