import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import validation from "./validation";
import { BackComponent } from "../../../components";
import CreateQuestion from "./CreateQuestion";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { Option, Select } from "@react-md/form";

export const Create = () => {
  const { questionType } = useSelector((state) => state);
  const { handleChange, handleSubmit, errors, loading, isSubmit } =
    CreateQuestion(validation);

  return (
    <LayoutAdmin>
      <h1>Buat Question</h1>

      <Form onSubmit={handleSubmit}>
        {" "}
        {questionType == 2 ? (
          <>
            <FormGroup row className="mb-2">
              <Label htmlFor="discussion" sm={2}>
                Label
              </Label>
              <Col>
                <Input
                  type="textarea"
                  name="label"
                  id="discussion"
                  placeholder="Masukan Label"
                  onChange={handleChange}
                />
                {errors && errors.label && (
                  <p className="text-danger small">{errors.label}</p>
                )}
              </Col>
            </FormGroup>
            <FormGroup row className="mb-2">
              <Label htmlFor="discussion" sm={2}>
                Tipe Soal
              </Label>
              <Col>
                <Input
                  type="select"
                  onChange={handleChange}
                  name="type"
                  id="exampleSelect"
                >
                  <option value="-1">Silahkan Pilih Tipe</option>
                  <option value="SKB">SKB Gizi</option>
                  <option value="UKOM">UKOM</option>
                </Input>
                {errors && errors.type && (
                  <p className="text-danger small">{errors.type}</p>
                )}
              </Col>
            </FormGroup>
          </>
        ) : null}
        <FormGroup row className="mb-2">
          <Label htmlFor="content" sm={2}>
            Pertanyaan :
          </Label>
          <Col>
            <Input
              name="content"
              id="content"
              placeholder="Masukan Pertanyaan"
              onChange={handleChange}
            />
            {errors && errors.content && (
              <p className="text-danger small">{errors.content}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="discussion" sm={2}>
            Discussion :
          </Label>
          <Col>
            <Input
              type="textarea"
              name="discussion"
              id="discussion"
              placeholder="Masukan Discussion"
              onChange={handleChange}
            />
            {errors && errors.discussion && (
              <p className="text-danger small">{errors.discussion}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent0" className="text-primary" sm={2}>
            Jawaban Benar :
          </Label>
          <Col>
            <Input
              className="border-primary"
              name="answerContent0"
              id="answerContent0"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent0 && (
              <p className="text-danger small">{errors.answerContent0}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent1" sm={2} className="text-warning">
            Jawaban Salah 1 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent1"
              id="answerContent1"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent1 && (
              <p className="text-danger small">{errors.answerContent1}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="answerContent2" sm={2} className="text-warning">
            Jawaban Salah 2 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent2"
              id="answerContent2"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent2 && (
              <p className="text-danger small">{errors.answerContent2}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="answerContent3" className="text-warning">
            Jawaban Salah 3 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent3"
              id="answerContent3"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent3 && (
              <p className="text-danger small">{errors.answerContent3}</p>
            )}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="answerContent4" className="text-warning">
            Jawaban Salah 4 :
          </Label>
          <Col>
            <Input
              className="border-warning"
              name="answerContent4"
              id="answerContent4"
              placeholder="Masukan Jawaban"
              onChange={handleChange}
            />
            {errors && errors.answerContent4 && (
              <p className="text-danger small">{errors.answerContent4}</p>
            )}
          </Col>
        </FormGroup>
        {Object.keys(errors).length === 0 && !loading && isSubmit && (
          <Alert color="success">Berhasil Membuat Question.</Alert>
        )}
        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Buat"}
          </button>
          {/* <BackComponent url="/admin/try-out/ukom/question" /> */}
          <BackComponent />
        </div>
      </Form>
    </LayoutAdmin>
  );
};
