import axios from "axios";

export const DeleteLaboratoryValueApi = async (
  laboratoryValueSecureId,
  token
) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/laboratory-value/delete?";
    const endpoint = `${BASED_URL}${path}laboratoryValueSecureId=${laboratoryValueSecureId}&token=${token}`;
    const response = axios.delete(endpoint);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default DeleteLaboratoryValueApi;
