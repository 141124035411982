import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as FaIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
import LayoutAdmin from "../LayoutAdmin";
import "../../CSS/style_bimbel.css";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import {
  DetailBimbel,
  FeatureListAPI,
  BimbelUpdateAPI,
} from "../../../services";
import { BackComponent } from "../../../components";
const BimbelUpdate = () => {
  const { customer, bimbelSecureId } = useSelector((state) => state);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [bimbel, setBimbel] = useState([]);
  const [error, setError] = useState(false);
  const [feature, setFeature] = useState([]);
  const [counterApi, setCounterAPI] = useState(0);
  const [counterExist, setCounterExist] = useState(0);
  const [existFeature, setExistFeature] = useState([]);
  const [selectedExistFeature, setSelectedExistFeature] = useState([]);
  const [notExistFeature, setNotExistFeature] = useState([]);
  const [data, setData] = useState({
    title: "",
    price: "",
    features: [],
  });

  //   bimbel["features"].map((val) => {
  //     console.log(val);
  //   });
  useEffect(() => {
    const detailAPI = async () => {
      const response = await DetailBimbel(customer.token, bimbelSecureId);

      // setBimbel(response.result);
      if (response.statusCode == 20) {
        let featuress = response.result.features.map((val) => {
          const singleFeature = {
            secureId: val.secureId,
            available: val.available,
          };
          data.features.push(singleFeature);
          var o = Object.assign({}, val);
          o.isSelected = true;
          return o;
        });
        setExistFeature(featuress);
        setData({
          ...data,
          title: response.result.title,
          price: response.result.price,
        });
      }
    };
    detailAPI();
  }, [counterExist]);
  useEffect(() => {
    const getFeature = async () => {
      const response = await FeatureListAPI(customer.token);
      if (response.statusCode === 20) {
        setFeature(response.result);
        let test = feature;
        for (var i = test.length - 1; i >= 0; i--) {
          for (var j = 0; j < existFeature.length; j++) {
            if (test[i] && test[i].secureId === existFeature[j].secureId) {
              test.splice(i, 1);
            }
          }
        }
        setNotExistFeature(test);
        setCounterAPI(counterApi + 1);
      }
    };
    if (counterApi < feature.length + 3) {
      setLoading(true);
      getFeature();
    } else {
      setLoading(false);
    }
  }, [counterApi]);
  const handleCheckExistFeature = (e, extData) => {
    const checkId = e.target.id;
    const selectLabel = document.getElementById("label-" + checkId);
    const selectEl = document.getElementById("select/" + checkId);

    if (extData.isSelected) {
      extData.isSelected = false;
      selectEl.checked = false;
    } else {
      extData.isSelected = true;
      selectEl.checked = true;
    }
    if (selectEl.checked) {
      selectEl.style.display = "";
      const singleFeature = {
        secureId: checkId,
        available: selectEl.value,
      };
      data.features.push(singleFeature);
    } else {
      selectEl.style.display = "none";
      const filterData = data.features.filter(
        (item) => item.secureId != checkId
      );
      data.features = filterData;
    }
  };
  const handleCheckbox = (e) => {
    const checkId = e.target.id;
    const selectLabel = document.getElementById("label-" + checkId);
    const selectEl = document.getElementById("select/" + checkId);
    if (e.target.checked) {
      selectEl.style.display = "";
      const singleFeature = {
        secureId: checkId,
        available: selectEl.value,
      };
      data.features.push(singleFeature);
    } else {
      selectEl.style.display = "none";
      const filterData = data.features.filter(
        (item) => item.secureId != checkId
      );
      data.features = filterData;
    }
  };

  const handleAvailable = (e) => {
    // const element = document.getElementById(e.target.id);
    const arr = e.target.id.toString().split("/");
    const getIndex = data.features.findIndex((item) => item.secureId == arr[1]);
    console.log(data);
    data.features[getIndex].available = e.target.value;
  };
  const handleUpdateForm = (e) => {
    e.preventDefault();
    console.log(data);
    setLoading(true);
    if (data.features.length <= 0) {
      setError(true);
    } else {
      BimbelUpdateAPI(customer.token, bimbelSecureId, data)
        .then((res) => {
          setLoading(false);
          console.log(res);
          setData({
            title: "",
            price: "",
            features: [],
          });
          setCounterExist(counterExist + 1);
          setCounterAPI(0);
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <LayoutAdmin>
      {loading ? (
        <>
          <div id="loadingDiv">
            <div className="spinner-2" style={{ display: "" }}></div>
          </div>
        </>
      ) : (
        <div className="paket-container p-4">
          <h4 className="mb-3">Detail Bimbel </h4>
          <Form onSubmit={handleUpdateForm}>
            <FormGroup className="mb-3">
              <Label for="exampleDetail">Detail bimbel</Label>
              <Input
                type="text"
                name="detail"
                id="detailBimbel"
                value={data.title}
                onChange={(e) => setData({ ...data, title: e.target.value })}
                required
                placeholder="Detail Bimbel"
              />
            </FormGroup>
            <FormGroup className="mb-3">
              <Label for="price">Price</Label>
              <Input
                type="number"
                name="price"
                value={data.price}
                onChange={(e) => setData({ ...data, price: e.target.value })}
                required
                id="priceId"
                placeholder="password placeholder"
              />
            </FormGroup>

            {"Package Feature"}
            <FormGroup>
              {existFeature &&
                existFeature.map((val) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                      key={val.secureId}
                    >
                      <Label for="checkFeature" id={`label-${val.secureId}`}>
                        <Input
                          type="checkbox"
                          id={val.secureId}
                          onChange={(e) => handleCheckExistFeature(e, val)}
                          defaultChecked={val.isSelected}
                        />
                        {val.desc}
                      </Label>

                      <Label check style={{ width: "20%" }}>
                        <Input
                          onChange={handleAvailable}
                          type="select"
                          name="select"
                          defaultValue={val.available}
                          id={"select/" + val.secureId}
                        >
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Input>
                      </Label>
                    </div>
                  );
                })}
              {notExistFeature &&
                notExistFeature.map((val) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                      key={val.secureId}
                    >
                      <Label for="checkFeature" id={`label-${val.secureId}`}>
                        <Input
                          type="checkbox"
                          id={val.secureId}
                          onChange={(e) => handleCheckbox(e)}
                        />{" "}
                        {val.desc}
                      </Label>

                      <Label check style={{ width: "20%" }}>
                        <Input
                          onChange={handleAvailable}
                          type="select"
                          name="select"
                          style={{ display: "none" }}
                          id={"select/" + val.secureId}
                        >
                          <option value="true">true</option>
                          <option value="false">false</option>
                        </Input>
                      </Label>
                    </div>
                  );
                })}
              {error && (
                <p className="text-danger small">
                  Minimal Memilih 1 Package feature
                </p>
              )}
            </FormGroup>
            <div className="d-inline-flex gap-2">
              <Button type="submit">Submit</Button>
              <BackComponent available={false}></BackComponent>
            </div>
          </Form>
        </div>
      )}
    </LayoutAdmin>
  );
};
export default BimbelUpdate;
