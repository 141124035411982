import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { CollapseComponent } from "../../../components";
import { Button } from "reactstrap";
import { ExamRankingApi } from "../../../services";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getQuestionSubType } from "../../../redux/features/customerSlice";

const Ranking = () => {
  const { questionType } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <Layout>
      {questionType == 3 &&
        [...Array(4)].map((value, index) => {
          return (
            <CollapseComponent
              key={index}
              title={`${
                index === 0
                  ? "D3"
                  : index === 1
                  ? "D4"
                  : index === 2
                  ? "S1"
                  : index === 3
                  ? "Pfs"
                  : ""
              } Try Out ${questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"}`}
            >
              <div className="mx-5">
                <p>
                  Lihat Ranking Sekarang untuk{" "}
                  {index === 0
                    ? "D3"
                    : index === 1
                    ? "D4"
                    : index === 2
                    ? "S1"
                    : index === 3
                    ? "Pfs"
                    : ""}{" "}
                  Try Out {questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"}
                </p>
                <Link
                  to={`/try-out/${
                    questionType === 3 ? "ukom" : "skb-gizi"
                  }/ranking/list`}
                >
                  <Button
                    className="btn btnPilih"
                    onClick={() => {
                      dispatch(getQuestionSubType(index + 1));
                    }}
                  >
                    Lihat Ranking
                  </Button>
                </Link>
              </div>
            </CollapseComponent>
          );
        })}
      {questionType != 3 &&
        [...Array(2)].map((value, index) => {
          return (
            <CollapseComponent
              key={index}
              title={`${
                index === 0
                  ? "D3"
                  : index === 1
                  ? "D4 / S1"
                  : index === 2
                  ? "S1"
                  : index === 3
                  ? "Pfs"
                  : ""
              } Try Out ${questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"}`}
            >
              <div className="mx-5">
                <p>
                  Lihat Ranking Sekarang untuk{" "}
                  {index === 0 ? "D3" : index === 1 ? "D4 / S1" : ""} Try Out{" "}
                  {questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"}
                </p>
                <Link
                  to={`/try-out/${
                    questionType === 3 ? "ukom" : "skb-gizi"
                  }/ranking/list`}
                >
                  <Button
                    className="btn btnPilih"
                    onClick={() => {
                      dispatch(getQuestionSubType(index + 1));
                    }}
                  >
                    Lihat Ranking
                  </Button>
                </Link>
              </div>
            </CollapseComponent>
          );
        })}
    </Layout>
  );
};

export default Ranking;
