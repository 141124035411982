import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { BimbelInformation } from "./services";
import { login } from "./redux/features/customerSlice";

const CustomerRoutes = ({ component: Component, ...rest }) => {
  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (customer) {
          if (customer.role === 1) {
            return <Component />;
          } else if (customer.role === 2) {
            return <Redirect to="/admin/schedule/list" />;
          } else {
            return <Redirect to="/auth/login" />;
          }
        } else {
          return (
            <Redirect
              to={{ pathname: "/auth/login", state: { from: props.location } }}
            />
          );
        }
      }}
    />
  );
};

export default CustomerRoutes;
