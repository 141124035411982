import axios from "axios";

export const AuthRegisterApi = async (userRegistration) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = `/auth/register`;
    const endpoint = `${BASED_URL}${path}`;
    const response = await axios.post(endpoint, userRegistration);
    const registerData = response.data;
    return registerData;
  } catch (error) {
    console.log("ini error auth register");
    console.log(error);
    return error.response.data.message;
  }
};

export default AuthRegisterApi;
