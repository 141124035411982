import { Link, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import LayoutAdmin from "../LayoutAdmin";
import { useSelector, useDispatch } from "react-redux";
import { setBimbelType } from "../../../redux/features/customerSlice";
export const Webinar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleChange = (type) => {
    // e.preventDefault();
    dispatch(setBimbelType(type));
    history.push("/admin/webinar/list");
  };
  return (
    <LayoutAdmin>
      <div className="d-flex" style={{ flexDirection: "row" }}>
        <div
          className="card-container"
          style={{
            cursor: "pointer",
          }}
          onClick={(e) => {
            handleChange(1);
          }}
        >
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <h4 style={{ textDecoration: "underline", marginTop: "30px" }}>
              UKOM
            </h4>
          </div>
        </div>
        <div
          className="card-container"
          style={{
            cursor: "pointer",
            height: "100px",
          }}
          onClick={(e) => {
            handleChange(2);
          }}
        >
          <div
            style={{
              alignContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h4 style={{ textDecoration: "underline", marginTop: "30px" }}>
              SKB Gizi
            </h4>
          </div>
        </div>
      </div>
    </LayoutAdmin>
  );
};

export default Webinar;
