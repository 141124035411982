import axios from "axios";

export const ExamSubmit = async (questionSubmit, token) => {
  try {
    const AUTH_BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/exam/submit?";
    const endpoint = `${AUTH_BASED_URL}${path}token=${token}`;

    const response = await axios.post(endpoint, questionSubmit);
    return response.data;
  } catch (error) {
    console.log("ini error auth submitQuestion");
    console.log(error);
    return error.response;
  }
};

export default ExamSubmit;
