import axios from "axios";
const BimbelInformation = async (token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/bimbel/information?token=${token}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default BimbelInformation;
