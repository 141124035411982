import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import * as AiIcons from "react-icons/ai";
import * as ImIcons from "react-icons/im";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { getLabValue } from "../../../redux/features/customerSlice";
import LayoutAdmin from "../LayoutAdmin";
import {
  DeleteLabValueApi,
  LaboratoryValueApi,
} from "../../../services";

const { SearchBar } = Search;

const LaboratoryValue = () => {
  const [ListLabValue, setListLabValue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [labValue, setLabValue] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { customer } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteLabValue = (data) => {
    setLabValue(data);
    setModal(!modal);
  };

  useEffect(() => {
    const fetchLaboratoryValue = async () => {
      setLoading(true);
      const res = await LaboratoryValueApi(customer.token);

      if (res.statusCode === 20) {
        setListLabValue(res.result);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    fetchLaboratoryValue();
  }, [customer]);

  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "measure",
      text: "Ukuran",
      sort: true,
    },
    {
      dataField: "unit",
      text: "Satuan",
      sort: true,
    },
    {
      dataField: "referenceValue",
      text: "Nilai Referensi",
      sort: true,
    },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col>
                <Link to={`/admin/laboratory-value/update`}>
                  <Button
                    color="warning"
                    onClick={() => dispatch(getLabValue(row))}
                  >
                    <AiIcons.AiOutlineEdit />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button color="danger" onClick={() => deleteLabValue(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <h1>Nilai Laboratorium</h1>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <p className="text-center">
            <strong>Detail</strong>
          </p>
          <ul>
            <li>Content : {labValue.content}</li>
            <li>value : {labValue.value}</li>
          </ul>
          <h5 className="text-center">
            Apakah Anda Yakin Akan Menghapus Data?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              toggle();
              await DeleteLabValueApi(labValue.secureId, customer.token);
              setListLabValue(
                ListLabValue.filter((item) => {
                  return item.secureId !== labValue.secureId;
                })
              );
              setIsDelete(true);
            }}
          >
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Tidak
          </Button>
        </ModalFooter>
      </Modal>
      {isDelete && <Alert color="success">Data berhasil dihapus.</Alert>}
      <ToolkitProvider
        bootstrap4
        defaultSorted={defaultSorted}
        keyField="secureId"
        data={ListLabValue}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <Link to="/admin/laboratory-value/create">
                  <Button color="primary mt-4">
                    <ImIcons.ImLab /> Buat
                  </Button>
                </Link>
              </Col>
              <Col>
                <div className="offset-8 mb-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center">
                <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
              </div>
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            )}
            {error && (
              <Alert color="danger">
                Terjadi Kesalahan saat pegambilan Data
              </Alert>
            )}
          </div>
        )}
      </ToolkitProvider>
    </LayoutAdmin>
  );
};

export default LaboratoryValue;
