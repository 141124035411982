import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import UpdateLabValue from "./UpdateLabValue";
import ValidationLabValue from "./ValidationLabValue";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";

const Update = () => {
  const {
    handleChange,
    handleSubmit,
    errors,
    loading,
    laboratoryValue,
    isSubmit,
  } = UpdateLabValue(ValidationLabValue);

  //   console.log(labValue);

  return (
    <LayoutAdmin>
      <h1>Ubah Laboratory Value</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="measure" sm={2}>
            Measure :
          </Label>
          <Col>
            <Input
              type="text"
              name="measure"
              defaultValue={laboratoryValue.measure}
              ref={React.createRef()}
              id="measure"
              onChange={handleChange}
            />
            {errors.measure && (
              <p className="text-danger small">{errors.measure}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="referenceValue" sm={2}>
            Reference Value :
          </Label>
          <Col>
            <Input
              type="text"
              name="referenceValue"
              defaultValue={laboratoryValue.referenceValue}
              ref={React.createRef()}
              id="referenceValue"
              onChange={handleChange}
            />
            {errors.referenceValue && (
              <p className="text-danger small">{errors.referenceValue}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="unit" sm={2}>
            Unit :
          </Label>
          <Col>
            <Input
              type="text"
              name="unit"
              defaultValue={laboratoryValue.unit}
              ref={React.createRef()}
              id="unit"
              onChange={handleChange}
            />
            {errors.unit && <p className="text-danger small">{errors.unit}</p>}
          </Col>
        </FormGroup>

        {Object.keys(errors).length === 0 && !loading && isSubmit && (
          <Alert color="success">Berhasil mengubah Laboratory Value.</Alert>
        )}

        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Ubah"}
          </button>

          <BackComponent url="/admin/laboratory-value" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Update;
