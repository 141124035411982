import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import Layout from "../Layout";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Finish = () => {
  const { quizAnsweredUser } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);

  let history = useHistory();

  useEffect(() => {
    console.log(quizAnsweredUser);
    if (
      quizAnsweredUser[0] === questionType &&
      quizAnsweredUser[1] === questionSubType
    ) {
      history.push(`/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}`);
    }
  }, []);
  return (
    <Layout>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h3>Terima Kasih telah mengikuti TryOut IlmuGiziku</h3>
        <Link to="/try-out/ukom">
          <Button className="btnPilih">Kembali</Button>
        </Link>
      </div>
    </Layout>
  );
};

export default Finish;
