import axios from "axios";

const SetRatingAPI = async (token, lessonId, data) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/bimbel/lesson/rating/set?token=${token}&lessonSecureId=${lessonId}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default SetRatingAPI;
