import styled from "styled-components";

export const StyledButtonSocial = styled.button`
  display: block;
  width: ${(props) => (props.full ? "100%" : null)};
  min-width: 64px;
  border: 0;
  border-radius: 4px;
  padding: 24px 32px;

  background-color: #ffffff;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.5;
  underline: none;
  letter-spacing: 0.02857rem;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: hsl(168, 85%, 78%);
  }
`;

// export const StyledButton = styled.button`
//   display: list-item;
//   width: ${(props) => (props.full ? "100%" : null)};
//   min-width: 64px;
//   border: 0;
//   border-radius: 4px;
//   padding: 24px 32px;
//   outline: none;
//   background-color: #ffffff;
//   color: #000000;
//   font-size: 1.5rem;
//   font-weight: 500;
//   line-height: 2.5;
//   letter-spacing: 0.02857rem;
//   cursor: pointer;
//   transition: all 0.2s;

//   &:hover {
//     background-color: hsl(168, 85%, 78%);
//   }
// `;
