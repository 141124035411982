import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { CollapseComponent } from "../../components";
import { useSelector } from "react-redux";
import { ExamKeyApi } from "../../services";

const Key = () => {
  const { customer } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);

  const [examKey, setExamKey] = useState();
  const [examKey2, setExamKey2] = useState();
  const [examKey3, setExamKey3] = useState();
  const [examKey4, setExamKey4] = useState();

  useEffect(() => {
    const fetchExamApi1 = async () => {
      await ExamKeyApi(1, questionType, customer.token)
        .then((data) => setExamKey(data.result))
        .catch((error) => {
          console.log("error Exam");
          console.log(error);
        });
    };
    fetchExamApi1();

    const fetchExamApi2 = async () => {
      await ExamKeyApi(2, questionType, customer.token)
        .then((data) => setExamKey2(data.result))
        .catch((error) => {
          console.log("error Exam2");
          console.log(error);
        });
    };
    fetchExamApi2();

    const fetchExamApi3 = async () => {
      await ExamKeyApi(3, questionType, customer.token)
        .then((data) => setExamKey3(data.result))
        .catch((error) => {
          console.log("error Exam3");
          console.log(error);
        });
    };
    fetchExamApi3();

    const fetchExamApi4 = async () => {
      await ExamKeyApi(4, questionType, customer.token)
        .then((data) => setExamKey4(data.result))
        .catch((error) => {
          console.log("error Exam4");
          console.log(error);
        });
    };
    fetchExamApi4();
  }, []);

  return (
    <Layout>
      <CollapseComponent
        title={`D3 Try Out ${
          questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
        }`}
      >
        {examKey?.length > 0 ? (
          <ol>
            {examKey?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <li style={{fontWeight: "bold"}}>{value.question}</li>
                  <div className="mx-3">
                    <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                      Pembahasan
                    </p>
                    <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5 }}>
                      {value.discussion}<br/><br></br><span style={{fontWeight: "bold"}}>Jawaban : {value.answer}</span>
                    </p>
                  </div>
                </React.Fragment>
              );
            })}
          </ol>
        ) : (
          <h1>Anda Belum Menjawab Pertanyaan ini</h1>
        )}
      </CollapseComponent>
      <CollapseComponent
        title={`${
          questionType === 3
            ? "D4 Try Out Uji Kompetensi"
            : "D4 / S1 Try Out SKB Gizi"
        }`}
      >
        {examKey2?.length > 0 ? (
          <ol>
            {examKey2?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <li style={{fontWeight: "bold"}}>{value.question}</li>
                  <div className="mx-3">
                    <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                      Pembahasan
                    </p>
                    <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5 }}>
                      {value.discussion}<br/><br></br><span style={{fontWeight: "bold"}}>Jawaban : {value.answer}</span>
                    </p>
                  </div>
                </React.Fragment>
              );
            })}
          </ol>
        ) : (
          <h1>Anda Belum Menjawab Pertanyaan ini</h1>
        )}
      </CollapseComponent>
      {questionType == 3 ? (
        <>
          {" "}
          <CollapseComponent
            title={`S1 Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
            }`}
          >
            {examKey3?.length > 0 ? (
              <ol>
                {examKey3?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li style={{fontWeight: "bold"}}>{value.question}</li>
                      <div className="mx-3">
                        <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                          Pembahasan
                        </p>
                        <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5 }}>
                          {value.discussion}<br/><br></br><span style={{fontWeight: "bold"}}>Jawaban : {value.answer}</span>
                        </p>
                      </div>
                    </React.Fragment>
                  );
                })}
              </ol>
            ) : (
              <h1>Anda Belum Menjawab Pertanyaan ini</h1>
            )}
          </CollapseComponent>
          <CollapseComponent
            title={`Pfs Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
            }`}
          >
            {examKey4 ? (
              <ol>
                {examKey4?.map((value, index) => {
                  return (
                    <React.Fragment key={index}>
                      <li style={{fontWeight: "bold"}}>{value.question}</li>
                      <div className="mx-3">
                        <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                          Pembahasan
                        </p>
                        <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5 }}>
                          {value.discussion}<br/><br></br><span style={{fontWeight: "bold"}}>Jawaban : {value.answer}</span>
                        </p>
                      </div>
                    </React.Fragment>
                  );
                })}
              </ol>
            ) : (
              <h1>Anda Belum Menjawab Pertanyaan ini</h1>
            )}
          </CollapseComponent>
        </>
      ) : null}
    </Layout>
  );
};

export default Key;
