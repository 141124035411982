import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="text-dark">Page Not Found</h1>
          <Link to="/schedule/list">
            <Button>Kembali</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
