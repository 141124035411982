import LayoutAdmin from "../LayoutAdmin";
import { Form, FormGroup, Label, Col, Input, Spinner, Alert } from "reactstrap";
import { BackComponent } from "../../../components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FeatureCreate } from "../../../services";
import WebinarAddAPI from "../../../services/adminApi/webinar/addWebinar";
export const WebinarAdd = () => {
  const { bimbelType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState({
    bimbelType: bimbelType,
    title: "",
    passcode: "",
    link: "",
    meetingId: "",
    date: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "meeting") {
      setData({ ...data, title: value });
    }
    if (name === "date") {
      setData({ ...data, date: value });
    }
    if (name == "link") {
      setData({ ...data, link: value });
    }
    if (name == "meetingId") {
      setData({ ...data, meetingId: value });
    }
    if (name === "passcode") {
      setData({ ...data, passcode: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccess(true);
    await WebinarAddAPI(customer.token, data)
      .then((res) => {
        if (res.statusCode === 20) {
          console.log(res.result);
          setLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <LayoutAdmin>
      <h1>Buat Webinar</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="description" sm={2}>
            Judul Meeting :
          </Label>
          <Col>
            <Input
              type="text"
              name="meeting"
              id="meeting"
              value={data.title}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="date" sm={2}>
            Tanggal :
          </Label>
          <Col>
            <Input
              type="datetime-local"
              name="date"
              id="date"
              value={data.date}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="link" sm={2}>
            Link Meeting :
          </Label>
          <Col>
            <Input
              type="text"
              name="link"
              id="link"
              value={data.link}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="passcode" sm={2}>
            Passcode Meeting :
          </Label>
          <Col>
            <Input
              type="text"
              name="passcode"
              id="passcode"
              value={data.passcode}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>
        <FormGroup row className="mb-2">
          <Label htmlFor="meetingId" sm={2}>
            Meeting Id :
          </Label>
          <Col>
            <Input
              type="text"
              name="meetingId"
              id="meetingId"
              value={data.meetingId}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>

        {/* {console.log("imgFileError")}
      {console.log(imgFileError)} */}
        {/* {errors.uri && <p className="text-danger small">{errors.uri}</p>} */}
        <Alert color="success" isOpen={success}>
          Berhasil Menambahkan Webinar
        </Alert>
        <div className="d-flex justify-content-end">
          {loading ? (
            <button type="submit" className="btn btn-primary mx-2">
              <Spinner />
            </button>
          ) : (
            <button type="submit" className="btn btn-primary mx-2">
              Buat
            </button>
          )}

          <BackComponent url="/admin/feature-packet" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default WebinarAdd;
