import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { CollapseComponent } from "../components";
import { useSelector } from "react-redux";
import { QuizPracticeApi } from "../services";
import { FiPlus, FiMinus } from "react-icons/fi";

export default function ContohSoal() {
  const { customer } = useSelector((state) => state);
  const [contohSoal, setContohSoal] = useState();
  const [clicked, setClicked] = useState(false);
  const [contohSoalUkom, setContohSoalUkom] = useState();
  useEffect(async () => {
    await QuizPracticeApi(2, customer.token)
      .then((data) => {
        const contohSoalUkomArr = [];
        const contohSoalSKBArr = [];
        data.result.map((val) => {
          if (val.type == "UKOM") {
            contohSoalUkomArr.push(val);
          } else {
            contohSoalSKBArr.push(val);
          }
        });
        setContohSoalUkom(contohSoalUkomArr);
        setContohSoal(contohSoalSKBArr);
      })
      .catch((error) => console.log(error));
  }, []);

  const toggleAccordion = (index) => {
    if (index === clicked) {
      return setClicked(null);
    }
    return setClicked(index);
  };

  console.log(contohSoal);
  return (
    <Layout>
      <p>
        Kamu bisa latihan dengan beberapa contoh soal
        <br /> dan langsung liat pembahasannya di bawah ini.
      </p>
      <CollapseComponent title="No. Uji Kompetensi">
        <ol
          style={{
            listStylePosition: "inside",
            padding: 12,
          }}
        >
          {contohSoalUkom?.map((value, index) => {
            return (
              value.type === "UKOM" && (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className="d-flex justify-content-between p-3 my-1  rounded"
                    style={{
                      background: index % 2 !== 0 ? "#A3DAD4" : "#ECECEC",
                      cursor: "pointer"
                    }}
                    onClick={() => toggleAccordion(index)}
                  >
                    <li>{value.label}</li>
                    {clicked === index ? (
                      <FiMinus color="#000" />
                    ) : (
                      <FiPlus color="#000" />
                    )}
                  </div>

                  {clicked === index && (
                    <>
                      <span className="mx-3" style={{fontWeight: "bold"}}>{value.content}</span>
                      <ol type="A">
                        {value.answers.map((answer, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <li>{answer.content}</li>
                            </React.Fragment>
                          );
                        })}
                        <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                          Pembahasan
                        </p>
                        <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5}}>
                          {value.discussion}
                        </p>
                      </ol>
                    </>
                  )}
                </React.Fragment>
              )
            );
          })}
        </ol>
      </CollapseComponent>
      <CollapseComponent title="No. SKB Gizi">
        <ol
          style={{
            listStylePosition: "inside",
            padding: 12,
          }}
        >
          {contohSoal?.map((value, index) => {
            return (
              value.type === "SKB" && (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className="d-flex justify-content-between p-3 my-1 rounded"
                    style={{
                      background: index % 2 !== 0 ? "#A3DAD4" : "#ECECEC",
                      cursor: "pointer"
                    }}
                    onClick={() => toggleAccordion(index)}
                  >
                    <li>{value.label}</li>
                    {clicked === index ? (
                      <FiMinus color="#000" />
                    ) : (
                      <FiPlus color="#000" />
                    )}
                  </div>

                  {clicked === index && (
                    <>
                      <span className="mx-3" style={{fontWeight: "bold"}}>{value.content}</span>
                      <ol type="A">
                        {value.answers.map((answer, idx) => {
                          return <li>{answer.content}</li>;
                        })}
                        <p className="my-2" style={{ color: "#008779", fontWeight: "bold" }}>
                          Pembahasan
                        </p>
                        <p style={{ background: "#54C2B7", marginRight: 20, padding: 8, borderRadius: 5 }}>
                          {value.discussion}
                        </p>
                      </ol>
                    </>
                  )}
                </React.Fragment>
              )
            );
          })}
        </ol>
      </CollapseComponent>
    </Layout>
  );
}
