import axios from "axios";

const DeleteFeatureAPI = async (token, secureId) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/admin/package-feature/delete?token=${token}&secureId=${secureId}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.delete(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default DeleteFeatureAPI;
