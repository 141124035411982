import { useState, useEffect } from "react";
import { UpdateQuestionApi } from "../../../services";
import { useSelector } from "react-redux";

const UpdateQuestion = (
  validation,
  question,
  questionSubType,
  questionType
) => {
  const [updateQuestion] = useState({
    answers: [
      {
        content: question.answerContent1,
        secureId: question.answerSecureId1,
        value: true,
      },
      {
        content: question.answerContent2,
        secureId: question.answerSecureId2,
        value: false,
      },
      {
        content: question.answerContent3,
        secureId: question.answerSecureId3,
        value: false,
      },
      {
        content: question.answerContent4,
        secureId: question.answerSecureId4,
        value: false,
      },
      {
        content: question.answerContent5,
        secureId: question.answerSecureId5,
        value: false,
      },
    ],
    content: question.content,
    discussion: question.discussion,
    questionSubType,
    questionType,
    secureId: question.secureId,
  });

  const { customer } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "content") {
      updateQuestion.content = value;
    } else if (name === "discussion") {
      updateQuestion.discussion = value;
    } else if (name === "answerContent0") {
      updateQuestion.answers[0].content = value;
    } else if (name === "answerContent1") {
      updateQuestion.answers[1].content = value;
    } else if (name === "answerContent2") {
      updateQuestion.answers[2].content = value;
    } else if (name === "answerContent3") {
      updateQuestion.answers[3].content = value;
    } else if (name === "answerContent4") {
      updateQuestion.answers[4].content = value;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validation(updateQuestion));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const QuestionUpdate = async () => {
        setLoading(true);
        const res = await UpdateQuestionApi(updateQuestion, customer.token);

        if (res.data.statusCode === 20) {
          setLoading(false);
          // setUpdateQuestion(res.data.result)
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
      };
      QuestionUpdate();
    }
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    updateQuestion,
    isSubmit,
  };
};

export default UpdateQuestion;
