import axios from "axios";

const DetailBimbel = async (token, secureId) => {
  try {
    var BASED_URL = process.env.REACT_APP_BASED_URL;
    var params = `/bimbel/package?token=${token}&secureId=${secureId}`;
    var url = `${BASED_URL}${params}`;
    var response = await axios.get(url);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export default DetailBimbel;
