import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import UpdateTheory from "./UpdateTheory";
import ValidationTheory from "./ValidationTheory";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";

const Update = () => {
  const {
    handleChange,
    handleSubmit,
    errors,
    loading,
    theoryUpdate,
    isSubmit,
  } = UpdateTheory(ValidationTheory);

  return (
    <LayoutAdmin>
      <h1>Ubah Theory</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="competence" sm={2}>
            Competence :
          </Label>
          <Col>
            <Input
              type="text"
              name="competence"
              defaultValue={theoryUpdate.competence}
              ref={React.createRef()}
              id="competence"
              onChange={handleChange}
            />
            {errors.competence && (
              <p className="text-danger small">{errors.competence}</p>
            )}
          </Col>
        </FormGroup>

        {/* <FormGroup row className="mb-2">
          <Label htmlFor="uri" sm={2}>
            Uri :
          </Label>
          <Col>
            <Input
              type="text"
              name="uri"
              defaultValue={theoryUpdate.uri}
              ref={React.createRef()}
              id="uri"
              onChange={handleChange}
            />
            {errors.value && (
              <p className="text-danger small">{errors.value}</p>
            )}
          </Col>
        </FormGroup> */}

        {Object.keys(errors).length === 0 && !loading && isSubmit && (
          <Alert color="success">Berhasil mengubah Theory.</Alert>
        )}

        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Ubah"}
          </button>

          <BackComponent url="/admin/theory" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Update;
