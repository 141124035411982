import React, { useState } from "react";
import {
  Card,
  Button,
  CardBody,
  CardTitle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import "./style.css";
import { Link } from "react-router-dom";
import LINKEDIN from "../../assets/img/LINKEDIN.png";
import FACEBOOK from "../../assets/img/FACEBOOK.png";
import TWITTER from "../../assets/img/TWITTER.png";
import GMAIL from "../../assets/img/GMAIL 1-2.png";
import WHATSAPP from "../../assets/img/WHATSAPP.png";
import DRIBBBLE from "../../assets/img/DRIBBBLE.png";
import upgrade_premium from "../../assets/img/Upgrade_Premium.JPG";

const CardComponent = (props) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    if (props.setPopUpNotAllow && !props.status) {
      props.setPopUpNotAllow(!props.popUpNotAllow);
    }
    if (props.setPopUp) {
      props.setPopUp(!props.popUp);
    }
    setModal(!modal);
  };

  return (
    <>
      {props.popUpNotAllow && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          style={{ width: "100%", height: "90%" }}
        >
          <ModalHeader>Maaf, Akun anda belum premium</ModalHeader>
          <ModalBody>
            <img
              src={upgrade_premium}
              alt="upgrade-premium"
              border="0"
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
            <Link to="/pilih-packet">
              <Button
                size="sm"
                color="danger"
                style={{
                  position: "absolute",
                  width: "250px",
                  height: "45px",
                  fontSize: "20px",
                  right: "28px",
                  top: "80%",
                }}
              >
                Upgrade Akun Kamu
              </Button>
            </Link>
          </ModalBody>

          <Button
            className="close-modal"
            onClick={toggle}
            style={{
              backgroundColor: "white",
              color: "black",
              borderColor: "white",
            }}
          >
            X
          </Button>
        </Modal>
      )}
      {props.popUp && (
        <Modal isOpen={modal} toggle={toggle} style={{marginTop: 200}}>
          <ModalHeader toggle={toggle}>
            Ajak temanmu untuk ikut Try Out ini!
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-4">
                <a target="_blank" href="http://www.linkedin.com/shareArticle?mini=true&url=ilmugiziku.com&title=">
                  <img src={LINKEDIN} alt="LinkedIn" />
                </a>
              </div>
              <div className="col-md-4">
                <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=ilmugiziku.com">
                  <img src={FACEBOOK} alt="Facebook" />
                </a>
              </div>
              <div className="col-md-4">
                <a target="_blank" href="https://twitter.com/intent/tweet?url=ilmugiziku.com&text=">
                  <img src={TWITTER} alt="Twitter" />
                </a>
              </div>
              <div className="col-md-4">
                <a target="_blank" href="#">
                  <img src={GMAIL} alt="Gmail" />
                </a>
              </div>
              <div className="col-md-4">
                <a target="_blank" href="#">
                  <img src={WHATSAPP} alt="WhatsApp" />
                </a>
              </div>
              <div className="col-md-4">
                <a target="_blank" href="#">
                  <img src={DRIBBBLE} alt="Dribbble" />
                </a>
              </div>
            </div>
          </ModalBody>
        </Modal>
      )}
      <Card className="mb-4 px-5 border-0" style={{width: 720}}>
        <CardBody className="cardShadow">
          <a href={props.url} className="text-decoration-none">
            <CardTitle tag="h4" className="text-dark">
              {props.title}
            </CardTitle>
          </a>
          {props.children}
          <hr></hr>
          <Link to={props.url}>
            <Button
              onClick={toggle}
              className={
                props.status
                  ? "btnPilih btn btn-lg button-big w-75"
                  : "btnDanger btn btn-lg button-big w-75"
              }
              disabled={props.check == 2 ? (props.disable ? "" : "true") : ""}
            >
              {props.buttonTeks}
            </Button>
          </Link>
        </CardBody>
      </Card>
    </>
  );
};

export default CardComponent;
