import axios from "axios";

export const CreateScheduleApi = async (scheduleData, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/schedule/create?";
    const endpoint = `${BASED_URL}${path}token=${token}`;

    const response = await axios.post(endpoint, scheduleData);

    return response;
  } catch (error) {
    return error.response;
  }
};

export default CreateScheduleApi;
