import { useState, useEffect } from "react";
import { UpdateScheduleApi, UpdateTheoryApi } from "../../../services";
import { useSelector } from "react-redux";

const UpdateTheory = (ValidationTheory) => {
  const { theory } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [theoryUpdate, setTheoryUpdate] = useState({
    competence: theory.competence,
    secureId: theory.secureId,
    theoryType: theory.theoryType,
    uri: theory.uri,
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setTheoryUpdate({
      ...theoryUpdate,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(ValidationTheory(theoryUpdate));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const FetchTheoryUpdate = async () => {
        setLoading(true);
        const res = await UpdateTheoryApi(theoryUpdate, customer.token);

        if (res.data.statusCode === 20) {
          //   console.log("Success");
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
      };
      FetchTheoryUpdate();
    }
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    theoryUpdate,
    isSubmit,
  };
};

export default UpdateTheory;
