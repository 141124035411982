import LayoutAdmin from "../LayoutAdmin";
import { Form, FormGroup, Label, Col, Input } from "reactstrap";
import { BackComponent } from "../../../components";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FeatureCreate } from "../../../services";
export const FeatureAdd = () => {
  const [data, setData] = useState({
    desc: "",
  });
  const { customer } = useSelector((state) => state);
  const history = useHistory();
  const handleChange = (e) => {
    setData({
      ...data,
      desc: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await FeatureCreate(customer.token, data);
    if (res.statusCode == 20) {
      history.goBack();
    }
  };

  return (
    <LayoutAdmin>
      <h1>Buat Feature</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="description" sm={2}>
            Description :
          </Label>
          <Col>
            <Input
              type="text"
              name="description"
              id="description"
              value={data.desc}
              onChange={handleChange}
              required
            />
            {/* {errors.description && (
              <p className="text-danger small">{errors.description}</p>
            )} */}
          </Col>
        </FormGroup>
        {/* {console.log("imgFileError")}
      {console.log(imgFileError)} */}
        {/* {errors.uri && <p className="text-danger small">{errors.uri}</p>} */}

        <div className="d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            Buat
          </button>
          <BackComponent url="/admin/feature-packet" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default FeatureAdd;
