import { useState } from "react";
import { Link } from "react-router-dom";
import { EntryPage, IconGoogle } from "../style";
import {
  ButtonLogin,
  ButtonMedia,
  TogglePassword,
  EntryCard,
  InputGroup,
  Input,
} from "../../components";
import Logo from "../../assets/img/logo-ilmugiziku.png";
import useLogin from "./useLogin";
import validateLogin from "./validateLogin";

const Login = () => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const { handleChange, values, handleSubmit, errors, loading } =
    useLogin(validateLogin);

  return (
    <EntryPage>
      <EntryCard>
        <Link to="/">
          <img src={Logo} width="100" alt="login" />
        </Link>
        <h1>ILMUGIZIKU</h1>
        <p>Dashboard Platform Belajar Gizi</p>
        <form onSubmit={handleSubmit}>
          <InputGroup>
            <label htmlFor="login-email">Email Address</label>
            <Input
              autoComplete="off"
              type="text"
              name="email"
              value={values.email}
              placeholder="name@email.com"
              id="login-email"
              onChange={handleChange}
            />
            {errors.email && (
              <p className="text-danger small">{errors.email}</p>
            )}
          </InputGroup>
          <InputGroup>
            <label htmlFor="login-password">Password</label>
            <Input
              autoComplete="off"
              id="login-password"
              placeholder="Password"
              name="password"
              type={isRevealPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange}
            />
            <TogglePassword
              isRevealPassword={isRevealPassword}
              setIsRevealPassword={setIsRevealPassword}
              onClick={() => setIsRevealPassword((prevState) => !prevState)}
            />
            {errors.password && (
              <p className="text-danger small">{errors.password}</p>
            )}
          </InputGroup>

          {errors.invalid && (
            <p className="text-danger small">{errors.invalid}</p>
          )}

          <ButtonLogin color="primary" type="submit" full>
            {loading ? "Loading..." : "Log in"}
          </ButtonLogin>
        </form>

        <h5 className="pt-5">Or Login With</h5>
        <Link to="#" className="text-decoration-none">
          <ButtonMedia full>
            <IconGoogle className="mb-3" /> Login / Daftar
          </ButtonMedia>
        </Link>

        <span>
          <Link to="#" className="to-Login">
            Forgot Password?
          </Link>
        </span>
        <span>
          Belum Punya Akun?
          <Link to="/auth/register" className="to-Login">
            Daftar
          </Link>
        </span>
      </EntryCard>
    </EntryPage>
  );
};

export default Login;
