import axios from "axios";

const getVideoAPI = async (templateSecureId, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `?templateSecureId=${templateSecureId}&token=${token}`;
    const url = `${BASED_URL}/admin/discussion-video${params}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default getVideoAPI;
