import React from "react";

const FlagComponent = ({ quesList, activeQuestion, setQuesList }) => {
  const flagQuestion = (questionIndex) => {
    setQuesList(
      quesList &&
        quesList.map((value, index) =>
          index === questionIndex && value.flag
            ? { ...value, flag: false }
            : index === questionIndex
            ? {
                ...value,
                //   selected: true,
                flag: true,
              }
            : value
        )
    );
  };

  return (
    <span className="d-flex justify-content-end">
      <div
        type="checkbox"
        style={{
          fontSize: 18,
          cursor: "pointer",
          padding: 10,
          margin: 5,
          width: "25px",
          height: "25px",
          borderRadius: 3,
          //   marginRight: 5,
          backgroundColor:
            quesList && quesList[activeQuestion].flag ? "#FBAD44" : "#fff",
        }}
        value={activeQuestion}
        onClick={() => flagQuestion(activeQuestion)}
      />
    </span>
  );
};

export default FlagComponent;
