function validateLogin(values) {
  let errorIni = {};

  if (!values.email) {
    errorIni.email = "Email is required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errorIni.email = "Email address is invalid";
  }

  if (!values.password) {
    errorIni.password = "Password is required";
  } else if (values.password.length < 6) {
    errorIni.password = "Password need to be 6 characters or more.";
  }

  return errorIni;
}

export default validateLogin;
