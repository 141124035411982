import axios from "axios";

export const LaboratoryValueApi = async (token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/laboratory-value/list?";

    const endpoint = `${BASED_URL}${path}token=${token}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export default LaboratoryValueApi;
