import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { AuthLoginApi } from "../../services";
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/customerSlice";

const useLogin = (validateLogin) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    type: 1,
  });
  const dispatch = useDispatch();

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validateLogin(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const fetchAuthLogin = async () => {
        setLoading(true);
        const res = await AuthLoginApi(values);
        if (res.statusCode === 20 && res.result.role === 2) {
          dispatch(login({ ...res.result }));
          return <Redirect to="/admin/schedule/list" />;
        } else if (res.statusCode === 20 && res.result.role === 1) {
          dispatch(login({ ...res.result }));
          return <Redirect to="/schedule/list" />;
        } else {
          errors.invalid = res.message;
          setLoading(false);
        }
      };
      fetchAuthLogin();
    }
  }, [errors, dispatch, isSubmitting, values]); //Disapear Warning

  return { handleChange, values, handleSubmit, errors, loading };
};

export default useLogin;
