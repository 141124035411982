import React from "react";
import { Button } from "reactstrap";

const ResumeSoalComponent = ({ quesList, setActiveQuestion }) => {
  const MoveQuestion = (index) => {
    setActiveQuestion(index);
  };
  return (
    <>
      {quesList &&
        quesList.map((val, i) => {
          return (
            <Button
              key={i}
              className={`d-flex justify-content-center align-items-center border-0`}
              style={{
                backgroundColor: val.flag
                  ? "#FBAD44" // flag
                  : val?.selected
                  ? "#0171BE" //selected
                  : "#BD2931", // empty
                width: 29,
                height: 29,
                margin: "2px",
              }}
              onClick={() => MoveQuestion(i)}
            >
              <span className="text-white small">{i + 1}</span>
            </Button>
          );
        })}
    </>
  );
};

export default ResumeSoalComponent;
