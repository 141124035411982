import { Link } from "react-router-dom";
import { EntryPage, IconGoogle, IconEmail } from "./style";
import { ButtonMedia, EntryCard, ButtonLogin } from "../components";
import Logo from "../assets/img/logo-ilmugiziku.png";

export default function PreLogin() {
  return (
    <EntryPage>
      <EntryCard>
        <img src={Logo} width="100" alt="Login" />
        <h1>ILMUGIZIKU</h1>
        <p>Dashboard Platform Belajar Gizi</p>

        <ButtonMedia className="text-link" full>
          <IconGoogle className="mb-4" /> Login / Daftar
        </ButtonMedia>

        <Link to="/auth/register" className="text-decoration-none">
          <ButtonLogin full>
            <IconEmail className="mb-4" /> Daftar Email
          </ButtonLogin>
        </Link>
        <hr></hr>
        <span>
          Sudah Punya Akun?
          <Link className="to-Login" to="/auth/login">
            Masuk
          </Link>
        </span>
        <span>
          Dengan daftar kalian setuju terhadap
          <Link className="to-Role" to="/syarat-ketentuan">
            Syarat dan Ketentuan
          </Link>
        </span>
      </EntryCard>
    </EntryPage>
  );
}
