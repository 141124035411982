import axios from "axios";

export const UpdateScheduleApi = async (
  scheduleData,
  scheduleSecureId,
  token
) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/schedule/update?";
    const endpoint = `${BASED_URL}${path}scheduleSecureId=${scheduleSecureId}&token=${token}`;
    const response = await axios.put(endpoint, scheduleData);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default UpdateScheduleApi;
