import axios from "axios";

const ReminderWebinerAPI = async (token, webinarSecureId) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/bimbel/webinar/reminder?token=${token}&webinarSecureId=${webinarSecureId}`;
    const url = `${BASED_URL}${params}`;
    const response = await axios.post(url);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export default ReminderWebinerAPI;
