import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Row,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import ListLessonAPI from "../../../services/adminApi/lesson/listLesson";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import LayoutAdmin from "../LayoutAdmin";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as BiIcons from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import { LessonListAPI } from "../../../services";
import { setLesson } from "../../../redux/features/customerSlice";
import DeleteLessonAPI from "../../../services/adminApi/lesson/deleteLesson";
import { storage } from "../../../config/FirebaseConfig";
const { SearchBar } = Search;

const LessonList = () => {
  const { bimbelType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [lesson, setLessons] = useState({});
  const [modalDelete, setModalDelete] = useState(false);
  const toggle = () => setModalDelete(!modalDelete);
  const [lessonList, setLessonList] = useState([]);
  const [listLoading, setListLoading] = useState(true);
  useEffect(() => {
    const LessonListAPI = async () => {
      const res = await ListLessonAPI(customer.token, bimbelType);
      if (res.statusCode == 20) {
        console.log(res.result);
        setLessonList(res.result);
        setListLoading(false);
      }
    };
    LessonListAPI();
  }, []);

  const deleteLesson = (data) => {
    setLessons(data);
    toggle();
  };
  const editLesson = (data) => {
    dispatch(setLesson(data));
    history.push("/admin/lesson/detail");
  };
  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "title",
      text: "Judul",
      formatter: (rowContent, row) => {
        return <div>{row.title}</div>;
      },
      sort: true,
    },
    {
      dataField: "theory",
      text: "Teori",
      formatter: (rowContent, row) => {
        return <div>{row.theory}</div>;
      },
      sort: true,
    },

    // {
    //   dataField: "uri",
    //   text: "Uri",
    //   formatter: (rowContent, row) => {
    //     console.log("row");
    //     console.log(row);
    //     return (
    //       <Container fluid>
    //         <a href={row.uri} target="_blank">
    //           {row.uri.substring(0, 100) + "..."}
    //         </a>
    //       </Container>
    //     );
    //   },
    //   sort: true,
    // },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col>
                <Button color="warning" onClick={() => editLesson(row)}>
                  <AiIcons.AiOutlineEdit />
                </Button>
              </Col>
              <Col className="text-center">
                <Button color="danger" onClick={() => deleteLesson(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "200px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <div className="m-4">
        <Modal
          style={{ margin: "auto", height: "90%", width: "90%" }}
          isOpen={modalDelete}
          toggle={toggle}
        >
          <ModalHeader>Delete Lesson</ModalHeader>
          <ModalBody>Apakah Anda Yakin ingin menghapus data ini?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={async () => {
                toggle();
                await DeleteLessonAPI(customer.token, lesson.secureId);
                let fileRef = storage.refFromURL(lesson.thumbnailUri);
                let fileRefVideo = storage.refFromURL(lesson.videoUri);
                // Delete the file

                fileRef
                  .delete()
                  .then(function () {
                    // setIsDelete(true);
                    console.log("delete file Image berhasil.");
                    fileRefVideo
                      .delete()
                      .then(function () {
                        // setIsDelete(true);
                        console.log("delete file berhasil.");
                        // File deleted successfully
                        window.location.reload();
                      })
                      .catch(function (error) {
                        console.log("delete file gagal.");
                        // Uh-oh, an error occurred!
                      });
                  })
                  .catch(function (error) {
                    console.log("delete file Image gagal.");
                    // Uh-oh, an error occurred!
                  });
              }}
            >
              Hapus
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              Batal
            </Button>
          </ModalFooter>
        </Modal>
        <Link to="/admin/lesson/add">
          <Button color="primary">Buat Lesson</Button>
        </Link>
        {lessonList.length > 0 ? (
          <ToolkitProvider
            bootstrap4
            defaultSorted={defaultSorted}
            keyField="secureId"
            data={lessonList}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <Row>
                  <Col className="offset-6">
                    <div className="offset-8 mb-2">
                      <SearchBar {...props.searchProps} />
                    </div>
                  </Col>
                </Row>
                {listLoading ? (
                  <div className="text-center">
                    <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
                  </div>
                ) : (
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory()}
                  />
                )}
              </div>
            )}
          </ToolkitProvider>
        ) : (
          <h2>Data Belum Ada</h2>
        )}
      </div>
    </LayoutAdmin>
  );
};

export default LessonList;
