import React, { useState, useEffect } from "react";
import LayoutAdmin from "../LayoutAdmin";
import { CollapseComponent } from "../../../components";
import { Button, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionSubType,
  getQuestionType,
} from "../../../redux/features/customerSlice";
import getTemplateListAPI from "../../../services/adminApi/template";
import Template from "../../../components/admin/Template";

export const Question = () => {
  const dispatch = useDispatch();
  const { questionType, customer, questionSubType } = useSelector(
    (state) => state
  );
  const [template, setListTemplate] = useState([]);

  return (
    <LayoutAdmin>
      <h1 className="mb-4">Buat Paket</h1>

      {template}
      <CollapseComponent
        title={`D3 Try Out ${
          questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"
        }`}
      >
        <h4>
          Soal Try Out {questionType === 3 ? "UKOM" : "SKB GIZI"} D3 Ilmugizi
        </h4>
        <div style={{ marginTop: "7px", display: "inline-flex", gap: "12px" }}>
          <Template questionSubType={1} />
          <Link
            to={`/admin/try-out/d3/createPacket`}
            onClick={() => {
              dispatch(getQuestionSubType(1));
            }}
          >
            <Button className="btn btnPilih">New Paket</Button>
          </Link>
        </div>
      </CollapseComponent>
      <CollapseComponent
        title={`${
          questionType === 3
            ? "D4 Try Out Uji Kompetensi"
            : "D4 / S1 Try Out SKB GIZI"
        }`}
      >
        <h4>
          Soal Try Out{" "}
          {questionType === 3
            ? "D4 Ilmugizi UKOM"
            : "D4 / S1 Ilmugizi SKB GIZI"}
        </h4>
        <div style={{ marginTop: "7px", display: "inline-flex", gap: "12px" }}>
          <Template questionSubType={2} />

          <Link
            to={`/admin/try-out/d3/createPacket`}
            onClick={() => {
              dispatch(getQuestionSubType(2));
            }}
          >
            <Button className="btn btnPilih">New Paket</Button>
          </Link>
        </div>
      </CollapseComponent>
      {questionType == 3 ? (
        <>
          <CollapseComponent
            title={`S1 Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"
            }`}
          >
            <h4>
              Soal Try Out {questionType === 3 ? "UKOM" : "SKB GIZI"} S1
              Ilmugizi
            </h4>

            <div
              style={{ marginTop: "7px", display: "inline-flex", gap: "12px" }}
            >
              <Template questionSubType={3} />

              <Link
                to={`/admin/try-out/d3/createPacket`}
                onClick={() => {
                  dispatch(getQuestionSubType(3));
                }}
              >
                <Button className="btn btnPilih">New Paket</Button>
              </Link>
            </div>
          </CollapseComponent>
          <CollapseComponent
            title={`Pfs Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB GIZI"
            }`}
          >
            <h4>
              Soal Try Out {questionType === 3 ? "UKOM" : "SKB GIZI"} Pfs
              Ilmugizi
            </h4>

            <div
              style={{ marginTop: "7px", display: "inline-flex", gap: "12px" }}
            >
              <Template questionSubType={4} />

              <Link
                to={`/admin/try-out/d3/createPacket`}
                onClick={() => {
                  dispatch(getQuestionSubType(4));
                }}
              >
                <Button className="btn btnPilih">New Paket</Button>
              </Link>
            </div>
          </CollapseComponent>
        </>
      ) : null}
    </LayoutAdmin>
  );
};
