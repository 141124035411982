import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as FaIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import "./CSS/style_bimbel.css";
import bimbelList from "../services/bimbel/bimbelList";
import { setBimbelType } from "../redux/features/customerSlice";

export default function Bimbel() {
  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [listBimbel, setListBimbel] = useState([]);
  const history = useHistory();
  let currency = Intl.NumberFormat("en-US");

  const handleCheckout = (val) => {
    dispatch(setBimbelType(val.packageType));
    history.push("/fill-info");
  };
  useEffect(() => {
    const BimbelListAPI = async () => {
      const res = await bimbelList(customer.token);

      if (res.statusCode == 20) {
        setListBimbel(res.result);
      }
    };
    
    BimbelListAPI();
  }, []);

  return (
    <Layout>
      <div>
        <h4 style={{ marginTop: "1%" }}>Pilih Paket Akun Premium Kamu</h4>
      </div>

      <div className="paket-container m-5">
        <Row>
          {listBimbel.map((val) => (
            <Col lg="6">
              <div className="card-container">
                <div>
                  <h4
                    style={{ textDecoration: "underline", textAlign: "center" }}
                  >
                    {val.title}
                  </h4>
                  <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
                    Rp {currency.format(val.price)} / Bulan
                  </h4>
                  <br></br>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    {val.features &&
                      val.features.map((feature) => (
                        <div key={feature.secureId} style={{ display: "flex" }}>
                          <span>
                            {feature.available ? (
                              <FaIcons.BsCheckAll
                                color="#79e09f"
                                style={{ width: "30px", height: "30px" }}
                              />
                            ) : (
                              <MdIcons.MdClose
                                color="#c33237"
                                style={{ width: "30px", height: "25px" }}
                              />
                            )}
                          </span>
                          <td>
                            <h5 style={{ paddingLeft: "10px" }}>
                              {feature.desc}
                            </h5>
                          </td>
                        </div>
                      ))}
                  </div>
                  <Button
                    className="mt-5"
                    onClick={() => {
                      handleCheckout(val);
                    }}
                    color="danger"
                    style={{
                      width: "150px",
                      height: "60px",
                      fontSize: "30px",
                      left: "40%",
                      bottom: "10px",
                      position: "relative",
                      cursor: val.open ? "pointer" : "not-allowed",
                    }}
                    disabled={val.open ? "" : "true"}
                  >
                    Pilih
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </Layout>
  );
}
