import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyAsKDEMCa1IwSX9kL_8nnA6ZK2d3X_1Z4Q",
  authDomain: "ilmugiziku-e24eb.firebaseapp.com",
  projectId: "ilmugiziku-e24eb",
  storageBucket: "ilmugiziku-e24eb.appspot.com",
  messagingSenderId: "503194484384",
  appId: "1:503194484384:web:41ba0f9a033f58bce913c5",
  measurementId: "G-LRH5NH1NTV",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
const storage = firebase.storage();

export { storage, firebase as default };
