import React, { useState } from "react";
import Layout from "../Layout";
import { CollapseComponent } from "../../components";
import { useSelector } from "react-redux";
import GetVideoPembahasan from "../../services/questionApi/getVideoPembahasan";

const VideoPembahasan = () => {
  const { questionType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [videoD3, setVideoD3] = useState({});
  const [videoD4, setVideoD4] = useState({});
  const [videoS1, setVideoS1] = useState({});
  const [videoPsf, setVideoPsf] = useState({});
  useState(async () => {
    await GetVideoPembahasan(customer.token, questionType).then((res) => {
      console.log(res);
      if (res.statusCode == 20) {
        res.result.map((val) => {
          if (val.questionSubType == 1) {
            setVideoD3(val);
          }
          if (val.questionSubType == 2) {
            setVideoD4(val);
          }
          if (val.questionSubType == 3) {
            setVideoS1(val);
          }
          if (val.questionSubType == 4) {
            setVideoPsf(val);
          }
        });
      }
    });
  }, []);
  console.log(videoD3);
  return (
    <Layout>
      <CollapseComponent
        title={`D3 Pembahasan Try Out ${
          questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
        } `}
      >
        <div className="text-center mt-3">
          {Object.keys(videoD3).length > 0 ? (
            <>
              <video
                src={videoD3.uri}
                width={420}
                height={600}
                controls
                controlsList="nodownloads"
              >
                <source type="video/mp4" />
              </video>
            </>
          ) : (
            <h1>Anda belum menjawab pertanyaan ini</h1>
          )}
        </div>
      </CollapseComponent>
      <CollapseComponent
        title={` ${
          questionType === 3
            ? "D4 Pembahasan Try Out Uji Kompetensi"
            : "D4 / S1 Pembahasan Try Out SKB Gizi"
        } `}
      >
        <div className="text-center mt-3 mb-3">
          {Object.keys(videoD4).length > 0 ? (
            <>
              <video
                src={videoD4.uri}
                width={720}
                height={500}
                controls
                controlsList="nodownloads"
              >
                <source type="video/mp4" />
              </video>
            </>
          ) : (
            <h1>Anda belum menjawab pertanyaan ini</h1>
          )}
        </div>
      </CollapseComponent>
      {questionType == 3 ? (
        <>
          <CollapseComponent
            title={`S1 Pembahasan Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
            } `}
          >
            <div className="text-center mt-3 mb-3">
              {Object.keys(videoS1).length > 0 ? (
                <>
                  <video
                    src={videoS1.uri}
                    width={720}
                    height={500}
                    controls
                    controlsList="nodownloads"
                  >
                    <source type="video/mp4" />
                  </video>
                </>
              ) : (
                <h1>Anda belum menjawab pertanyaan ini</h1>
              )}
            </div>
          </CollapseComponent>
          <CollapseComponent
            title={`Pfs Pembahasan Try Out ${
              questionType === 3 ? "Uji Kompetensi" : "SKB Gizi"
            } `}
          >
            <div className="text-center mt-3 mb-3">
              {Object.keys(videoPsf).length > 0 ? (
                <>
                  <video src={videoPsf.uri} controls controlsList="nodownloads">
                    <source type="video/mp4" />
                  </video>
                </>
              ) : (
                <h1>Anda belum menjawab pertanyaan ini</h1>
              )}
            </div>
          </CollapseComponent>
        </>
      ) : null}
    </Layout>
  );
};

export default VideoPembahasan;
