import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CreateQuestionApi } from "../../../services";

const CreateQuestion = (validation) => {
  const { customer } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);
  const { templateSecureId } = useSelector((state) => state);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState({
    answers: [
      {
        content: "",
        value: true,
      },
      {
        content: "",
        value: false,
      },
      {
        content: "",
        value: false,
      },
      {
        content: "",
        value: false,
      },
      {
        content: "",
        value: false,
      },
    ],
    content: "",
    discussion: "",
    label: "",
    type: "",
    questionSubType,
    questionType,
    templateSecureId,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (questionType == 2) {
      if (name == "label") {
        question.label = value;
      }
      if (name == "type") {
        console.log(value);
        question.type = value;
      }
    }
    if (name === "content") {
      question.content = value;
    } else if (name === "discussion") {
      question.discussion = value;
    } else if (name === "answerContent0") {
      question.answers[0].content = value;
    } else if (name === "answerContent1") {
      question.answers[1].content = value;
    } else if (name === "answerContent2") {
      question.answers[2].content = value;
    } else if (name === "answerContent3") {
      question.answers[3].content = value;
    } else if (name === "answerContent4") {
      question.answers[4].content = value;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validation(question));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const QuestionCreate = async () => {
        setLoading(true);
        const res = await CreateQuestionApi(question, customer.token);
        console.log("res");
        console.log(res);
        if (res.data.statusCode === 20) {
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
        // // setQuestion(res.data.result);
        setLoading(false);
      };
      QuestionCreate();
    }
  }, [errors, customer, isSubmit, question]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    question,
    isSubmit,
  };
};

export default CreateQuestion;
