import axios from "axios";

const DetailLesson = async (token, secureId) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/bimbel/lesson?token=${token}&secureId=${secureId}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default DetailLesson;
