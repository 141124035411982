import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Spinner,
  Col,
  Alert,
  Row,
  ModalFooter,
  Modal,
  ModalBody,
} from "reactstrap";
import * as AiIcons from "react-icons/ai";
import LayoutAdmin from "../../LayoutAdmin";
import {
  QuestionListApi,
  DeleteQuestionApi,
  QuizPracticeApi,
} from "../../../../services";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { getQuestion } from "../../../../redux/features/customerSlice";

const { SearchBar } = Search;

export const QuizPractice = () => {
  const [questionList, setQuestionList] = useState([]);
  const [question, setQuestion] = useState();
  const [listsError, setListError] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const deleteQuestion = async (question) => {
    setQuestion(question);
    setModal(!modal);
  };

  const { customer } = useSelector((state) => state);
  const { questionSubType } = useSelector((state) => state);
  const { questionType } = useSelector((state) => state);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchQuestion = async () => {
      setListLoading(true);
      const res = await QuizPracticeApi(questionType, customer.token);

      if (res && res.statusCode === 20) {
        setListLoading(false);

        setQuestionList(res.result);
      } else {
        setListLoading(false);
        setListError(true);
      }
    };
    fetchQuestion();
  }, [questionType, questionSubType, customer]);

  const columns = [
    // Don't delete this
    // {
    //   dataField: "secureId",
    //   text: "Secure Id",
    //   sort: true,
    // },
    {
      dataField: "content",
      text: "Content",
      sort: true,
    },
    {
      dataField: "discussion",
      text: "Discussion",
      sort: true,
    },
    {
      dataField: "answers[0].content",
      text: "Jawaban 1",
      sort: true,
    },
    {
      dataField: "answers[1].content",
      text: "Jawaban 2",
      sort: true,
    },
    {
      dataField: "answers[2].content",
      text: "Jawaban 3",
      sort: true,
    },
    {
      dataField: "answers[3].content",
      text: "Jawaban 4",
      sort: true,
    },
    {
      dataField: "answers[4].content",
      text: "Jawaban 5",
      sort: true,
    },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              <Col>
                <Link to={`/admin/quiz-practice/question/update`}>
                  <Button
                    color="warning"
                    onClick={() => dispatch(getQuestion(row))}
                  >
                    <AiIcons.AiOutlineEdit />
                  </Button>
                </Link>
              </Col>
              <Col>
                <Button color="danger" onClick={() => deleteQuestion(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  return (
    <LayoutAdmin>
      <h3>
        List Question{" "}
        {questionType === 1
          ? "Kuis Mandiri"
          : questionType === 2
          ? "Contoh Soal"
          : questionType === 3
          ? "Try Out UKOM"
          : questionType === 4
          ? "Try Out SKB GIZI"
          : ""}{" "}
        {questionSubType === 1
          ? "D3"
          : questionSubType === 2
          ? "D4"
          : questionSubType === 3
          ? "S1"
          : questionSubType === 4
          ? "Pfs"
          : ""}{" "}
        Gizi
      </h3>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <p className="text-center">
            <strong>Detail</strong>
          </p>
          <ul>
            <li>Content : {question && question.content}</li>
            <li>Discussion : {question && question.discussion}</li>
            <li>Jawaban 1 : {question && question.answers[0].content}</li>
            <li>Jawaban 2 : {question && question.answers[2].content}</li>
            <li>Jawaban 3 : {question && question.answers[3].content}</li>
            <li>Jawaban 4 : {question && question.answers[4].content}</li>
            <li>Jawaban 5 : {question && question.answers[1].content}</li>
          </ul>
          <h5 className="text-center">
            Apakah Anda Yakin Akan Menghapus Data?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              toggle();
              await DeleteQuestionApi(question.secureId, customer.token);
              window.location.reload();
            }}
          >
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Tidak
          </Button>
        </ModalFooter>
      </Modal>

      <ToolkitProvider
        bootstrap4
        defaultSorted={defaultSorted}
        keyField="secureId"
        data={questionList}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <Link to="/admin/question/create">
                  <Button color="primary mt-4">
                    <AiIcons.AiOutlineQuestion /> Buat
                  </Button>
                </Link>
              </Col>
              <Col>
                <div className="d-flex justify-content-end mb-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </Col>
            </Row>

            {listLoading ? (
              <div className="text-center">
                <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
              </div>
            ) : questionList.length > 0 ? (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            ) : (
              <Alert color="danger" className="my-5">
                Question Kosong
              </Alert>
            )}
          </div>
        )}
      </ToolkitProvider>
      {listsError && (
        <Alert color="danger" className="my-5">
          Terjadi Kesalahan saat pegambilan Data
        </Alert>
      )}

      {/* {questionList.length !== 0 ? (
          <div>
            <p className="text-end">
              Time : <strong>{questionList.timeLimit}</strong> minute
            </p>
            <ol className="list-group list-group-numbered">
              {questionList.exam.map((question, Qindx) => (
                <div key={Qindx}>
                  <li className="list-group-item d-flex justify-content-between align-items-start">
                    <div className="text-end">
                      <button>Edit</button>
                      <button>Delete</button>
                    </div>
                    <div className="ms-2 me-auto">
                      <div className="fw-bold">{question.content}</div>

                      <ul>
                        {question.answers.map((ans, Aindx) => (
                          <li key={Aindx}>{ans.content}</li>
                        ))} */}
      {/* <li>{question.answers[0].content}</li>
                        <li>{question.answers[1].content}</li>
                        <li>{question.answers[2].content}</li>
                        <li>{question.answers[3].content}</li>
                        <li>{question.answers[4].content}</li> */}
      {/* </ul>
                    </div>
                  </li>
                </div>
              ))}
            </ol>
          </div>
        ) : (
          <div className="text-center">
            <p className="text-danger">{listsError}</p>
          </div>
        )} */}
    </LayoutAdmin>
  );
};

export default QuizPractice;
