import axios from "axios";

const DetailInvoiceAPI = async (token, packageType) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/payment/detail?token=${token}&packageType=${packageType}`;
    const url = `${BASED_URL}${params}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export default DetailInvoiceAPI;
