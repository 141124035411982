import React, { useState, useRef, useEffect } from "react";
import "./style.css";

const CollapseComponent = ({ children, title }) => {
  const [active, setActive] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight * 4}px`
      : "0px";
  }, [contentRef, active]);

  const toogleActive = () => {
    setActive(!active);
  };

  const titleStyle = {
    fontWeight: 600,
    fontSize: "14px",
  };

  return (
    <div className="accordion-section my-2">
      <button className="accordion-title" onClick={toogleActive}>
        <p className="mt-3 mx-2" style={titleStyle}>
          {title}
        </p>
        <span
          className={active ? "accordion-icon rotate" : "accordion-icon"}
        ></span>
      </button>

      <div ref={contentRef} className="accordion-content mx-3">
        {children}
      </div>
    </div>
  );
};

export default CollapseComponent;
