import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
    labValue: null,
    theory: null,
    schedule: null,
    question: null,
    institute: null,
    questionSubType: null,
    questionType: null,
    theoryType: null,
    templateSecureId: null,
    template: null,
    quizAnsweredUser: null,
    bimbelSecureId: null,
    bimbelType: null,
    lessonSecureId: null,
    lesson: null,
    quizQuestion: null,
    buttonDisable: null,
    timeLimit: null,
  },
  reducers: {
    login: (state, action) => {
      state.customer = action.payload;
    },
    disablePremium: (state, action) => {
      state.customer.premium = action.payload;
    },
    disableButton: (state, action) => {
      state.buttonDisable = action.payload;
    },
    logout: (state) => {
      state.customer = null;
      state.labValue = null;
      state.theory = null;
      state.schedule = null;
      state.question = null;
      state.questionSubType = null;
      state.questionType = null;
      state.theoryType = null;
      state.quizAnsweredUser = null;
      state.institute = null;
    },
    getLabValue: (state, action) => {
      state.labValue = action.payload;
    },
    deleteLabValue: (state) => {
      state.labValue = null;
    },
    getTheory: (state, action) => {
      state.theory = action.payload;
    },
    deleteTheory: (state) => {
      state.theory = null;
    },
    getSchedule: (state, action) => {
      state.schedule = action.payload;
    },
    deleteSchedule: (state) => {
      state.schedule = null;
    },
    getQuestion: (state, action) => {
      state.question = action.payload;
    },
    deleteQuestion: (state) => {
      state.schedule = null;
    },
    getQuestionSubType: (state, action) => {
      state.questionSubType = action.payload;
    },
    deleteQuestionSubType: (state) => {
      state.questionSubType = null;
    },
    getTemplateSecureId: (state, action) => {
      state.templateSecureId = action.payload;
    },
    getQuestionType: (state, action) => {
      state.questionType = action.payload;
    },
    deleteQuestionType: (state) => {
      state.questionType = null;
    },
    getTheoryType: (state, action) => {
      state.theoryType = action.payload;
    },
    deleteTheoryType: (state) => {
      state.theoryType = null;
    },
    getQuizAnsweredUser: (state, action) => {
      state.quizAnsweredUser = action.payload;
    },
    deleteQuizAnsweredUser: (state) => {
      state.quizAnsweredUser = null;
    },
    getInstitute: (state, action) => {
      state.institute = action.payload;
    },
    deleteInstitute: (state) => {
      state.institute = null;
    },
    setBimbelSecureId: (state, action) => {
      state.bimbelSecureId = action.payload;
    },
    setBimbelType: (state, action) => {
      state.bimbelType = action.payload;
    },
    setPremium: (state, action) => {
      state.customer.premium = action.payload;
    },
    setLessonId: (state, action) => {
      state.lessonSecureId = action.payload;
    },
    setLesson: (state, action) => {
      state.lesson = action.payload;
    },
    setQuizAnswer: (state, action) => {
      state.quizAnsweredUser = action.payload;
    },
    setQuizQuestion: (state, action) => {
      state.quizQuestion = action.payload;
    },
    setTimelimit: (state, action) => {
      state.timeLimit = action.payload;
    },
  },
});

export const {
  login,
  logout,
  getLabValue,
  deleteLabValue,
  getTheory,
  deleteTheory,
  getSchedule,
  deleteSchedule,
  getQuestion,
  deleteQuestion,
  getQuestionSubType,
  deleteQuestionSubType,
  getQuestionType,
  deleteQuestionType,
  getTheoryType,
  deleteTheoryType,
  getQuizAnsweredUser,
  deleteQuizAnsweredUser,
  getInstitute,
  getTemplateSecureId,
  deleteInstitute,
  setBimbelSecureId,
  setBimbelType,
  setPremium,
  setLessonId,
  setLesson,
  setQuizAnswer,
  setQuizQuestion,
  disablePremium,
  disableButton,
  setTimelimit,
} = customerSlice.actions;
export const selectCustomer = (state) => state.customer;
export const selectQuestion = (state) => state.question;
export const selectLabValue = (state) => state.labValue;
export const selectTheory = (state) => state.theory;
export const selectSchedule = (state) => state.schedule;
export const selectQuestionSubType = (state) => state.questionSubType;
export const selectQuestionType = (state) => state.questionType;
export const selectInstitute = (state) => state.institute;
export const selectTheoryType = (state) => state.theoryType;
export const selectQuizAnsweredUser = (state) => state.quizAnsweredUser;
export default customerSlice.reducer;
