import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { storage } from "../../../config/FirebaseConfig";
import { CreateLesson } from "../../../services";
const LessonServiceAdd = (validation) => {
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [progressVideo, setProgressVideo] = useState(0);
  const [progressImage, setProgressImage] = useState(0);
  const [counter, setCounter] = useState(0);
  const { bimbelType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [data, setData] = useState({
    bimbelType: bimbelType,
    theory: "",
    title: "",
    thumbnailUri: "",
    videoUri: "",
    videoName: "",
    thumbnailName: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    let selectedFile = e.target.files?.[0];
    if (name === "title") {
      data.title = value;
    } else if (name === "theory") {
      data.theory = value;
    } else if (name == "thumbnailUri") {
      data.thumbnailUri = selectedFile.type;
      data.thumbnailName = selectedFile;
    } else if (name == "videoUri") {
      data.videoUri = selectedFile.type;
      data.videoName = selectedFile;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validation(data));
    setIsSubmit(true);
  };
  const format = (x, y) => {
    let z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  const testNewFunctionUpload = (storageRef, type) => {
    return new Promise((resolve, reject) => {
      const arr =
        type == 1
          ? data.videoName?.name.split(".")
          : data.thumbnailName?.name.split(".");
      const formatName =
        arr[0] + " " + format(new Date(), "ddMMyyyyhhmmss") + "." + arr[1];
      if (type == 1) {
        var metadata = {
          contentType: "video/mp4",
        };
        var path = "lesson/video";
        var selectedFile = data.videoName;
      } else {
        let metadata = {
          contentType: "image/jpeg",
        };
        var path = "lesson/images";
        var selectedFile = data.thumbnailName;
      }
      var cekProgress = 0;
      let uploadTask = storageRef
        .child(`${path}/${formatName}`)
        .put(selectedFile, metadata);
      uploadTask.on(
        "state_changed",
        function progress(snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (type == 1) {
            setProgressVideo(progress);
          } else {
            setProgressImage(progress);
          }
          console.log("Upload is " + progress + "% done");
          cekProgress = progress;
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        function error(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              console.log("User doesn't have permission to access the object");
              break;

            case "storage/canceled":
              // User canceled the upload
              console.log("User canceled the upload");
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              console.log(
                "Unknown error occurred, inspect error.serverResponse"
              );
              break;
          }
        },
        function completed() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            if (type == 1) {
              data.videoUri = downloadURL;
              console.log("Video available at", data.videoUri);
              setProgressVideo(cekProgress + 1);
            } else {
              data.thumbnailUri = downloadURL;
              console.log("File available at", downloadURL);
            }
            resolve("Completed");
          });
        }
      );
    });
  };

  const uploadFile = async (storageRef, type) => {
    await testNewFunctionUpload(storageRef, type);
  };

  useEffect(() => {
    if (Object.keys(errors).length == 0 && isSubmit) {
      let storageRef = storage.ref();
      uploadFile(storageRef, 1);
      uploadFile(storageRef, 2);
    }
  }, [errors, isSubmit]);
  return {
    handleSubmit,
    errors,
    data,
    handleChange,
    isSubmit,
    counter,
    progressImage,
    progressVideo,
  };
};

export default LessonServiceAdd;
