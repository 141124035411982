import React, { useState, useEffect } from "react";
import { StyledButtonSocial } from "./style";
import { googleProvider } from "../../config/AuthMethod";
import { AuthGoogleApi } from "../../services";
// import firebase from "firebase";
import { AuthLoginApi, AuthRegisterApi } from "../../services";
import { useDispatch } from "react-redux";
import { login } from "../../redux/features/customerSlice";
import { Redirect } from "react-router-dom";

export function ButtonLogin({ children, ...props }) {
  return (
    <>
      <StyledButtonSocial {...props}>{children}</StyledButtonSocial>
    </>
  );
}

export function ButtonMedia({ children, ...props }) {
  const [isSign, setIsSign] = useState(false);
  const [errors, setErrors] = useState();
  const [Google, setGoogle] = useState();

  const dispatch = useDispatch();

  const handleOnClick = async (provider) => {
    const acc = await AuthGoogleApi(provider);
    if (acc.message) {
      return setErrors(acc.message);
    } else {
      setGoogle({
        email: acc.email,
        firstName: acc.displayName,
        gender: "",
        lastName: "",
        password: "",
        registrationType: 2,
        role: 1,
      });
      setIsSign(true);
    }
  };

  useEffect(() => {
    if (isSign) {
      const googleLog = {
        email: Google.email,
        password: "",
        type: Google.registrationType,
        valid: true,
      };

      const GoogleRegister = async () => {
        console.log(`WOrk ${Google}`);
        const res = await AuthRegisterApi(Google);
        console.log(res);
        if (res.statusCode === 99 || res.statusCode === 20) {
          const resLog = await AuthLoginApi(googleLog);
          if (
            resLog.statusCode === 20 &&
            resLog.result.role === 1 &&
            resLog.result.type === 2
          ) {
            dispatch(login({ ...resLog.result }));
            // history.push("/schedule/lis  t");
            return <Redirect to="/schedule/list" />;
          } else if (resLog.statusCode === 99) {
            dispatch(login({ ...resLog.result }));
            // history.push("/schedule/list");
            return <Redirect to="/schedule/list" />;
          }
        } else if (res.statusCode === 90) {
          setErrors("Gagal menyimpan data, Silahkan coba beberapa saat lagi.");
        } else {
          setErrors(res.message);
        }
      };
      GoogleRegister();
    }
  }, [isSign, Google, dispatch]);

  // const handleLogout = () => {
  //   dispatch(logout());
  //   firebase.auth().signOut();
  // };

  return (
    <>
      {errors ? window.alert(errors) : ""}
      {/* <button
        onClick={() =>
          firebase
            .auth()
            .signOut()
            .then(() => {
              console.log("Sign out success");
            })
            .catch((error) => {
              console.log("Sign out failed.");
            })
        }
      >
        Logout
      </button> */}
      <StyledButtonSocial
        {...props}
        onClick={() => handleOnClick(googleProvider)}
      >
        {children}
      </StyledButtonSocial>
    </>
  );
}
