import styled from "styled-components";

export const StyledInput = styled.input`
  width: 100%;
  border: 0;
  z-index: 1;
  text-align: center;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  ${"" /* padding: 0.25rem 0; */}
  &:focus,
  &:valid,
  type=text {
    outline: 0;
    border-bottom-color: #393e46;
  }
`;
