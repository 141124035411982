import { IconShow, IconHide } from "./style";

export default function TogglePassword({
  isRevealPassword,
  setIsRevealPassword,
}) {
  return (
    <>
      {isRevealPassword ? (
        <IconShow
          onClick={() => setIsRevealPassword((prevState) => !prevState)}
        />
      ) : (
        <IconHide
          onClick={() => setIsRevealPassword((prevState) => !prevState)}
        />
      )}
    </>
  );
}
