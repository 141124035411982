import axios from "axios";

export const CreateInstitute = async (institute, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/institute/create?";
    const endpoint = `${BASED_URL}${path}token=${token}`;

    const response = await axios.post(endpoint, institute);

    return response;
  } catch (error) {
    return error.response;
  }
};

export default CreateInstitute;
