import axios from "axios";

const WebinarAddAPI = async (token, data) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/admin/webinar/create?token=${token}`;
    const url = `${BASED_URL}${params}`;
    const response = await axios.post(url, data);
    return response.data;
  } catch (err) {
    return err.response;
  }
};

export default WebinarAddAPI;
