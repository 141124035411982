function validationTheory(values) {
  let errorIni = {};
  const fileType = ["application/pdf"];

  console.log("values");
  console.log(values);

  if (!values.competence) {
    errorIni.competence = "Masukkan Kompetensi terlebih dahulu";
  }

  if (!values.uri) {
    errorIni.uri = "Masukan File PDF.";
  }

  return errorIni;
}

export default validationTheory;
