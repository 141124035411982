import Layout from "./Layout";
import { Row, Col, Container, Button, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { BimbelInformation, LessonListAPI } from "../services";
import { useDispatch, useSelector } from "react-redux";
import * as FaIcons from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { setLessonId } from "../redux/features/customerSlice";
import ReminderWebinerAPI from "../services/adminApi/webinar/reminderWebinar";
export default function BimbelPremium() {
  const [ukomActive, setUkomActive] = useState(false);
  const [skbActive, setSkbActive] = useState(false);
  const [data, setData] = useState([]);
  const [counterCheck, setCounterCheck] = useState(0);
  const [cekActive, setCekActive] = useState(0);
  const [webinars, setWebinars] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [loadingWebinar, setLoadingWebinar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const reminder = async (data) => {
    const elementSpinner = document.getElementById(data.secureId);
    const textElement = document.getElementById("text-" + data.secureId);
    console.log(textElement);
    elementSpinner.style.display = "";
    textElement.style.display = "none";
    await ReminderWebinerAPI(customer.token, data.secureId)
      .then((res) => {
        if (res.statusCode === 20) {
          var x = document.getElementById("snackbar");
          x.className = "show";
          x.textContent = "Berhasil mengingatkan";
          elementSpinner.style.display = "none";
          textElement.style.display = "";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 1500);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoadingWebinar(false);
      });
  };

  const handleClick = async (id) => {
    setLoading(true);
    if (id == 1) {
      setUkomActive(true);
      setSkbActive(false);
    } else {
      setUkomActive(false);
      setSkbActive(true);
    }
    const viewDataLesson = async (id) => {
      const res = await LessonListAPI(customer.token, id);
      setData([]);
      if (res.statusCode == 20) {
        setWebinars(res.result.webinars);
        setData(res.result.lessons);
        setLoading(false);
      }
    };
    await viewDataLesson(id);
  };
  const tester = (id) => {
    dispatch(setLessonId(id));
    history.push("/detail-lesson");
  };
  useEffect(() => {
    const checkExistLesson = async () => {
      setLoading(true);
      try {
        const cekPaket = await BimbelInformation(customer.token);
        if (cekPaket.statusCode === 20) {
          setUkomActive(cekPaket.result.ukomActive);
          if (cekPaket.result.ukomActive == false) {
            setCounterCheck(1);
          }
          if (cekPaket.result.skbActive == false) {
            setCounterCheck(2);
          }
          setSkbActive(cekPaket.result.skbActive);
          setLoading(false);
          document.getElementById("container-video").style = "none";
        }
      } catch (err) {
        console.log(err);
      }
    };
    checkExistLesson();
  }, []);
  return (
    <Layout>
      <Container fluid>
        <div id="snackbar">Berhasil Mengingatkan</div>

        <h4>Bimbingan Belajar untuk mendalami materi gizi kamu</h4>
        <div className="paket-container mt-5">
          <Row>
            <Col lg="6">
              <div className="card-container">
                <div class="container text-center p-4">
                  <h4 style={{ textDecoration: "underline" }}>
                    Uji Kompetensi
                  </h4>
                  <Button
                    className="mt-5"
                    style={{
                      width: "140px",
                      backgroundColor: ukomActive ? "red" : "black",
                    }}
                    disabled={counterCheck == 1 ? "true" : ""}
                    onClick={() => {
                      handleClick(1);
                    }}
                  >
                    Pilih
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="6">
              <div className="card-container">
                <div class="container text-center p-4">
                  <h4 style={{ textDecoration: "underline" }}>SKB Gizi</h4>
                  <Button
                    className="mt-5"
                    style={{
                      width: "140px",
                      backgroundColor: skbActive ? "red" : "black",
                    }}
                    disabled={counterCheck == 2 ? "true" : ""}
                    onClick={() => {
                      handleClick(2);
                    }}
                  >
                    Pilih
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="video-container" id="container-video">
          {data.length == 0
            ? ""
            : [
                loading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="card-container p-4">
                      <h5>
                        {ukomActive
                          ? "Bimbingan Belajar Uji Kompetensi"
                          : "Bimbingan Belajar SKB Gizi"}
                      </h5>
                      <Container
                        className="video p-4 large-2 web"
                        fluid
                        style={{
                          overflowX: "hidden",
                          overflowY: "auto",
                          maxHeight: "600px",
                          minWidth: "100%",
                        }}
                      >
                        <Row className="text-center">
                          {data &&
                            data.map((val) => {
                              return (
                                <Col lg="6" key={val.secureId}>
                                  <div
                                    className="mt-2 mb-2"
                                    style={{
                                      position: "relative",
                                      width: "560px",
                                      height: "315px" /*Or whatever you want*/,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => tester(val.secureId)}
                                  >
                                    <img
                                      style={{
                                        width: "460px",
                                        height: "315px",
                                      }}
                                      class="imageOne"
                                      src={val.thumbnailUri}
                                    />
                                    <a>
                                      <FaIcons.FaYoutube
                                        color="red"
                                        style={{
                                          position: "absolute",
                                          width: "200px",
                                          height: "100px",
                                          top: "50%",
                                          left: "50%",
                                          transform: "translate(-50%, -50%)",
                                        }}
                                      />
                                    </a>
                                  </div>
                                </Col>
                              );
                            })}
                          {data.length == 0 && <h1>Data Tidak Ada</h1>}
                        </Row>
                        <div class="force-overflow"></div>
                      </Container>
                    </div>
                  </>
                ),
              ]}

          <div className="card-container p-4">
            {webinars &&
              webinars.map((val) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      wordBreak: "break-word",
                    }}
                  >
                    <h5 className="mb-3">{val.title}</h5>
                    <div className="p-3 d-flex ">
                      <Button
                        color="danger"
                        style={{
                          position: "relative",
                          width: "200px",
                          marginRight: "20px",
                          height: "70px",
                        }}
                        onClick={() => {
                          reminder(val);
                        }}
                      >
                        <Spinner
                          id={val.secureId}
                          style={{ display: "none" }}
                        />
                        <h5 id={"text-" + val.secureId} style={{ display: "" }}>
                          Ingatkan
                        </h5>
                      </Button>
                      <div>
                        <p>Join Zoom Meeting</p>

                        <a href={val.link}>{val.link}</a>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
