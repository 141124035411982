import { useState, useEffect } from "react";
import { CreateTheoryApi, CreateInstituteApi } from "../../../services";
import { useSelector } from "react-redux";
import { storage } from "../../../config/FirebaseConfig";

const CreateInstitute = () => {
  const { theoryType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  // const [isSubmit, setIsSubmit] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [imgFileError, setImgError] = useState("");
  const [progress, setProgress] = useState(0);
  const [institute, setInstitute] = useState({
    description: "",
    uri: "",
  });

  const format = function date2str(x, y) {
    let z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };

  // console.log(format(new Date(), "ddMMyyyy"));

  const fileType = ["image/png"];

  const handleChange = (e) => {
    let { name, value } = e.target;
    let selectedFile = e.target.files?.[0];
    if (name === "description") {
      institute.description = value;
    }

    if (name === "uri") {
      if (selectedFile) {
        if (selectedFile && fileType.includes(selectedFile.type)) {
          setImgError("");
        } else {
          setImgError("select valid img file.");
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let { name, value } = e.target[1];
    let selectedFile = e.target[1].files?.[0];
    const arr = selectedFile?.name.split(".");

    let storageRef = storage.ref();

    if (
      name === "uri" &&
      selectedFile &&
      fileType.includes(selectedFile.type)
    ) {
      const instituteFormatName =
        arr[0] + " " + format(new Date(), "ddMMyyyyhhmmss") + "." + arr[1];

      let metadata = {
        contentType: "images/*",
      };

      let uploadTask = storageRef
        .child("institute/" + instituteFormatName)
        .put(selectedFile, metadata);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        function (error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              console.log("User doesn't have permission to access the object");
              break;

            case "storage/canceled":
              // User canceled the upload
              console.log("User canceled the upload");
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              console.log(
                "Unknown error occurred, inspect error.serverResponse"
              );
              break;
          }
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            institute.uri = downloadURL;

            // if (Object.keys(errors).length === 0 && imgFileError.length === 0) {
            if (imgFileError.length === 0) {
              const InstituteCreate = async () => {
                // setLoading(true);

                const res = await CreateInstituteApi(institute, customer.token);

                if (res.data.statusCode === 20) {
                  setInstitute(res.data.result);
                  // setLoading(false);
                } else {
                  errors.invalid = res.data.message;
                  // setLoading(false);
                  return;
                }
              };
              InstituteCreate();
            }
            console.log("File available at", downloadURL);
          });
        }
      );
    } else {
      errors.uri = "Masukkan File PDF.";
      institute.uri = "";
    }
  };

  // useEffect(() => {
  //   if (Object.keys(errors).length === 0 && isSubmit) {
  //     console.log("muncullah");
  //     const TheoryCreate = async () => {
  //       console.log("muncullah createtheory");
  //       setLoading(true);

  //       const res = await CreateTheoryApi(theory, customer.token);

  //       if (res.data.statusCode === 20) {
  //         setInstitute(res.data.result);
  //         setLoading(false);
  //       } else {
  //         errors.invalid = res.data.message;
  //         setLoading(false);
  //         return;
  //       }
  //     };
  //     TheoryCreate();
  //   }
  // }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    // loading,
    institute,
    imgFileError,
    progress,
    // isSubmit,
  };
};

export default CreateInstitute;
