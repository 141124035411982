import { useState, useEffect } from "react";
import { CreateLabValueApi } from "../../../services";

const CreateLabValue = (ValidationLabValue, token) => {
  const [labValue, setLabValue] = useState({
    measure: "",
    unit: "",
    referenceValue: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;

    setLabValue({
      ...labValue,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(ValidationLabValue(labValue));
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const LabValueCreate = async () => {
        setLoading(true);
        const res = await CreateLabValueApi(labValue, token);

        if (res.data.statusCode === 20) {
          setLabValue(res.data.result);
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          setLoading(false);
          return;
        }
      };
      LabValueCreate();
    }
  }, [errors]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    labValue,
    isSubmit,
  };
};

export default CreateLabValue;
