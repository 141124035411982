import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import paginationFactory from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  Button,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
} from "reactstrap";

import { getTheory } from "../../../redux/features/customerSlice";
import LayoutAdmin from "../LayoutAdmin";
import { TheoryApi, DeleteTheoryApi } from "../../../services";
import { storage } from "../../../config/FirebaseConfig";

const { SearchBar } = Search;

const AdminTheory = () => {
  const [ListTheory, setListTheory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [theory, setTheory] = useState({});
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { customer } = useSelector((state) => state);
  const { theoryType } = useSelector((state) => state);

  const dispatch = useDispatch();

  const deleteTheory = (data) => {
    setTheory(data);
    setModal(!modal);
  };

  useEffect(() => {
    const fetchTheory = async () => {
      setLoading(true);
      const res = await TheoryApi(theoryType, customer.token);

      if (res && res.statusCode === 20) {
        setListTheory(res.result);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    fetchTheory();
  }, [customer, theoryType]);

  const columns = [
    //Don't Delete This
    // {
    //   dataField: "secureId",
    //   text: "Schedule ID",
    //   sort: true,
    // },
    {
      dataField: "competence",
      text: "Competence",
      formatter: (rowContent, row) => {
        return (
          <a href={row.uri} target="_blank">
            {row.competence}
          </a>
        );
      },
      sort: true,
    },
    // {
    //   dataField: "uri",
    //   text: "Uri",
    //   formatter: (rowContent, row) => {
    //     console.log("row");
    //     console.log(row);
    //     return (
    //       <Container fluid>
    //         <a href={row.uri} target="_blank">
    //           {row.uri.substring(0, 100) + "..."}
    //         </a>
    //       </Container>
    //     );
    //   },
    //   sort: true,
    // },
    {
      dataField: "link",
      text: "Action",
      formatter: (rowContent, row) => {
        return (
          <div>
            <Row>
              {/* <Col>
                <Link to={`/admin/theory/update`}>
                  <Button
                    color="warning"
                    onClick={() => dispatch(getTheory(row))}
                  >
                    <AiIcons.AiOutlineEdit />
                  </Button>
                </Link>
              </Col> */}
              <Col className="text-center">
                <Button color="danger" onClick={() => deleteTheory(row)}>
                  <AiIcons.AiOutlineDelete />
                </Button>
              </Col>
            </Row>
          </div>
        );
      },
      headerStyle: () => {
        return { width: "130px" };
      },
      sort: true,
    },
  ];

  const defaultSorted = [
    {
      dataField: "secureId",
      order: "asc",
    },
  ];

  console.log(ListTheory);

  return (
    <LayoutAdmin>
      <h1>Theory {theoryType === 1 ? "UKOM" : "SKB GIZI"}</h1>

      <Modal isOpen={modal} toggle={toggle}>
        {/* <ModalHeader toggle={toggle}>{schedule.desc}</ModalHeader> */}
        <ModalBody>
          <p className="text-center">
            <strong>Detail</strong>
          </p>
          <ul>
            <li>Content : {theory.competence}</li>
            {/* <li>value : {theory.uri}</li> */}
          </ul>
          <h5 className="text-center">
            Apakah Anda Yakin Akan Menghapus Data?
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={async () => {
              toggle();
              await DeleteTheoryApi(theory.secureId, customer.token);
              let fileRef = storage.refFromURL(theory.uri);
              // Delete the file

              fileRef
                .delete()
                .then(function () {
                  setIsDelete(true);
                  console.log("delete file berhasil.");
                  // File deleted successfully
                })
                .catch(function (error) {
                  console.log("delete file gagal.");
                  // Uh-oh, an error occurred!
                });
              setListTheory(
                ListTheory.filter((item) => {
                  return item.secureId !== theory.secureId;
                })
              );
            }}
          >
            Ya
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Tidak
          </Button>
        </ModalFooter>
      </Modal>
      {isDelete && <Alert color="success">Data berhasil dihapus.</Alert>}
      <ToolkitProvider
        bootstrap4
        defaultSorted={defaultSorted}
        keyField="secureId"
        data={ListTheory}
        columns={columns}
        search
      >
        {(props) => (
          <div>
            <Row>
              <Col>
                <Link to="/admin/theory/create">
                  <Button color="primary mt-4">
                    <BiIcons.BiBookOpen /> Buat
                  </Button>
                </Link>
              </Col>
              <Col>
                <div className="offset-8 mb-2">
                  <SearchBar {...props.searchProps} />
                </div>
              </Col>
            </Row>

            {loading ? (
              <div className="text-center">
                <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
              </div>
            ) : (
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            )}
            {error && (
              <Alert color="danger">
                Terjadi Kesalahan saat pegambilan Data
              </Alert>
            )}
          </div>
        )}
      </ToolkitProvider>
    </LayoutAdmin>
  );
};

export default AdminTheory;
