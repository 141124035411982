import axios from "axios";

export const DeleteScheduleApi = async (id, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/schedule/delete?";
    const endpoint = `${BASED_URL}${path}scheduleSecureId=${id}&token=${token}`;
    const response = axios.delete(endpoint);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default DeleteScheduleApi;
