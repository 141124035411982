import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Spinner } from "reactstrap";
import { BackComponent } from "../../components";
import CreateInvoice from "../../services/payment/createInvoice";
import DetailInvoiceAPI from "../../services/payment/detailInvoice";
import Layout from "../Layout";

const AskInfo = () => {
  const { bimbelType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const currency = Intl.NumberFormat("en-US");
  
  useEffect(() => {
    window.localStorage.clear();
    const getDetail = async () => {
      const res = await DetailInvoiceAPI(customer.token, bimbelType);
      if (res.statusCode == 20) {
        setData(res.result);
      }
    };
    getDetail();
  }, []);

  const handlePayment = () => {
    setLoading(true);
    const createPayment = async () => {
      const data = {
        packageType: bimbelType,
        successRedirectUrl: "https://tryout.ilmugiziku.com/success-payment",
        failureRedirectUrl: "https://tryout.ilmugiziku.com/failure",
      };
      const res = await CreateInvoice(customer.token, data);
      if (res.statusCode == 20) {
        window.localStorage.setItem("status", "checkout");
        setLoading(false);
        window.location.href = `${res.result.invoiceUrl}`;
      }
    };
    createPayment();
  };
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          marginTop: "7%",
          flexDirection: "column",
          height: "70%",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          gap: "10px",
        }}
      >
        {loading ? (
          <>
            <Spinner style={{ width: "7.5rem", height: "7.5rem" }} />
            <p>Mohon Menunggu, Anda akan diarahkan langsung ke pembayaran</p>
          </>
        ) : (
          <>
            {" "}
            <h1>Detail Pembayaran</h1>
            <h5>Email : {customer.email}</h5>
            <h5>
              Nama : {customer.firstName.toString().concat(customer.lastName)}
            </h5>
            <h5>Nama Paket : {data.packageName}</h5>
            <h5>Harga : Rp.{currency.format(data.fee)}</h5>
            <h5>Durasi: 6 Bulan </h5>
            <div className="d-inline-flex gap-2">
              <Button color="warning" onClick={handlePayment}>
                Checkout
              </Button>
              <BackComponent available={false}>Batal</BackComponent>
            </div>{" "}
          </>
        )}
      </div>
    </Layout>
  );
};

export default AskInfo;
