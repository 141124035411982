import styled from "styled-components";

export const StyledEntryCard = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  border-radius: 5px;
  padding: 50px;
  margin: auto;
  background-color: #54c2b7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  text-align: center;

  h1 {
    font-weight: 700;
    margin-bottom: 5px;
    color: #000;
  }
  span {
    display: block;
    margin-top: 30px;
    color: #000000;
    font-size: 14px;
  }
  .to-Login,
  .to-Role {
    color: #fff;
  }

  a {
    margin-left: 4px;
    color: #2f8bfd;
  }
  hr {
    margin-top: 25px;
    height: 3px;
    background: #fff;
  }
  label {
    display: block;
  }
  img {
    margin-top: -40px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
  }
`;

export const StyledCardLogin = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 5px;
  padding: 50px;
  margin: 27px 0;
  background-color: #54c2b7;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  text-align: center;
  h1 {
    font-weight: 700;
    margin-bottom: 5px;
    color: #000;
  }
  span {
    display: block;
    margin-top: 30px;
    margin-bottom: 15px;
    color: #000000;
    font-size: 14px;
  }

  span Link {
    color: #fff;
  }
  a {
    margin-left: 4px;
    color: #2f8bfd;
  }
  hr {
    margin-top: 25px;
    height: 3px;
    background: #fff;
  }
  label {
    display: inline-block;
    color: #fff;
  }
  img {
    margin-top: -40px;
    background-color: #fff;
    border-radius: 50%;
    padding: 2px;
  }
  form p {
    color: #fff;
  }
`;
