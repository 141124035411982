import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import CardComponent from "../../components/Card";
import { QuizPracticeApi, ExamSubmitApi } from "../../services";
import * as FaIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";

const Answer = () => {
  const { quizQuestion } = useSelector((state) => state);
  //   const { questionSubType } = useSelector((state) => state);
  const { quizAnsweredUser } = useSelector((state) => state);

  const [kuisMandiri, setKuisMandiri] = useState();
  const [quesAnswers, setQuesAnswers] = useState({
    exams: [],
    questionSubType: 0,
    questionType: 1,
  });
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  let history = useHistory();

  useEffect(() => {
    console.log(quizAnsweredUser);
    console.log(quizQuestion);
  }, []);

  const answeredQuestion = kuisMandiri?.filter((val, idx) => {
    return val.selected;
  });

  const selectOption = (indexSelected, indexOptionSelected) => {
    setKuisMandiri(
      kuisMandiri.map((val, idx) =>
        idx === indexSelected
          ? {
              ...val,
              selected: true,
              answers: val.answers.map((item, index) =>
                index === indexOptionSelected
                  ? { ...item, selected: true }
                  : { ...item, selected: false }
              ),
            }
          : val
      )
    );
  };

  const submitQuestion = (e) => {
    history.replace("/kuis-mandiri");
  };

  return (
    <Layout>
      <div className="shadow m-auto p-4 container">
        {quizQuestion?.length > 0 ? (
          <ol>
            {quizQuestion?.map((value, index) => {
              return (
                <React.Fragment key={index}>
                  <li>{value.content}</li>
                  {value.answers.map((value, idx) => {
                    return (
                      <div key={idx} className="d-flex">
                        <div
                          style={{
                            backgroundColor: value.selected
                              ? "#0072B8"
                              : "rgb(161, 178, 185)",
                            borderRadius: "70%",
                            padding: "7px",
                            height: "10px",
                            width: "10px",
                            margin: "2px",
                            borderStyle: "solid",
                            borderColor: "rgb(227, 231, 234)",
                          }}
                        ></div>
                        <span>{value.content}</span>
                        <div>
                          {value.selected ? (
                            value.value ? (
                              <FaIcons.BsCheckAll
                                color="#79e09f"
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : (
                              <MdIcons.MdClose
                                color="#c33237"
                                style={{ width: "30px", height: "25px" }}
                              />
                            )
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </ol>
        ) : (
          <Spinner
            className="offset-5"
            style={{ width: "7.5rem", height: "7.5rem" }}
          />
        )}

        <div className="text-center">
          <button
            type="submit"
            className="w-25 mb-2 bg-primary border-0 shadow  btn btn-secondary"
            style={{ background: "rgb(84, 194, 183)" }}
            onClick={submitQuestion}
          >
            Ulangi Kuis
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Answer;
