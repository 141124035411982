import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import CreateLabValue from "./CreateLabValue";
import ValidationLabValue from "./ValidationLabValue";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";

const Create = () => {
  const { customer } = useSelector((state) => state);

  const { handleChange, handleSubmit, errors, loading, isSubmit } =
    CreateLabValue(ValidationLabValue, customer.token);

  return (
    <LayoutAdmin>
      <h1>Buat Laboratory Value</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="measure" sm={2}>
            Ukuran :
          </Label>
          <Col>
            <Input
              type="text"
              name="measure"
              id="measure"
              onChange={handleChange}
            />
            {errors.measure && (
              <p className="text-danger small">{errors.measure}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="referenceValue">
            Reference Value :
          </Label>
          <Col>
            <Input
              type="text"
              name="referenceValue"
              id="referenceValue"
              onChange={handleChange}
            />
            {errors.referenceValue && (
              <p className="text-danger small">{errors.referenceValue}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label sm={2} htmlFor="unit">
            Unit :
          </Label>
          <Col>
            <Input type="text" name="unit" id="unit" onChange={handleChange} />
            {errors.unit && <p className="text-danger small">{errors.unit}</p>}
          </Col>
        </FormGroup>

        {Object.keys(errors).length === 0 && !loading && isSubmit && (
          <Alert color="success">Berhasil Membuat Laboratory Value.</Alert>
        )}

        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Submit"}
          </button>
          <BackComponent url="/admin/laboratory-value" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Create;
