import React from "react";
import LayoutAdmin from "../LayoutAdmin";
import UpdateSchedule from "./updateSchedule";
import validationUpdate from "./validationUpdate";
import { BackComponent } from "../../../components";
import { Form, FormGroup, Label, Input, Spinner, Alert, Col } from "reactstrap";
import { useSelector } from "react-redux";

const Update = () => {
  const { schedule } = useSelector((state) => state);

  const {
    handleChange,
    handleSubmit,
    errors,
    loading,
    schedules,
    isSubmitting,
  } = UpdateSchedule(validationUpdate, schedule);

  const day = new Date();
  const nextDay = new Date(day);
  nextDay.setDate(nextDay.getDate() + 1);

  return (
    <LayoutAdmin>
      <h1>Ubah Jadwal</h1>

      <Form onSubmit={handleSubmit}>
        <FormGroup row className="mb-2">
          <Label htmlFor="desc" sm={2}>
            Description :
          </Label>
          <Col>
            <Input
              type="text"
              name="desc"
              defaultValue={schedules.desc}
              ref={React.createRef()}
              id="desc"
              onChange={handleChange}
            />
            {errors.desc && <p className="text-danger small">{errors.desc}</p>}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="startTime" sm={2}>
            Start Date :
          </Label>
          <Col>
            <Input
              type="date"
              min={nextDay.toISOString().substring(0, 10)}
              name="startDate"
              defaultValue={schedules.startDate}
              ref={React.createRef()}
              id="startDate"
              onChange={handleChange}
            />
            {errors.desc && (
              <p className="text-danger small">{errors.startDate}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-2">
          <Label htmlFor="endDate" sm={2}>
            End Date :
          </Label>
          <Col>
            <Input
              type="date"
              min={schedules.startDate}
              disabled={schedules.startDate === "" && true}
              name="endDate"
              defaultValue={schedules.endDate}
              ref={React.createRef()}
              id="endDate"
              onChange={handleChange}
            />
            {errors.desc && (
              <p className="text-danger small">{errors.endDate}</p>
            )}
          </Col>
        </FormGroup>

        <FormGroup row className="mb-4">
          <Label htmlFor="timeLimit" sm={2}>
            Time Limit :
          </Label>
          <Col>
            <Input
              type="number"
              name="timeLimit"
              defaultValue={schedules.timeLimit}
              ref={React.createRef()}
              id="timeLimit"
              onChange={handleChange}
            />
            {errors.timeLimit && (
              <p className="text-danger small">{errors.timeLimit}</p>
            )}
          </Col>
        </FormGroup>

        {Object.keys(errors).length === 0 && !loading && isSubmitting && (
          <Alert color="success">Berhasil Mengubah Schedule.</Alert>
        )}
        {errors.invalid && <p className="text-danger">{errors.invalid}</p>}

        <div className="container-fluid d-flex justify-content-end">
          <button type="submit" className="btn btn-primary mx-2">
            {loading ? <Spinner color="primary" /> : "Ubah"}
          </button>

          <BackComponent url="/admin/schedule/list" />
        </div>
      </Form>
    </LayoutAdmin>
  );
};

export default Update;
