import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import BackComponent from "../../../components/BackComponent";
import { storage } from "../../../config/FirebaseConfig";
import { DetailLessonAPI } from "../../../services";
import UpdateDetailLessonAPI from "../../../services/adminApi/lesson/updateLesson";
import LayoutAdmin from "../LayoutAdmin";

const DetailLesson = () => {
  const { lesson } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const history = useHistory();
  const [data, setData] = useState({});
  const [error, setError] = useState({});
  const [videoURL, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [supportFile, setSupportFile] = useState({
    videoUri: "",
    thumbnailUri: "",
  });
  const [loading, setLoading] = useState(false);
  const [progressVideo, setProgressVideo] = useState(0);
  const [progressImage, setProgressImage] = useState(0);

  useEffect(() => {
    setLoading(true);
    const getDetail = async () => {
      const response = await DetailLessonAPI(customer.token, lesson.secureId);
      if (response.statusCode === 20) {
        setData(response.result);
        setLoading(false);
      }
    };
    getDetail();
  }, []);
  useEffect(() => {
    if (progressImage == 102 || progressVideo == 102) {
      data.thumbnailUri = imageUrl == "" ? data.thumbnailUri : imageUrl;
      data.videoUri = videoURL == "" ? data.videoUri : videoURL;
      console.log(`data : `, supportFile);
      console.log(`data1 : `, data);
      const updateDetail = async () => {
        const result = await UpdateDetailLessonAPI(
          customer.token,
          lesson.secureId,
          data
        );
        if (result.statusCode === 20) {
          console.log("YEs");
          setLoading(false);
          var x = document.getElementById("snackbar");
          x.className = "show";
          setProgressImage(0);
          setProgressVideo(0);
          history.goBack();
        }
      };
      updateDetail();
    }
  }, [progressImage, progressVideo, error]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const error = {};
    if (name == "video") {
      let file = e.target.files[0];
      if (file) {
        const fileType = ["video/mp4"];
        if (!fileType.includes(file.type)) {
          error.videoError = "Format Video salah harus format video/mp4";
        } else {
          let bloUrl = URL.createObjectURL(file);
          document.getElementById("video-preview").src = bloUrl;
          setSupportFile({ ...supportFile, videoUri: file });
        }
      }
    }
    if (name == "image") {
      let file = e.target.files[0];
      if (file) {
        const imageType = ["image/bmp", "image/jpg", "image/jpeg", "image/png"];
        if (!imageType.includes(file.type)) {
          error.imageError = "Format tidak didukung harus jpg,jpeg,bmp";
        } else {
          let bloUrl = URL.createObjectURL(file);
          document.getElementById("image-preview").src = bloUrl;

          setSupportFile({ ...supportFile, thumbnailUri: file });
        }
      }
    }
    if (name == "title") {
      setData({ ...data, title: value });
    }
    if (name == "theory") {
      setData({ ...data, theory: value });
    }
    setError(error);
  };
  const testNewFunctionUpload = (storageRef, type) => {
    return new Promise((resolve, reject) => {
      const arr =
        type == 1
          ? supportFile.videoUri?.name.split(".")
          : supportFile.thumbnailUri?.name.split(".");
      const formatName =
        arr[0] + " " + format(new Date(), "ddMMyyyyhhmmss") + "." + arr[1];
      if (type == 1) {
        var metadata = {
          contentType: "video/mp4",
        };
        var path = "lesson/video";
        var selectedFile = supportFile.videoUri;
      } else {
        let metadata = {
          contentType: "image/jpeg",
        };
        var path = "lesson/images";
        var selectedFile = supportFile.thumbnailUri;
      }
      var cekProgress = 0;
      let uploadTask = storageRef
        .child(`${path}/${formatName}`)
        .put(selectedFile, metadata);
      uploadTask.on(
        "state_changed",
        function progress(snapshot) {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          if (type == 1) {
            setProgressVideo(progress);
          } else {
            setProgressImage(progress);
          }
          console.log("Upload is " + progress + "% done");
          cekProgress = progress;
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        function error(error) {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              console.log("User doesn't have permission to access the object");
              break;

            case "storage/canceled":
              // User canceled the upload
              console.log("User canceled the upload");
              break;

            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              console.log(
                "Unknown error occurred, inspect error.serverResponse"
              );
              break;
          }
        },
        function completed() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            if (type == 1) {
              setVideoUrl(downloadURL);
              console.log("Video available at", downloadURL);
              setProgressVideo(cekProgress + 2);
            } else {
              setImageUrl(downloadURL);
              setProgressImage(cekProgress + 2);
              console.log("File available at", downloadURL);
            }
            resolve("Completed");
          });
        }
      );
    });
  };
  const format = (x, y) => {
    let z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
      return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
      return x.getFullYear().toString().slice(-v.length);
    });
  };
  const uploadFile = async (storageRef, type) => {
    await testNewFunctionUpload(storageRef, type);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let storageRef = storage.ref();
    if (Object.keys(error).length <= 0) {
      if (supportFile.videoUri != "" && supportFile.thumbnailUri != "") {
        uploadFile(storageRef, 1);
        uploadFile(storageRef, 2);
      } else if (supportFile.videoUri != "") {
        uploadFile(storageRef, 1);
      } else if (supportFile.thumbnailUri != "") {
        uploadFile(storageRef, 2);
      } else {
        const updateDetail = async () => {
          const result = await UpdateDetailLessonAPI(
            customer.token,
            lesson.secureId,
            data
          );
          if (result.statusCode === 20) {
            console.log("YEs");
            setLoading(false);
            var x = document.getElementById("snackbar");
            x.className = "show";
            setTimeout(function () {
              x.className = x.className.replace("show", "");
            }, 2100);
            setProgressImage(0);
            setProgressVideo(0);
          }
        };
        updateDetail();
      }
    }
  };
  return (
    <LayoutAdmin>
      <div id="snackbar">Lesson berhasil diupdate</div>
      <div id="loadingDiv">
        <div
          className="spinner-2"
          style={{ display: loading ? "" : "none" }}
        ></div>
      </div>
      <div className="p-3 m-3">
        <Form onSubmit={handleSubmit}>
          <FormGroup className="mb-3">
            <Label>Judul Lesson</Label>
            <Input value={data.title} name="title" onChange={handleChange} />
            {error && error.titleError && (
              <p className="text-danger small">{error.titleError}</p>
            )}
          </FormGroup>

          <FormGroup className="mb-3">
            <Label>Teori</Label>
            <Input value={data.theory} name="theory" onChange={handleChange} />
            {error && error.theoryError && (
              <p className="text-danger small">{error.theoryError}</p>
            )}
          </FormGroup>

          <FormGroup className="mb-3 flex-column d-flex gap-2">
            <Label>Image Thumnail</Label>
            <img
              src={data.thumbnailUri}
              id="image-preview"
              alt="Image Thumbnail"
              width={200}
            />
            <progress
              value={progressImage <= 100 ? progressImage : 100}
              placeholder={progressImage <= 100 ? progressImage : 100 + "%"}
              max="100"
              style={{ height: "30px" }}
            />
            {progressImage <= 100 ? progressImage : 100 + "%"}
            {error && error.imageError && (
              <p className="text-danger small">{error.imageError}</p>
            )}
            <Input
              type="file"
              accept="image/*"
              name="image"
              onChange={handleChange}
            />
          </FormGroup>

          <FormGroup className="mb-3 flex-column d-flex gap-2">
            <Label>Video</Label>
            <video
              src={data.videoUri}
              width={200}
              controlsList="nodownload"
              controls
              id="video-preview"
            >
              <source type="video/mp4" />
            </video>
            <progress
              value={progressVideo <= 100 ? progressVideo : 100}
              placeholder={progressVideo <= 100 ? progressVideo : 100 + "%"}
              max="100"
              style={{ height: "30px" }}
            />
            {progressVideo <= 100 ? progressVideo : 100 + "%"}
            {error && error.videoError && (
              <p className="text-danger small">{error.videoError}</p>
            )}
            <Input
              type="file"
              accept="video/*"
              name="video"
              onChange={handleChange}
            />
          </FormGroup>
          <div className="d-flex gap-2">
            <Button color="success">Update</Button>
            <BackComponent available={true} />
          </div>
        </Form>
      </div>
    </LayoutAdmin>
  );
};

export default DetailLesson;
