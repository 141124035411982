import styled from "styled-components";

export const FooterComp = styled.footer`
  padding: 30px;
  background-color: #54c2b7;
  ${"" /* clear: both; */}
  position: relative;
  span {
    color: #fff;
    font-size: 1.3rem;
  }
`;
