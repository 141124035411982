import axios from "axios";

export const TheoryApi = async (theoryType, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/theory/list?";

    const endpoint = `${BASED_URL}${path}theoryType=${theoryType}&token=${token}`;
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export default TheoryApi;
