import axios from "axios";

const QuizPracticeApi = async (questionType, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/exam/quiz/list?";
    const endpoint = `${BASED_URL}${path}questionType=${questionType}&token=${token}`;

    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.log("ini error auth login");
    console.log(error);
    return error.response;
  }
};

export default QuizPracticeApi;
