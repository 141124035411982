import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as FaIcons from "react-icons/bs";
import * as MdIcons from "react-icons/md";
import LayoutAdmin from "../LayoutAdmin";
import bimbelList from "../../../services/bimbel/bimbelList";
import "../../CSS/style_bimbel.css";
import { Link, useHistory } from "react-router-dom";
import { setBimbelSecureId } from "../../../redux/features/customerSlice";
const BimbelList = () => {
  const { customer } = useSelector((state) => state);

  const [listBimbel, setListBimbel] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const BimbelListAPI = async () => {
      const res = await bimbelList(customer.token);

      if (res.statusCode == 20) {
        setListBimbel(res.result);
      }
    };

    BimbelListAPI();
  }, []);
  
  return (
    <LayoutAdmin>
      <div className="paket-container m-4">
        <h4 style={{ margin: "15px" }}>Daftar Paket Bimbel</h4>
        <Row>
          {listBimbel.map((val) => (
            <Col className="mt-2" key={val.secureId} lg="6">
              <Link
                to="/admin/bimbel/update"
                onClick={() => {
                  dispatch(setBimbelSecureId(val.secureId));
                }}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  style={{ cursor: "pointer", padding: "18px" }}
                  className="card-container"
                >
                  <div>
                    <h4
                      style={{
                        textDecoration: "underline",
                        textAlign: "center",
                      }}
                    >
                      {val.title}
                    </h4>
                    <br />
                    <h4 style={{ fontWeight: "bold", textAlign: "center" }}>
                      Rp.{val.price}/Month
                    </h4>
                    <br></br>
                    <div>
                      <table class="center">
                        {val.features &&
                          val.features.map((feature) => (
                            <tr key={feature.secureId}>
                              <td>
                                {feature.available ? (
                                  <FaIcons.BsCheckAll
                                    color="#79e09f"
                                    style={{ width: "30px", height: "30px" }}
                                  />
                                ) : (
                                  <MdIcons.MdClose
                                    color="#c33237"
                                    style={{ width: "30px", height: "25px" }}
                                  />
                                )}
                              </td>
                              <td>
                                <h5 style={{ paddingLeft: "10px" }}>
                                  {feature.desc}
                                </h5>
                              </td>
                            </tr>
                          ))}
                      </table>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
    </LayoutAdmin>
  );
};
export default BimbelList;
