import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import customer from "../features/customerSlice";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const persistedReducer = persistReducer(persistConfig, customer);

export default configureStore({
  reducer: persistedReducer,
  //DISABLED DEV TOOLS IN BOTH DEV AND PROD MODE
  // devTools: false,
  //DEV TOOLS ONLY IN DEV MODE
  devTools: process.env.NODE_ENV === "development",
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
