import axios from "axios";
//documentation data go to https://developers.xendit.co/api-reference/id/?javascript#pembuatan-invoice
const CreateInvoice = async (token, data) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/payment/create?token=${token}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.post(url, data);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default CreateInvoice;
