import SidebarData from "./SidebarData";
import SubMenu from "../SubMenu";
import classNames from "classnames";
import Logo from "../../assets/img/logo-ilmugiziku.png";
import { Nav } from "reactstrap";

export const SideBar = ({ isOpen, toggle }) => {
  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle} style={{ color: "#fff" }}>
          &times;
        </span>

        <div className="row p-2">
          <div className="col-md-3">
            <img
              className="bg-light rounded-circle w-100 m-4"
              src={Logo}
              alt="Logo"
            />
          </div>
          <div
            className="col-md-9 my-4 px-4 fw-bold"
            style={{ lineHeight: "1px" }}
          >
            <h4>ILMUGIZIKU </h4>
            <span>Platform Edukasi Gizi</span>
          </div>
        </div>
      </div>
      {/* <AiIcons.AiOutlineClose onClick={toggle} className="h2" /> */}
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          {SidebarData.map((item, key) => {
            return <SubMenu item={item} key={key} />;
          })}
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
