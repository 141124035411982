import Layout from "./Layout";

import { CollapseComponent } from "./../components";
import React, { useState } from "react";
import { FiMinus, FiPlus } from "react-icons/fi";
import { Button } from "reactstrap";
export const SyaratDanKetentuan = () => {
  const [clicked, setClicked] = useState(false);
  const toggleAccordion = (index) => {
    if (index === clicked) {
      return setClicked(null);
    }
    return setClicked(index);
  };
  return (
    <div className="d-flex flex-column gap-2 p-4">
      <h5>
        Dengan menyetujui, mendaftar, dan/atau menggunakan situs
        http://tryout.ilmugiziku.com dan aplikasi Try Out Ilmugiziku, pengguna
        dianggap telah membaca, memahami, dan menyetujui semua konten dalam
        syarat dan ketentuan yang ditetapkan. Jika pengguna tidak menyetujui
        satu, sebagian atau semua konten syarat dan ketentuan, pengguna tidak
        dizinkan untuk menggunakan layanan di kami.
      </h5>
      <h5>Pembaruan : 06 Desember 2021</h5>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(1)}
        >
          <li style={{ listStyle: "none" }}>1. Definisi</li>
          {clicked === 1 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 1 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(2)}
        >
          <li style={{ listStyle: "none" }}>2. Pendaftaran Dan Login</li>
          {clicked === 2 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 2 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(3)}
        >
          <li style={{ listStyle: "none" }}>3. Premium Membership</li>
          {clicked === 3 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 3 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(4)}
        >
          <li style={{ listStyle: "none" }}>4. Pembayaran</li>
          {clicked === 4 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 4 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(5)}
        >
          <li style={{ listStyle: "none" }}>5. Metode Pembayaran</li>
          {clicked === 5 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 5 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(6)}
        >
          <li style={{ listStyle: "none" }}>6. Jam Operasional</li>
          {clicked === 6 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 6 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(7)}
        >
          <li style={{ listStyle: "none" }}>7. Jadwal dan Bimbel</li>
          {clicked === 7 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 7 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(8)}
        >
          <li style={{ listStyle: "none" }}>8. Try Out</li>
          {clicked === 8 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 8 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(9)}
        >
          <li style={{ listStyle: "none" }}>9. Konten dan Soal</li>
          {clicked === 9 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 9 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(10)}
        >
          <li style={{ listStyle: "none" }}>10. Masalah Jaringan</li>
          {clicked === 10 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 10 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#A3DAD4",
          }}
          onClick={() => toggleAccordion(11)}
        >
          <li style={{ listStyle: "none" }}>11. Kebijakan Privasi</li>
          {clicked === 11 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 11 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
      <React.Fragment>
        <div
          className="d-flex justify-content-between p-3 my-1  rounded"
          style={{
            background: "#ECECEC",
          }}
          onClick={() => toggleAccordion(12)}
        >
          <li style={{ listStyle: "none" }}>12. Pelanggaran dan Sanksi</li>
          {clicked === 12 ? <FiMinus color="#000" /> : <FiPlus color="#000" />}
        </div>

        {clicked === 12 && (
          <>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
            <span className="mx-3">A. Lorem</span>
          </>
        )}
      </React.Fragment>
    </div>
  );
};

export default SyaratDanKetentuan;
