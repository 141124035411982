import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import {
  getQuestion,
  getQuestionSubType,
  getTemplateSecureId,
} from "../../../redux/features/customerSlice";
import getTemplateListAPI from "../../../services/adminApi/template";

const Template = (props) => {
  const { customer, questionSubType, questionType } = useSelector(
    (state) => state
  );
  const dispatch = useDispatch();
  //   const {} = props;
  const [templates, setListTemplate] = useState([]);
  const [url, setUrl] = useState("");
  const getTemplate = async (questionSubType) => {
    const res = await getTemplateListAPI(
      questionSubType,
      questionType,
      customer.token
    );

    console.log("template : ", res.result);
    setListTemplate(res.result);
  };
  useEffect(() => {
    // console.log(props.questionSubType);
    getTemplate(props.questionSubType);
    if (props.questionSubType == 1) {
      setUrl("/admin/try-out/d3/question/");
    } else if (props.questionSubType == 2) {
      setUrl("/admin/try-out/d4/question/");
    } else if (props.questionSubType == 3) {
      setUrl("/admin/try-out/s1/question/");
    } else if (props.questionSubType == 4) {
      setUrl("/admin/try-out/pfs/question/");
    }
  }, [questionType]);
  return templates
    ? templates.map((v, i) => (
        <Link
          key={i}
          to={`${url}${v.secureId}`}
          onClick={() => {
            dispatch(getTemplateSecureId(v.secureId));
            dispatch(getQuestionSubType(props.questionSubType));
          }}
        >
          <Button color={v.used ? "primary" : "secondary"}>{v.name}</Button>
        </Link>
      ))
    : "";
};

export default Template;
