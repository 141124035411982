import axios from "axios";

const GetVideoPembahasan = async (token, questionType) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const params = `/exam/discussion-video?questionType=${questionType}&token=${token}`;
    const url = `${BASED_URL}${params}`;
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return err.response;
  }
};

export default GetVideoPembahasan;
