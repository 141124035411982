import axios from "axios";

export const UpdateLaboratoryValueApi = async (labvalueUpdate, token) => {
  try {
    const BASED_URL = process.env.REACT_APP_BASED_URL;
    const path = "/admin/laboratory-value/update?";
    const endpoint = `${BASED_URL}${path}token=${token}`;
    const response = await axios.put(endpoint, labvalueUpdate);
    return response;
  } catch (error) {
    return error.response;
  }
};

export default UpdateLaboratoryValueApi;
