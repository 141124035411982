import { useState, useEffect } from "react";
import { UpdateScheduleApi } from "../../../services";
import { useSelector } from "react-redux";

const UpdateSchedule = (validationUpdate, schedule) => {
  const [schedules, setSchedules] = useState({
    desc: schedule.desc,
    endDate: schedule.endDate.substring(0, 10),
    startDate: schedule.startDate.substring(0, 10),
    timeLimit: schedule.timeLimit,
  });

  const { customer } = useSelector((state) => state);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    let { name, value } = e.target;
    setSchedules({
      ...schedules,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors(validationUpdate(schedules));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      const ScheduleUpdate = async () => {
        setLoading(true);
        const res = await UpdateScheduleApi(
          schedules,
          schedule.secureId,
          customer.token
        );

        if (res.data.statusCode === 20) {
          setLoading(false);
        } else {
          errors.invalid = res.data.message;
          return;
        }
      };
      ScheduleUpdate();
    }
  }, [errors, isSubmitting, schedules, schedule, customer]);

  return {
    handleSubmit,
    handleChange,
    errors,
    loading,
    schedules,
    isSubmitting,
  };
};

export default UpdateSchedule;
