import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { CardComponent } from "../../components";
import * as GiIcons from "react-icons/gi";
import * as HiIcons from "react-icons/hi";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import * as TiIcons from "react-icons/ti";
import { Col, Row } from "reactstrap";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { BimbelInformation } from "../../services";
import {
  disablePremium,
} from "../../redux/features/customerSlice";

export const TryOut = () => {
  const dispatch = useDispatch();
  const { questionType } = useSelector((state) => state);
  const { customer } = useSelector((state) => state);
  const { buttonDisable } = useSelector((state) => state);
  const [skbStatus, setSkbStatus] = useState(false);
  const [ukomStatus, setUkomStatus] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [popUpNotAllow, setPopUpNotAllow] = useState(false);
  useEffect(() => {
    const bimbelInformation = async () => {
      const response = await BimbelInformation(customer.token);
      if (response.statusCode === 20) {
        setSkbStatus(response.result.skbActive);
        setUkomStatus(response.result.ukomActive);
        if (!response.result.skbActive && !response.result.ukomActive) {
          dispatch(disablePremium(false));
        }
      }
    };
    bimbelInformation();
  }, []);

  return (
    <Layout>
      <h2>
        Halaman Utama Try Out {questionType === 3 ? "UKOM" : "SKB GIZI"}{" "}
        ilmugiziku 2021
      </h2>
      <Row className="text-center">
        <Col md={4} className="d-flex justify-content-start">
          <CardComponent
            title="Try Out"
            url={
              buttonDisable
                ? `/try-out/${
                    questionType === 3 ? "ukom" : "skb-gizi"
                  }/ketentuan`
                : "#"
            }
            buttonTeks="Pilih"
            disable={buttonDisable}
            status={true}
            check={2}
          >
            <BsIcons.BsPen
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>

        <Col md={4} className="d-flex justify-content-center">
          <CardComponent
            title="Hasil Try Out"
            url={
              buttonDisable
                ? `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/result`
                : "#"
            }
            buttonTeks="Pilih"
            status={true}
            disable={buttonDisable}
            check={2}
          >
            <TiIcons.TiLightbulb
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>

        <Col md={4} className="d-flex justify-content-end">
          <CardComponent
            title="Ranking Try Out"
            url={
              buttonDisable
                ? `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/ranking`
                : "#"
            }
            buttonTeks="Pilih"
            status={true}
            disable={buttonDisable}
            check={2}
          >
            <GiIcons.GiRank3
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>

        <Col md={4} className="d-flex justify-content-start">
          <CardComponent
            title="Kunci Jawawaban"
            url={
              buttonDisable
                ? questionType == 3
                  ? ukomStatus
                    ? `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/key`
                    : "#"
                  : skbStatus
                  ? `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/key`
                  : "#"
                : "#"
            }
            status={questionType == 3 ? ukomStatus : skbStatus}
            setPopUpNotAllow={setPopUpNotAllow}
            check={2}
            popUpNotAllow={popUpNotAllow}
            disable={buttonDisable}
            buttonTeks="Pilih"
          >
            <AiIcons.AiOutlineSearch
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>

        <Col md={4} className="d-flex justify-content-center">
          <CardComponent
            title="Video Pembahasan"
            url={
              buttonDisable
                ? questionType == 3
                  ? ukomStatus
                    ? `/try-out/${
                        questionType === 3 ? "ukom" : "skb-gizi"
                      }/video`
                    : "#"
                  : skbStatus
                  ? `/try-out/${questionType === 3 ? "ukom" : "skb-gizi"}/video`
                  : "#"
                : "#"
            }
            status={questionType == 3 ? ukomStatus : skbStatus}
            disable={buttonDisable}
            check={2}
            setPopUpNotAllow={setPopUpNotAllow}
            popUpNotAllow={popUpNotAllow}
            buttonTeks="Pilih"
          >
            <MdIcons.MdOndemandVideo
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>

        <Col md={4} className="d-flex justify-content-end">
          <CardComponent
            title="Bagikan ke Teman"
            buttonTeks="Pilih"
            setPopUp={setPopUp}
            disable={buttonDisable}
            status={true}
            popUp={popUp}
            check={2}
          >
            <HiIcons.HiOutlineSpeakerphone
              color="#54c2b7"
              style={{ width: "150px", height: "200px" }}
            />
          </CardComponent>
        </Col>
      </Row>
    </Layout>
  );
};

export default TryOut;
