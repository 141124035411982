import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as ImIcons from "react-icons/im";
import * as GiIcons from "react-icons/gi";
import * as BiIcons from "react-icons/bi";

const SidebarData = [
  {
    title: "Schedule",
    path: "/admin/schedule/list",
    icon: <AiIcons.AiFillSchedule />,
  },

  {
    title: "Laboratory Value",
    path: "/admin/laboratory-value",
    icon: <ImIcons.ImLab />,
  },
  {
    title: "Theory",
    path: "#",
    icon: <BiIcons.BiBookOpen />,
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDropDownFill />,
    subNav: [
      {
        title: "UKOM",
        path: "/admin/theory/ukom",
        // icon: <ImIcons.ImBooks/>,
        icon: null,
      },
      {
        title: "SKB GIZI",
        path: "/admin/theory/skb-gizi",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
    ],
  },
  {
    title: "Question",
    path: "#",
    icon: <AiIcons.AiOutlineQuestion />,
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDropDownFill />,
    subNav: [
      {
        title: "Try Out UKOM",
        path: "/admin/try-out/ukom",
        // icon: <ImIcons.ImBooks/>,
        icon: null,
      },
      {
        title: "Try Out SKB GIZI",
        path: "/admin/try-out/skb-gizi",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
      {
        title: "Kuis Mandiri",
        path: "/admin/kuis-mandiri",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
      {
        title: "Contoh Soal",
        path: "/admin/contoh-soal",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
    ],
  },
  // {
  //   title: "Question",
  //   path: "#",
  //   icon: <GiIcons.GiBookshelf />,
  // },
  // {
  //   title: "Try Out",
  //   path: "#",
  //   icon: <CgIcons.CgNotes />,
  //   iconClosed: <RiIcons.RiArrowRightSFill />,
  //   iconOpened: <RiIcons.RiArrowDropDownFill />,
  //   subNav: [
  //     {
  //       title: "UKOM",
  //       path: "/admin/try-out/ukom",
  //       // icon: <ImIcons.ImBooks/>,
  //       icon: null,
  //     },
  //     {
  //       title: "SKB GIZI",
  //       path: "/admin/try-out/skb-gizi",
  //       // icon: <ImIcons.ImBooks />,
  //       icon: null,
  //     },
  //   ],
  // },
  // {
  //   title: "Forum Diskusi",
  //   path: "#",
  //   icon: <MdIcons.MdForum />,
  //   iconClosed: <RiIcons.RiArrowRightSFill />,
  //   iconOpened: <RiIcons.RiArrowDropDownFill />,
  //   subNav: [
  //     {
  //       title: "UKOM",
  //       path: "/admin/forum-diskusi/ukom",
  //       // icon: <ImIcons.ImBooks/>,
  //       icon: null,
  //     },
  //     {
  //       title: "SKB GIZI",
  //       path: "/admin/forum-diskusi/skb-gizi",
  //       // icon: <ImIcons.ImBooks />,
  //       icon: null,
  //     },
  //   ],
  // },
  {
    title: "Bimbel",
    icon: <GiIcons.GiTeacher />,
    iconClosed: <RiIcons.RiArrowRightSFill />,
    iconOpened: <RiIcons.RiArrowDropDownFill />,
    subNav: [
      {
        title: "List Bimbel",
        path: "/admin/bimbel",
        // icon: <ImIcons.ImBooks/>,
        icon: null,
      },
      {
        title: "Feature Package",
        path: "/admin/feature-packet",
        // icon: <ImIcons.ImBooks />,
        icon: null,
      },
      {
        title: "Lesson",
        path: "/admin/lesson",
        icon: null,
      },
      {
        title: "Webinar",
        path: "/admin/webinar",
        icon: null,
      },
    ],
    // icon: null,
  },
];

export default SidebarData;
