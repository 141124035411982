function validationCreate(values) {
  let errorIni = {};
  if (!values.desc) {
    errorIni.desc = "Masukkan Desc  terlebih dahulu";
  }
  if (!values.startDate) {
    errorIni.startDate = "Masukkan Start Date  terlebih dahulu";
  }
  if (!values.endDate) {
    errorIni.endDate = "Masukkan End Date  terlebih dahulu";
  }

  return errorIni;
}

export default validationCreate;
