import React from "react";
import { StyledEntryCard, StyledCardLogin } from "./style";

export function EntryCard({ children }) {

  return (
    <StyledEntryCard>
      {children}
    </StyledEntryCard>
  );
}

export function CardLogin({ children }) {
  return <StyledCardLogin>{children}</StyledCardLogin>;
}
